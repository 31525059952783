import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import LoginIcon from '@material-ui/icons/ExitToApp';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import FormHelperText from '@material-ui/core/FormHelperText';

import ColoredButton from 'sfl-components/Buttons/Button';

import logo from "assets/images/logo-with-tagline.png";

import { setSessionToken } from 'store/session';
import { useSFLApi } from 'api';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import GooglePlayStore from 'assets/images/google_play_store.png'
import AppleAppStore from 'assets/images/apple_app_store.png'

import { version } from "../../misc";


export default function SignInForm() {
    const [state, setState] = useState({ username: '', password: '', showPassword: false });
    const { username, password, showPassword } = state;
    const dispatch = useDispatch();
    const { post } = useSFLApi();
    const history = useHistory();
    const intl = useIntl();

    const handleChange = (name, value) => {
        setState(currentState => ({ ...currentState, [name]: value }))
    }

    const preventDefault = (event) => {
        event.preventDefault();
    }

    const submitLogin = () => {
        post('sessions', state)
            .then((data) => {
                if (data.session.user_type === 'consultant') {
                    toast.error('Mobile access is currently restricted to member accounts only. Our apologies for the inconvenience.')
                } else {
                    dispatch(setSessionToken(data.session.uuid, data.session.user_type, data.session.user_uuid));
                    history.push(`/dashboard`);
                }
            })
            .catch(() => {
                toast.error(intl.formatMessage({ id: "login.loginFailed", defaultMessage: "Login Failed" }), { toastId: 'SignInInvalidLogin' })
            });
    }

    return <form onSubmit={preventDefault} action="/">
        <div style={{ padding: '80px 30px 10px 30px' }}>
            <img src={logo} alt='Simply For Life' style={{ width: '100%' }} />
        </div>
        <div style={{ padding: '20px 30px' }}>
            <TextField
                id="username"
                label={intl.formatMessage({ id: "app.username", defaultMessage: "Username" })}
                value={username}
                fullWidth
                variant="outlined"
                onChange={event => handleChange('username', event.target.value)}
            />
        </div>
        <div style={{ padding: '20px 30px' }}>
            <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="password"><FormattedMessage id="app.password" defaultMessage="Password" /></InputLabel>
                <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={event => handleChange('password', event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleChange('showPassword', !showPassword)}
                                onMouseDown={preventDefault}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText id="filled-weight-helper-text">
                    <NavLink to='/forgot-login' style={{ color: '#cccccc' }}>
                        <FormattedMessage
                            id="login.forgotYourPassword"
                            defaultMessage="Forgot your password?"
                        />
                    </NavLink>
                </FormHelperText>
            </FormControl>
        </div>
        <div style={{ padding: '10px 30px' }}>
            <ColoredButton color='purple' type="submit" style={{ width: '100%', marginBottom: '2rem' }} startIcon={<LoginIcon />} onClick={submitLogin}>
                <FormattedMessage
                    id="app.signIn"
                    defaultMessage="Sign In"
                />
            </ColoredButton>
        </div>
        <div style={{ padding: '10px 30px', textAlign: 'center' }}>
            <FormattedMessage
                id="login.dontHaveAnAccountYet"
                defaultMessage="Don't have an account yet?"
            />
        </div>
        <div style={{ padding: '0px 30px', textAlign: 'center' }}>
            <a href='http://newsite.simplyforlife.com/#consultant' target="_blank" rel="noopener noreferrer">
                <FormattedMessage
                    id="login.signUpAtWebsite"
                    defaultMessage="Sign up at SimplyForLife.com"
                />
            </a>
        </div>
        <div style={{ padding: '0px 30px', textAlign: 'center' }}>
            <br />
            <a href='https://play.google.com/store/apps/details?id=com.simplyforlife.app' style={{ margin: '20px' }}>
                <img src={GooglePlayStore} style={{ width: '150px' }} />
            </a>
            <a href='https://apps.apple.com/ca/app/simply-for-life/id1594421277' style={{ margin: '20px' }}>
                <img src={AppleAppStore} style={{ width: '150px' }} />
            </a>
        </div>
        <div style={{ paddingTop: '40px', marginTop: '20px', marginLeft: '30px', marginRight: '30px', borderTop: '1px solid #cccccc', color: '#cccccc', fontSize: '12px', textAlign: 'center' }}>
            <NavLink to='/terms-of-use' style={{ color: '#cccccc' }}>
                <FormattedMessage
                    id="app.termsOfUse"
                    defaultMessage="Terms Of Use"
                />
            </NavLink>
            <div style={{ display: 'inline-block', padding: '0px 20px' }}>|</div>
            <NavLink to='/privacy-policy' style={{ color: '#cccccc' }}>
                <FormattedMessage
                    id="app.privacyPolicy"
                    defaultMessage="Privacy Policy"
                />
            </NavLink>
        </div>
        <div style={{ color: '#cccccc', fontSize: '12px', textAlign: 'center' }}>
            ©2020 Simply For Life.&nbsp;
            <FormattedMessage
                id="app.allRightsReserved"
                defaultMessage="All rights reserved."
            /><br />
            Version: {version}<br />
        </div>
    </form >
}