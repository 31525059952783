import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import Table from "sfl-components/Table/Table.js";
import RowButton from "sfl-components/Table/RowButton.js";


export default function MemberMealPlansTable(props) {
	const history = useHistory();
	const { tableData } = props;

	const handleOpenClick = (mealPlanId) => {
		history.push(`/meal-plan/${mealPlanId}?sort=start_date`);
	}

	const actions = [
		{
			title: <><FontAwesomeIcon icon={faEdit} fixedWidth /> Open</>,
			onClick: handleOpenClick
		}
	];

	const rowFormat = row => ({
		name: row.name,
		start_date: row.start_date,
		end_date: row.end_date,
		actions: <RowButton actionId={row.uuid} actions={actions} />
	});

	const tableConfig = [
		{
			key: 'name',
			name: 'Name'
		},
		{
			key: 'start_date',
			name: 'Start Date'
		},
		{
			key: 'end_date',
			name: 'End Date'
		},
		{
			key: 'actions',
			headerStyles: { width: '100px' }
		}
	];

	return (
		<Table
			tableConfig={tableConfig}
			tableData={tableData.map(rowFormat)}
			pagination={props.pagination}
		/>
	);
}