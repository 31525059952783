import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from 'sfl-components/TextField';

import CardBody from "components/Card/CardBody.js";
import OtherInput from 'sfl-components/Inputs/OtherInput';
import FoodInput from 'sfl-components/FoodInput.js';

import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardButton from 'sfl-components/SFLCards/CardButton';

import BottomNavbar from "sfl-components/Navbars/BottomNavbar.js";
import { FormattedMessage, useIntl } from 'react-intl';

import { useSFLApi } from 'api';


export default function FormOther(props) {
    const { uuid, onClose, date, section } = props;
    const intl = useIntl();

    const [state, setState] = useState({});
    const { get, post } = useSFLApi();
    const { name = '', scale = '', portion = '', time = '' } = state;

    useEffect(() => {
        if (!uuid) {
            return;
        }
        get(`foodLogs/${uuid}`)
            .then(results => {
                setState(results.foodLog);
            })
            .catch(error => {
                console.log('error', error);
            })
    }, [get, uuid]);


    const handleChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }


    const handleSave = async () => {
        const path = uuid ? `foodLogs/${uuid}` : 'foodLogs';
        const data = {
            ...state,
            section: section,
            date: date
        };
        try {
            await post(path, data);

            toast.success(intl.formatMessage({ id: "app.entrySaved", defaultMessage: "Entry Saved" }));
            props.refresh();
            onClose();
        }
        catch (error) {
            console.log('error', error);
            toast.error(intl.formatMessage({ id: "app.saveFailed", defaultMessage: "Save Failed" }))
        }
    }


    return <>
        <MobileNavbar>
            <NavBarButton onClick={onClose}>
                <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
            </NavBarButton>
            <NavBarTitle>
                <FormattedMessage id="app.foodLog" defaultMessage="Food Log" />
            </NavBarTitle>
        </MobileNavbar>
        <BottomNavbar>
            <CardButton key="save" onClick={handleSave} style={{ borderRadius: '0px', padding: '0', flexGrow: '1', marginLeft: '0px', height: '40px', backgroundColor: '#b4bb00' }}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} style={{ marginRight: '10px' }} /> <FormattedMessage id="app.save" defaultMessage="Save" />
            </CardButton>
        </BottomNavbar>
        <CardBody style={{ boxSizing: 'border-box' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="foodLog.whatDidYouEat" defaultMessage="What did you eat?" />
                    </div>
                    <FoodInput
                        id="name"
                        label={intl.formatMessage({ id: "foodLog.foodSearch", defaultMessage: "Food Search" })}
                        value={name}
                        onChange={newValue => handleChange('name', newValue)}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <div style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="foodLog.portion" defaultMessage="Portion" />
                    </div>
                    <TextField
                        id="portion"
                        label={intl.formatMessage({ id: "foodLog.portion.helperText", defaultMessage: "Ex: 2" })}
                        value={portion}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                    <div style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="foodLog.scale" defaultMessage="Scale" />
                    </div>
                    <OtherInput
                        id='scale'
                        label={intl.formatMessage({ id: "foodLog.scale.helperText", defaultMessage: "Ex: Grams" })}
                        value={scale}
                        textMode={![undefined, '', 'Serving', 'Cup', 'Tbsp', 'Tsp', 'ml', 'Gram', 'Ounce'].includes(scale)}
                        onChange={newValue => handleChange('scale', newValue)}
                    >
                        <MenuItem key='empty' value=''></MenuItem>
                        <MenuItem key='Serving' value='Serving'><FormattedMessage id="foodLog.serving" defaultMessage="Serving" /></MenuItem>
                        <MenuItem key='Cup' value='Cup'><FormattedMessage id="foodLog.cup" defaultMessage="Cup" /></MenuItem>
                        <MenuItem key='Tbsp' value='Tbsp'><FormattedMessage id="foodLog.tbsp" defaultMessage="Tbsp" /></MenuItem>
                        <MenuItem key='Tsp' value='Tsp'><FormattedMessage id="foodLog.tsp" defaultMessage="Tsp" /></MenuItem>
                        <MenuItem key='ML' value='ML'><FormattedMessage id="foodLog.ml" defaultMessage="ML" /></MenuItem>
                        <MenuItem key='Gram' value='Gram'><FormattedMessage id="foodLog.gram" defaultMessage="Gram" /></MenuItem>
                        <MenuItem key='Ounce' value='Ounce'><FormattedMessage id="foodLog.ounce" defaultMessage="Ounce" /></MenuItem>
                    </OtherInput>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="foodLog.when" defaultMessage="When?" />
                    </div>
                    <TextField
                        id="time"
                        label={intl.formatMessage({ id: "foodLog.time", defaultMessage: "Time" })}
                        type="time"
                        value={time || ''}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 900, // 5 min
                        }}
                    />
                </Grid>
            </Grid>
        </CardBody>
    </>
}