import React from "react";
import { useApi } from 'api';

export default function Ingredients({ recipeUuid = '' }) {
    const { data: { recipeIngredients = [] } } = useApi(`recipeIngredients?recipe_uuid=${recipeUuid}`)

    const ingredientRow = (ingredient) => {
        const { uuid = '', type = '', amount = '', size = '', scale = '', name = '', description = '' } = ingredient

        if (type === 'group') {
            return <div key={uuid}>
                <div style={{ marginTop: '20px', fontWeight: 'bold' }}>
                    {name}
                </div>
                <div style={{ paddingLeft: '20px' }}>
                    {recipeIngredients.filter(e => e.parent_uuid === uuid).map(ingredientRow)}
                </div>
            </div>
        }

        const prefix = [amount, size, scale].filter(e => e)
        const prefixStr = prefix.length === 0 ? '' : <>{prefix.join(' ')} — </>

        return <div key={uuid} style={{ padding: '4px' }}>
            {prefixStr}{name}{description !== '' && `, ${description}`}
        </div>
    }

    return recipeIngredients.filter(e => e.parent_uuid === null).map(ingredientRow)
}