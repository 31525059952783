import React from "react";
import { Link } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import imagePlaceholder from "assets/images/recipe/placeholder.jpg";
import Button from '@material-ui/core/Button';

import notFoundImage from 'assets/images/plate-empty-v.jpg';
import { FormattedMessage } from 'react-intl';

export default function RecipeList(props) {
    const { data, hasMore, loadMore, searchValue, isLoading } = props;

    if (isLoading) {
        return null
    }


    if (data.length === 0) {
        return <div key='notFound' style={{ position: 'relative', textAlign: 'center' }}>
            <img src={notFoundImage} alt='' />
            <div style={{
                lineHeight: '2rem',
                fontSize: '36px',
                fontWeight: 'bold',
                color: '#ea7530',
                position: 'absolute',
                top: '45%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
                <FormattedMessage id="recipes.noResults" defaultMessage="Sorry, Your search came up empty." />
            </div >
        </div>
    }

    return <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '20px' }}>
        {data.map(recipe => {
            const imagePath = recipe.images.length > 0 ? recipe.images[0].path : imagePlaceholder
            return <div key={recipe.uuid} style={{ width: '50%', boxSizing: 'border-box', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '20px' }}>
                <Paper elevation={3} style={{ height: '100%' }}>
                    <Link to={`/recipe/${recipe.uuid}`}>

                        <div style={{ backgroundImage: `url(${imagePath})`, backgroundPosition: 'center', backgroundSize: 'cover', height: '125px' }}>

                        </div>
                        <div style={{ color: '#444', fontWeight: 'bold', fontSize: '16px', padding: '10px 10px 20px 10px' }}>{recipe.name}</div>
                    </Link>
                </Paper>
            </div>
        })}
        {hasMore && <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '40px' }}><Button variant="outlined" color="primary" onClick={loadMore}>
            <FormattedMessage id="recipes.loadMoreResults" defaultMessage="Load More Results" />
        </Button></Grid>}
    </div>
}