import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import ColoredButton from 'sfl-components/Buttons/Button';
import DateDisplay from 'sfl-components/DateDisplay';
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import ProgressChart from '../ProgressChart';
import { TimeframeSelect, getTimeframeName } from '../TimeframeSelect';

export const Summary = (props) => {
    return <div style={{ display: 'flex', flexWrap: 'wrap', padding: '20px 0px' }}>
        {props.children}
    </div>
}

export const SummaryPanel = (props) => {
    return <Paper elevation={3} style={{ flex: '1', minWidth: '425px', padding: '20px', margin: '20px' }}>
        <div style={{ padding: '15px', fontSize: '24px' }}>
            <FormattedMessage id="app.currentMeasurement" defaultMessage="Current Measurement" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#3c4858', paddingTop: '100px' }}>
            {props.isEmpty
                ? <div style={{ padding: '40px' }}>
                    <FormattedMessage id="app.noMeasurementsYet" defaultMessage="You haven't recorded any measurements yet!" />
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>
                        <ColoredButton color='purple' type="submit" style={{ width: '100%', marginBottom: '2rem' }} startIcon={<FontAwesomeIcon icon={['fas', 'edit']} />} onClick={props.onEdit}>
                            <FormattedMessage id="app.addMeasurement" defaultMessage="Add Measurement" />
                        </ColoredButton>
                    </div>
                </div >
                : <>
                    <div style={{ textAlign: 'center' }}>
                        {props.children}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        <span style={{ fontSize: '18px' }}><FormattedMessage id="app.lastUpdated" defaultMessage="Last Updated" />: {<DateDisplay date={props.date} />}</span>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>
                        <ColoredButton color='purple' type="submit" style={{ width: '100%', marginBottom: '2rem' }} startIcon={<FontAwesomeIcon icon={['fas', 'edit']} />} onClick={props.onEdit}>
                            <FormattedMessage id="app.update" defaultMessage="Update" />
                        </ColoredButton>
                    </div>
                </>
            }
        </div>
    </Paper>
}

export const ChartPanel = ({ timeframeId, onTimeframeSelect, series }) => {
    const [showTimeframeSelect, setShowTimeframeSelect] = useState()


    return <Paper elevation={3} style={{ flex: '1', minWidth: '425px', padding: '20px', margin: '20px' }}>
        <TimeframeSelect
            key='timeframeSelect'
            selectedOptionId={timeframeId}
            onSelect={onTimeframeSelect}
            open={showTimeframeSelect}
            onClose={setShowTimeframeSelect}
        />
        <div style={{ padding: '10px', fontSize: '24px' }}>
            <Button
                style={{ fontSize: '20px', padding: '5px' }}
                endIcon={<FontAwesomeIcon icon={['fas', 'search']} style={{ fontSize: '16px' }} />}
                onClick={() => { setShowTimeframeSelect(true) }}
            >
                <FormattedMessage id="app.pastProgress" defaultMessage="Past Progress" /> - {getTimeframeName(timeframeId)}
            </Button>
        </div>
        <div style={{ padding: '0px 30px 30px 30px', minHeight: '300px' }}>
            <ProgressChart series={series} />
        </div>
    </Paper>
}