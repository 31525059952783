import React from "react";
import { IntlProvider } from 'react-intl';
import French from 'lang/fr.json';
import English from 'lang/en.json';

import { useSelector } from 'react-redux'

export default function LanguageProvider(props) {
    const { language = 'en' } = useSelector(state => state.appSettings);

    let selectedLanguage = English;
    if (language === 'fr') {
        selectedLanguage = French
    }

    return <IntlProvider locale={language} messages={selectedLanguage}>{props.children}</IntlProvider>
}