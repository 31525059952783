import React from "react";
import CardTitle from 'sfl-components/SFLCards/CardTitle';

export default function Card(props) {
    const { title, titleMode, buttons = [] } = props

    const style = {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        backgroundColor: '#f3f0d0',
        borderRadius: '15px',
        marginBottom: '30px',
        padding: '20px',
        ...props.style
    }

    const titleBar = <CardTitle key='title' title={title} buttons={buttons} style={{ color: '#6b3374' }} />

    return [
        titleMode === 'outer' ? titleBar : null,
        <div key='content' style={style}>
            {titleMode === 'inner' ? titleBar : null}
            {props.children}
        </div >
    ]
}