import React from "react";
import { FormattedMessage } from 'react-intl';
import SocialTile from "../components/SocialTile";
import { useApi } from 'api';


export default function Socials() {
    const { data: { profile = {} }, isLoading } = useApi('profile')

    if (isLoading === true) {
        return null
    }

    return [
        <div key='title' style={{ padding: '20px', color: '#3c4858', fontWeight: 'bold', fontSize: '24px' }}>
            <FormattedMessage
                id="app.WhereToFindUs"
                defaultMessage="Where To Find Us"
            />
        </div>,
        <div key='findUs' style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '50px' }}>
            <SocialTile type='facebook' url={profile.location_social_facebook} />
            <SocialTile type='instagram' url={profile.location_social_instagram} />
            <SocialTile type='youtube' url={profile.location_social_youtube} />
            <SocialTile type='pinterest' url={profile.location_social_pinterest} />
            <SocialTile type='ecom' url={profile.ecom} />
        </div>
    ]
}