import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Pagination from 'sfl-components/Table/Pagination';
import css from './styles.module.css';

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
	const classes = useStyles();
	let { tableConfig, tableData } = props;

	return (
		<div className={`${classes.tableResponsive}`}>
			<Table className={`${classes.table} ${css.stripedTable}`}>
				<TableHead className={classes['primaryTableHeader']}>
					<TableRow className={classes.tableHeadRow}>
						{tableConfig.map(column => {
							return (
								<TableCell key={column.key} style={{ fontWeight: 500, ...column.headerStyles }} className={classes.tableCell + " " + classes.tableHeadCell} >
									{column.name}
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableData.map((rowData, rowKey) => {
						return (
							<TableRow key={rowKey} className={classes.tableBodyRow}>
								{tableConfig.map(column => {
									return (
										<TableCell style={{ fontWeight: 400, ...column.cellStyles }} className={classes.tableCell} key={column.key}>
											{rowData[column.key]}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			{props.pagination && <Pagination
				currentPage={props.pagination.currentPage}
				totalPages={props.pagination.totalPages}
				firstPage={props.pagination.firstPage}
				previousPage={props.pagination.previousPage}
				nextPage={props.pagination.nextPage}
				lastPage={props.pagination.lastPage}
				setResultsPerPage={props.pagination.setResultsPerPage}
			/>}
		</div >
	);
}