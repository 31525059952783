import {
  drawerWidth,
  transition
} from "assets/jss/material-dashboard-react.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"

  },
  content: {
    [theme.breakpoints.up("md")]: {
      marginTop: "70px",
      padding: "30px 15px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px",

    },

    minHeight: "calc(100vh - 130px)"
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",

    [theme.breakpoints.down("md")]: {
      paddingRight: "0px",
      paddingLeft: "0px"
    }
  },
map: {
  marginTop: "70px",
  }
});


export default appStyle;
