import React from "react";

import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';

import Question from './Question';


const QuestionnaireHabits = props => {
    const {
        handleChange,
        exercise_level = '',
        restaurant_frequency = '',
        cook_frequency = ''
    } = props


    return <Card key='habits' title="What habits do you have?" titleMode="inner">
        <Grid container spacing={3} style={{ padding: '0 20px' }}>
            <Grid item xs={12} >
                <Grid container spacing={3}>
                    <Question
                        id="exercise_level"
                        label="How many hours per week do you exercise?"
                        helperText=" "
                        select
                        value={exercise_level}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='none' value='none'>I don't exercise</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='low' value='low'>Under 2 hours</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='medium' value='medium'>Between 2 to 5 hours</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='high' value='high'>More than 5 hours</MenuItem>
                    </Question>
                    <Question
                        id="restaurant_frequency"
                        label="On average, how many times per week do you eat meals from restaurant?"
                        helperText="This includes dine in, take out, etc."
                        select
                        value={restaurant_frequency}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'>1-5</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'>6-10</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='11' value='11'>11+</MenuItem>
                    </Question>
                    <Question
                        id="cook_frequency"
                        label="On average, how many times per week do you cook?"
                        select
                        value={cook_frequency}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'>1-5</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'>6-10</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='11' value='11'>11+</MenuItem>
                    </Question>
                </Grid>
            </Grid>
        </Grid>
    </Card>
}


export default React.memo(QuestionnaireHabits);