import React from "react";

export default function FancyBox(props) {
    return <div style={{ backgroundColor: 'white', borderRadius: '15px', marginTop: '40px', padding: '0 40px 40px 40px' }}>
        <div style={{ textAlign: 'center' }}>
            <div style={{ textTransform: 'uppercase', backgroundColor: '#C5C62E', display: 'inline-block', padding: '10px 40px', color: 'white', fontWeight: 'bold', fontSize: '28px', marginTop: '-30px' }}>
                {props.title}
            </div>
        </div>
        {props.children}
    </div>
}