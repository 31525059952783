import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import CardButton from 'sfl-components/SFLCards/CardButton';
import Error from 'sfl-components/Error';
import MealPlanLayout from './ComponentsMobile/MealPlanLayout';
import EntryDialog from './ComponentsMobile/EntryDialog';

import { useSFLApi } from 'api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";

import BottomNavbar from "../Shared/BottomNavBar";
import { FormattedMessage } from 'react-intl';


export default function MealPlanEditor() {
    const [editEntry, setEditEntry] = useState();
    const [mealPlan, setMealPlan] = useState({});
    const [mealPlanEntries, setMealPlanEntries] = useState([]);
    const { mealPlanUuid } = useParams();
    const { get, post } = useSFLApi();
    const [error, setError] = useState();


    const fetchData = useCallback(async () => {
        let mealPlan;

        try {
            if (mealPlanUuid) {
                const results = await get(`mealPlans/${mealPlanUuid}`);
                mealPlan = results.mealPlan;
            } else {
                const results = await get(`mealPlans?status=active&sort=start_date:desc,name:asc`);
                mealPlan = results.mealPlans[0];
            }

            setError();
            setMealPlan(mealPlan);


            if (!mealPlan) {
                return
            }
            get(`mealPlanEntries?status=active&sort=order&meal_plan_uuid=${mealPlan.uuid}`)
                .then(results => {
                    if (results.mealPlanEntries.length === 0) {
                        setError({
                            type: 'warning',
                            title: 'Warning',
                            description: <FormattedMessage id="mealPlan.mealPlanEmptyContactConsultant" defaultMessage='The selected meal plan appears to be empty. Please contact your consultant for assistance.' />
                        });
                    }
                    setMealPlanEntries(results.mealPlanEntries)
                })
        }
        catch (error) {
            setError({
                type: 'error',
                title: 'Error',
                description: <FormattedMessage id="mealPlan.mealPlanErrorContactConsultant" defaultMessage='An error has occured while retreiving the selected meal plan. Please contact your consultant for assistance.' />
            });
            console.log('error', error)
        }

    }, [get, mealPlanUuid]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const toggleShoppingList = entryUuid => {
        const entryIndex = mealPlanEntries.findIndex(e => e.uuid === entryUuid);
        post(`mealPlanEntries/${entryUuid}`, { shopping_list: !mealPlanEntries[entryIndex].shopping_list })
            .then(results => {
                mealPlanEntries[entryIndex] = results.mealPlanEntry;
                setMealPlanEntries([...mealPlanEntries])
            });
    }


    const toggleFavorite = entryUuid => {
        const entryIndex = mealPlanEntries.findIndex(e => e.uuid === entryUuid);
        post(`mealPlanEntries/${entryUuid}`, { favorite: !mealPlanEntries[entryIndex].favorite })
            .then(results => {
                mealPlanEntries[entryIndex] = results.mealPlanEntry;
                setMealPlanEntries([...mealPlanEntries])
            });
    }


    const toggleChildren = entryUuid => {
        const entryIndex = mealPlanEntries.findIndex(e => e.uuid === entryUuid);
        let updatedEntry = { ...mealPlanEntries[entryIndex] };
        updatedEntry.displayChildren = updatedEntry.displayChildren === true ? false : true;

        mealPlanEntries[entryIndex] = updatedEntry;
        setMealPlanEntries([...mealPlanEntries])
    }

    const actions = {
        childrenToggle: toggleChildren,
        cartToggle: toggleShoppingList,
        favoriteToggle: toggleFavorite,
        helpToggle: () => { }
    }


    let dialog;
    if (editEntry) {
        const entry = mealPlanEntries.find(e => e.uuid === editEntry);
        dialog = <EntryDialog entry={entry} actions={actions} onClose={setEditEntry} />
    }

    if (error) {
        return [
            <MobileNavbar key='MobileNavbar'>
                <SidebarToggle />
                <NavBarTitle><FormattedMessage id="app.mealPlan" defaultMessage="Meal Plan" /></NavBarTitle>
            </MobileNavbar>,
            <BottomNavbar key='BottomNavbar' activeTab={1} />,
            <Error key='error' title={error.title} description={error.description}>
                <CardButton key="refresh" onClick={fetchData}>
                    <FontAwesomeIcon icon={['fas', 'sync']} />
                    <div><FormattedMessage id="app.tryAgain" defaultMessage="Try Again" /></div>
                </CardButton>
            </Error>
        ]
    }

    if (!mealPlan?.uuid || mealPlanEntries.length === 0) {
        return [
            <MobileNavbar key='MobileNavbar'>
                <SidebarToggle />
                <NavBarTitle><FormattedMessage id="app.mealPlan" defaultMessage="Meal Plan" /></NavBarTitle>
            </MobileNavbar>,
            <BottomNavbar key='BottomNavbar' activeTab={1} />,
            <div key='message' style={{ display: 'flex', paddingTop: '150px' }}>
                <div style={{ flex: 1, padding: '50px' }}>
                    <div style={{ color: '#bbbbbb', fontSize: '48px', fontWeight: 'bold', margin: '50px 0' }}>
                        <FormattedMessage id="mealPlan.checkBackSoon" defaultMessage='Check back soon!' />
                    </div>
                    <div style={{ color: '#bbbbbb', fontSize: '24px', fontWeight: 'bold', maxWidth: '500px' }}>
                        <FormattedMessage id="mealPlan.mealPlanNotReady" defaultMessage="Your meal plan isn't quite ready yet. Please check with your consultant for additional information." />
                    </div>
                </div>
            </div>
        ]
    }

    return <>
        {dialog}
        <MobileNavbar>
            <SidebarToggle />
            <NavBarTitle><FormattedMessage id="app.mealPlan" defaultMessage="Meal Plan" /></NavBarTitle>
        </MobileNavbar>
        <BottomNavbar activeTab={1} />
        <Paper elevation={3} style={{ padding: '20px' }}>
            <span style={{ fontSize: '32px' }}>{mealPlan.name}</span>
            {mealPlan.description !== ''
                ? <div style={{ whiteSpace: 'pre-line' }}>{mealPlan.description}</div>
                : null
            }
        </Paper>
        <div style={{ marginBottom: '100px' }}>
            <MealPlanLayout mealPlanEntries={mealPlanEntries} members={mealPlan.members} editEntry={setEditEntry} />
        </div>
    </>
}