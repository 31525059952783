import React from "react";

import Hidden from "@material-ui/core/Hidden";

import LayoutMobile from './Layout.mobile';
import LayoutDesktop from './Layout.desktop';

export default function FoodLog() {
	return <>
		<Hidden smDown key='desktop'>
			<LayoutDesktop />
		</Hidden>
		<Hidden mdUp key='mobile'>
			<LayoutMobile />
		</Hidden>
	</>
}