import React from "react";

import Hidden from "@material-ui/core/Hidden";

import Mobile from './mobile';
import Desktop from './desktop';

export default function RecipeDetails() {


	return <>
		<Hidden mdDown>
			<Desktop />
		</Hidden>
		<Hidden mdUp>
			<Mobile />
		</Hidden>
	</>
}