import React from "react";
import { Link } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import imagePlaceholder from "assets/images/recipe/placeholder.jpg";


export default function CategoryList(props) {
    const { data } = props;


    return <Grid container spacing={5} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {data.map((category) => {
            let imagePath = category.image === null ? imagePlaceholder : category.image.path

            return <Grid key={category.uuid} item xs={6} lg={6} xl={4}
                style={{
                    boxSizing: 'border-box',
                    padding: '15px',
                    position: 'relative',
                    fontWeight: 'bold',
                    color: category.image_text_color,
                    fontSize: '28px',
                    minHeight: '100px'
                }}>
                <Link to={`/recipe-categories/${category.uuid}`} style={{ color: category.image_text_color }}>
                    <img alt='' src={`${imagePath}?width=500`} style={{ width: '100%', display: 'block' }} />
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        maxWidth: '45%',
                        left: '30px',
                        transform: 'translate(0, -50%)'
                    }}>
                        {category.name}
                    </div>
                </Link>
            </Grid>
        })}
    </Grid>

}



