import React from "react";
import DashboardPage from "views/Dashboard-Member";
import SignOut from "sfl-components/SignOut";
import RecipeCategories from "views/RecipeCategories";
import CurrentMealPlan from "views/MemberMealPlans/Current";
import CurrentFoodLog from "views/FoodLogs/Current";
import Questionnaire from "views/Questionnaires/Form";
import Feedback from "views/Feedback";
import Profile from "views/Profile";
import ProgressTrackerDashboard from "views/ProgressTracker/Dashboard";

import HomeIcon from 'assets/svgIcons/Home'
import MealPlanIcon from 'assets/svgIcons/MealPlan'
import ChartIcon from 'assets/svgIcons/Chart'
import FoodLogIcon from 'assets/svgIcons/FoodLog'
import RecipesIcon from 'assets/svgIcons/Recipes'
import QuestionnaireIcon from 'assets/svgIcons/Questionnaire'
import FeedbackIcon from 'assets/svgIcons/Feedback'
import ProfileIcon from 'assets/svgIcons/Profile'
import SignOutIcon from 'assets/svgIcons/SignOut'

const dashboardRoutes = [
	{
		path: "/dashboard",
		id: "app.dashboard",
		name: "Dashboard",
		iconComponent: <HomeIcon size={30} color='currentColor' />,
		component: DashboardPage,
	},
	{
		path: "/meal-plan",
		id: "app.mealPlan",
		name: "Meal Plan",
		iconComponent: <MealPlanIcon size={30} color='currentColor' />,
		component: CurrentMealPlan
	},
	{
		path: "/food-log",
		id: "app.foodLog",
		name: "Food Log",
		iconComponent: <FoodLogIcon size={30} color='currentColor' />,
		component: CurrentFoodLog
	},
	{
		path: "/recipe-categories",
		id: "app.recipes",
		name: "Recipes",
		iconComponent: <RecipesIcon size={30} color='currentColor' />,
		component: RecipeCategories
	},
	{
		path: "/progress-tracker",
		id: "app.progressTracker",
		name: "Progress Tracker",
		iconComponent: <ChartIcon size={30} color='currentColor' />,
		component: ProgressTrackerDashboard
	},
	{
		path: "/questionnaire",
		id: "app.questionnaire",
		name: "Questionnaire",
		iconComponent: <QuestionnaireIcon size={30} color='currentColor' />,
		component: Questionnaire
	},
	{
		path: "/feedback",
		id: "app.feedback",
		name: "Feedback",
		iconComponent: <FeedbackIcon size={30} color='currentColor' />,
		component: Feedback
	},
	{
		path: "/profile",
		id: "app.profile",
		name: "Profile",
		iconComponent: <ProfileIcon size={30} color='currentColor' />,
		component: Profile
	},
	{
		path: "/sign-out",
		id: "app.signOut",
		name: "Sign Out",
		iconComponent: <SignOutIcon size={30} color='currentColor' />,
		component: SignOut
	},
];

export default dashboardRoutes;
