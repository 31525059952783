import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        viewBox="-45 0 512 512"
        width={size}
    >
        <path fill={color} d="m354.78125 66.757812 53.914062.09375-53.914062-53.914062zm0 0" />
        <path fill={color} d="m339.578125 97.082031c-8.371094-.015625-15.148437-6.804687-15.148437-15.175781v-81.90625h-257.414063c-8.378906 0-15.175781 6.792969-15.175781 15.175781v270.464844c15.800781-6.597656 33.125-10.25 51.289062-10.25 8.96875 0 17.734375.894531 26.214844 2.589844-1.011719-2.03125-1.597656-4.320313-1.597656-6.75 0-8.378907 6.792968-15.171875 15.175781-15.171875h182.769531c8.382813 0 15.175782 6.792968 15.175782 15.171875 0 8.382812-6.792969 15.175781-15.175782 15.175781h-169.488281c47.265625 20.566406 80.40625 67.714844 80.40625 122.464844 0 21.253906-5.003906 41.355468-13.878906 59.210937h184.839843c8.378907 0 15.175782-6.792969 15.175782-15.175781v-355.675781zm-68.515625 54.769531c0 8.382813-6.796875 15.175782-15.175781 15.175782h-112.964844c-8.382813 0-15.175781-6.792969-15.175781-15.175782 0-8.378906 6.792968-15.175781 15.175781-15.175781h112.964844c8.378906 0 15.175781 6.792969 15.175781 15.175781zm54.628906 74.867188h-182.769531c-8.382813 0-15.175781-6.796875-15.175781-15.175781 0-8.382813 6.792968-15.175781 15.175781-15.175781h182.769531c8.382813 0 15.175782 6.792968 15.175782 15.175781 0 8.378906-6.792969 15.175781-15.175782 15.175781zm0 0" />
        <path fill={color} d="m206.257812 408.871094c0-56.867188-46.261718-103.128906-103.128906-103.128906-56.867187 0-103.128906 46.261718-103.128906 103.128906 0 56.867187 46.261719 103.128906 103.128906 103.128906 56.867188 0 103.128906-46.265625 103.128906-103.128906zm-73.453124 15.175781h-14.5v14.5c0 8.378906-6.796876 15.175781-15.175782 15.175781-8.382812 0-15.175781-6.796875-15.175781-15.175781v-14.5h-14.5c-8.382813 0-15.175781-6.796875-15.175781-15.175781 0-8.382813 6.792968-15.175782 15.175781-15.175782h14.5v-14.5c0-8.382812 6.792969-15.175781 15.175781-15.175781 8.378906 0 15.175782 6.792969 15.175782 15.175781v14.5h14.5c8.378906 0 15.175781 6.792969 15.175781 15.175782 0 8.378906-6.796875 15.175781-15.175781 15.175781zm0 0" />
    </svg>
}

export default SvgComponent