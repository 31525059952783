import React from "react";
import Food from './Food';
import Group from './Group';
import Recipe from './Recipe';
import Section from './Section';

export default function generateEntry(mealPlanEntries, members = [], parentUuid, actions, indentCells = []) {
    return mealPlanEntries.filter(entry => entry.parent_entry_uuid === parentUuid)
        .map(entry => {
            if (entry.type === 'food_parent' || entry.type === 'food_child' || entry.type === 'food') {
                return <Food members={members} key={entry.uuid} data={entry} mealPlanEntries={mealPlanEntries} actions={actions} indentCells={indentCells} />
            } else if (entry.type === 'group' || entry.type === 'group_public') {
                return <Group members={members} key={entry.uuid} data={entry} mealPlanEntries={mealPlanEntries} actions={actions} indentCells={indentCells} />
            } else if (entry.type === 'recipe') {
                return <Recipe members={members} key={entry.uuid} data={entry} mealPlanEntries={mealPlanEntries} actions={actions} indentCells={indentCells} />
            } else if (entry.type === 'section') {
                return <Section members={members} key={entry.uuid} data={entry} mealPlanEntries={mealPlanEntries} actions={actions} indentCells={indentCells} />
            }
            return null;
        });
}