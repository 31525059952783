import React from "react";

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Clear from '@material-ui/icons/Clear';

import Search from '@material-ui/icons/Search';

export default function SearchInput(props) {
    const { title, value, onChange, style, size } = props;

    const handleChange = event => {
        onChange(event.target.value);
    };


    return <FormControl fullWidth variant="filled" size={size || 'medium'}>
        <InputLabel htmlFor="standard-adornment-password">{title}</InputLabel>
        <OutlinedInput
            id="standard-adornment-password"
            type='text'
            value={value}
            onChange={handleChange}
            style={{ overflow: 'hidden', ...style }}

            autoComplete="off"
            endAdornment={
                <InputAdornment position="end">
                    <IconButton onClick={() => { onChange('') }}>
                        {value === '' ? <Search /> : <Clear />}
                    </IconButton>
                </InputAdornment>
            }
        />
    </FormControl>
}