import React from "react";
import { Link } from "react-router-dom";

import imagePlaceholder from "assets/images/recipe/placeholder.jpg";


export default function CategoryList(props) {
    const { data } = props;

    return <div>
        {data.map((category) => {
            let imagePath = category?.image?.path || imagePlaceholder;

            return <div
                key={category.uuid}
                style={{
                    position: 'relative',
                    fontWeight: 'bold',
                    minHeight: '100px',
                    color: category.image_text_color,
                    fontSize: '20px'
                }}>
                <Link to={`/recipe-categories/${category.uuid}`} style={{ color: category.image_text_color }}>
                    <img alt='' src={imagePath} style={{ width: '100%', display: 'block' }} />
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        maxWidth: '45%',
                        left: '20px',
                        transform: 'translate(0, -50%)'
                    }}>
                        {category.name}
                    </div>
                </Link>
            </div>
        })}
    </div>
}