import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useSFLApi } from 'api';
import { FormattedMessage } from 'react-intl';
import DateDisplay from 'sfl-components/DateDisplay';
import moment from 'moment';

const today = moment().format('YYYY-MM-DD');

export default function Appointments() {
    const [isLoading, setIsLoading] = useState(true)
    const [appointments, setAppointments] = useState([])
    const [profile, setProfile] = useState({})
    const { get } = useSFLApi();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [profileResults, appointmentResults] = await Promise.all([
                    get(`profile`),
                    get(`pos/appointments?minDate=${today}&status=Scheduled&limit=5`)
                ])

                setProfile(profileResults.profile)
                setAppointments(appointmentResults.appointments.map(appt => {
                    const startHour = parseInt(appt.start_hour)
                    const startMinutes = appt.start_minute.length < 2 ? `0${appt.start_minute}` : appt.start_minute
                    const amPmStr = startHour >= 12 ? 'pm' : 'am'
                    return {
                        ...appt,
                        time_display: `${startHour}:${startMinutes}${amPmStr}`
                    }
                }))
                setIsLoading(false)
            }
            catch (error) {
                console.log('Error', error)
            }
        }

        fetchData()
    }, [get])


    if (isLoading === true || appointments.length === 0) {
        return null
    }

    return <div style={{ marginBottom: '50px' }}>
        <div style={{ padding: '20px', color: '#3c4858' }}>
            <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
                <FormattedMessage id="app.appointmentSchedule" defaultMessage="Appointment Schedule" />
            </div>
            <div style={{ paddingTop: '10px' }}>
                <FormattedMessage
                    id="app.appointmentSchedule"
                    defaultMessage="For all changes, please contact us at {phone} or {email}."
                    values={{
                        phone: profile.location_email || '',
                        email: profile.location_phone || ''
                    }}
                />
            </div>
        </div>
        {appointments.map((appt, index) => <Paper key={index} elevation={3} style={{ color: '#555555' }}>
            <div style={{ padding: '20px', backgroundColor: '#f7f7f7' }} >
                <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    <DateDisplay
                        date={appt.date}
                        dateFormats={{
                            en: 'dddd, MMMM Do',
                            fr: 'dddd, [le] d MMMM'
                        }}
                    /> @ {appt.time_display}
                </div>
                <div>{appt.consultant_first_name} {appt.consultant_last_name}</div>
                <div>{appt.consultant_email}</div>
            </div>
        </Paper>
        )}
    </div>
}