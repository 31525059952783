import React from "react";

import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux'
import { setLanguage } from 'store/appSettings';



export default function TitleBar() {
    const dispatch = useDispatch();
    const { language = 'en' } = useSelector(state => state.appSettings);
    const session = useSelector(state => state.session);
    const langToggleUsers = ['00000000-0000-0000-0000-0000000000d1']


    return <div style={{ display: 'flex', padding: '15px 40px 15px 0px' }}>
        <div style={{ flexGrow: '1' }} />
        {langToggleUsers.includes(session.userUuid) && <>
            <Button onClick={() => { dispatch(setLanguage('fr')) }} className={language === 'fr' ? 'languageActive' : 'languageInactive'} style={{ marginRight: '20px' }}>FR</Button>
            <Button onClick={() => { dispatch(setLanguage('en')) }} className={language === 'en' ? 'languageActive' : 'languageInactive'} style={{ marginRight: '20px' }}>EN</Button>
        </>}
        {session.userType === 'consultant' && <>
            <Button onClick={() => { dispatch(setLanguage('fr')) }} className={language === 'fr' ? 'languageActive' : 'languageInactive'} style={{ marginRight: '20px' }}>FR</Button>
            <Button onClick={() => { dispatch(setLanguage('en')) }} className={language === 'en' ? 'languageActive' : 'languageInactive'} style={{ marginRight: '20px' }}>EN</Button>
        </>}
    </div>
}