import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { useSelector } from 'react-redux'

import Popover from '@material-ui/core/Popover';
import { getDateFormat } from "misc";

import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';



export default function DateField(props) {
    const [showDatePicker, setShowDatePicker] = useState(null);
    const { locale = 'en-ca' } = useSelector(state => state.appSettings);
    const dateFormat = getDateFormat(locale)
    const open = Boolean(showDatePicker);
    const id = open ? 'simple-popover' : undefined;

    let date = moment(props.value);
    let dateDisplay = '';

    if (date.isValid()) {
        dateDisplay = date.locale(locale).format(dateFormat);
    } else {
        date = moment();
    }



    const handleClose = () => {
        setShowDatePicker(null);
    };

    const selectDate = (d) => {
        props.onChange(props.id, d.format('YYYY-MM-DD'));
        handleClose();
    }

    const handleClick = (event) => {
        setShowDatePicker(event.currentTarget);
    };



    return (<>
        <TextField
            id={props.id}
            label={props.label}
            value={dateDisplay}
            fullWidth
            autoComplete="off"
            variant={props.variant}
            onClick={handleClick}
        />
        <Popover
            id={id}
            open={open}
            anchorEl={showDatePicker}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <DayPickerSingleDateController
                date={date}
                onDateChange={selectDate}
                focused={true}
                hideKeyboardShortcutsPanel={true}
            />
        </Popover>
    </>);
}

