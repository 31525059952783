
import React, { useState } from "react";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";
import DateCycle from "sfl-components/DateCycle";
import { FormattedMessage } from 'react-intl';

import List from './List';

export default function FoodLogPastLogForm(props) {
    const { onClose, date } = props;
    const [pastLogsDate, setPastLogsDate] = useState(props.date);

    return <>
        <MobileNavbar>
            <NavBarButton onClick={onClose}>
                <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
            </NavBarButton>
            <NavBarTitle>
                <FormattedMessage id="app.foodLog" defaultMessage="Food Log" />
            </NavBarTitle>
        </MobileNavbar>
        <DateCycle date={pastLogsDate} onChange={setPastLogsDate} />
        <List {...props} date={date} pastLogsDate={pastLogsDate} />
    </>
}