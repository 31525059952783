import moment from 'moment';
import { useSelector } from 'react-redux'
import { getDateFormat } from "misc";

export default function DateDisplay(props) {
    const { date, dateFormats = {} } = props;
    const { locale = 'en-ca', language = 'en' } = useSelector(state => state.appSettings);
    const dateFormat = dateFormats[language] ? dateFormats[language] : getDateFormat(locale)

    return moment(date).locale(locale).format(dateFormat)
}