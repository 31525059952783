


import React from "react";
import Paper from '@material-ui/core/Paper';

export default function SectionBody(props) {
    return <Paper elevation={3} style={{ backgroundColor: 'white', borderBottom: '3px solid #6e3076', marginBottom: '40px' }}>
        {props.children}
    </Paper>
}
