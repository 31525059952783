import React from "react";

import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';
import WeightTextField from "sfl-components/Inputs/WeightTextField";

import Question from './Question';
import { FormattedMessage, useIntl } from 'react-intl';


const QuestionnaireGoals = props => {
    const {
        handleChange,
        primary_goal = '',
        current_weight = '',
        current_weight_scale = 'pounds',
        goal_weight = '',
        goal_weight_scale = 'pounds',
        goal_celebration = ''
    } = props
    const intl = useIntl();


    return <Card key='goals' title={intl.formatMessage({ id: "questionnaire.whatAreYourGoals", defaultMessage: "What are you goals?" })} titleMode="inner" style={{ marginBottom: '75px' }}>
        <div style={{ padding: '0 20px' }}>
            <Grid container spacing={3} >
                <Question
                    id="primary_goal"
                    label={intl.formatMessage({ id: "questionnaire.whatIsYourPrimaryGoal", defaultMessage: "What is your primary goal?" })}
                    helperText=" "
                    select
                    value={primary_goal}
                    onChange={handleChange}
                >
                    <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='moreEnergy' value='moreEnergy'><FormattedMessage id="questionnaire.moreEnergy" defaultMessage="I want to have more energy" /></MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='feelBetter' value='feelBetter'><FormattedMessage id="questionnaire.feelBetter" defaultMessage="I want to feel better" /></MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='loseWeight' value='loseWeight'><FormattedMessage id="questionnaire.loseWeight" defaultMessage="I want to lose weight" /></MenuItem>
                </Question>
                <Grid item xs={12} lg={6}>
                    <WeightTextField
                        id="current_weight"
                        label={intl.formatMessage({ id: "questionnaire.whatIsYourCurrentWeight", defaultMessage: "What's your current weight?" })}
                        helperText=" "
                        weight={current_weight}
                        scale={current_weight_scale}
                        onWeightChange={(updatedWeight) => handleChange('current_weight', updatedWeight)}
                        onWeightScaleChange={(updatedWeightScale) => handleChange('current_weight_scale', updatedWeightScale)}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WeightTextField
                        id="goal_weight"
                        label={intl.formatMessage({ id: "questionnaire.whatIsYourGoalWeight", defaultMessage: "What's your goal weight?" })}
                        helperText=" "
                        weight={goal_weight}
                        scale={goal_weight_scale}
                        onWeightChange={(updatedWeight) => handleChange('goal_weight', updatedWeight)}
                        onWeightScaleChange={(updatedWeightScale) => handleChange('goal_weight_scale', updatedWeightScale)}
                    />
                </Grid>
                <Question
                    id="goal_celebration"
                    label={intl.formatMessage({ id: "questionnaire.celebration", defaultMessage: "Once you reach your goal, how would you like to celebrate?" })}
                    multiline
                    rows={3}
                    rowsMax={10}
                    value={goal_celebration}
                    onChange={handleChange}
                />
            </Grid>
        </div>
    </Card>
}


export default React.memo(QuestionnaireGoals);