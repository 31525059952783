const PREFIX = 'APP_SETTINGS_';
const TOGGLE_SIDEBAR = `${PREFIX}TOGGLE_SIDEBAR`;
const SET_LANGUAGE = `${PREFIX}SET_LANGUAGE`;
const SET_RECIPE_SEARCH_TERM = `${PREFIX}SET_RECIPE_SEARCH_TERM`;
const SET_RECIPE_SEARCH_LIMIT = `${PREFIX}SET_RECIPE_SEARCH_LIMIT`;

export const toggleSidebar = () => {
	return { type: TOGGLE_SIDEBAR }
}

export const setLanguage = (language) => {
	return { type: SET_LANGUAGE, language: language }
}

export const setRecipeSearchTerm = (term) => {
	return { type: SET_RECIPE_SEARCH_TERM, term: term }
}

export const setRecipeSearchLimit = (limit) => {
	return { type: SET_RECIPE_SEARCH_LIMIT, limit: limit }
}

//const defaultLanguage = navigator.language.toLowerCase().substr(0, 2) === 'fr' ? 'fr' : 'en'

const initialState = {
	sidebarOpen: false,
	language: 'en',
	locale: 'en-ca',
	recipeSearchTerm: '',
	recipeSearchLimit: 40
}

export const appSettings = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return {
				...state,
				sidebarOpen: !state.sidebarOpen
			};
		case SET_LANGUAGE:
			return {
				...state,
				language: action.language,
				locale: `${action.language}-ca`,
			};
		case SET_RECIPE_SEARCH_TERM:
			return {
				...state,
				recipeSearchTerm: action.term,
				recipeSearchLimit: 40
			};
		case SET_RECIPE_SEARCH_LIMIT:
			return {
				...state,
				recipeSearchLimit: action.limit
			};
		default:
			return state
	}
}