import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';


export default function AddEntryDialog(props) {
    const { open, onSelect, onClose } = props;
    const intl = useIntl();


    return <Dialog onClose={() => { onClose() }} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">
            <FormattedMessage id="foodLog.whereFrom" defaultMessage="Where from?" />
        </DialogTitle>
        <List>
            <ListItem button onClick={() => { onClose(); onSelect('ingredientsToggle'); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                    <FontAwesomeIcon icon={['fas', 'indent']} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "foodLog.showIngredients", defaultMessage: 'Show Ingredients' })} style={{ padding: '0px 20px' }} />
            </ListItem>
            <ListItem button onClick={() => { onClose(); onSelect('edit'); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                    <FontAwesomeIcon icon={['fas', 'edit']} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "app.edit", defaultMessage: "Edit" })} style={{ padding: '0px 20px' }} />
            </ListItem>
            <ListItem button onClick={() => { onClose(); onSelect('delete'); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                    <FontAwesomeIcon icon={['fas', 'plus-square']} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "app.delete", defaultMessage: "Delete" })} style={{ padding: '0px 20px' }} />
            </ListItem>
        </List>
    </Dialog>
}