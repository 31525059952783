import React from "react";
import Hidden from "@material-ui/core/Hidden";
import RecipeDetailsMobile from './RecipeDetails.mobile';
import RecipeDetailsDesktop from './RecipeDetails.desktop';

export default function RecipeDetails() {
	return <>
		<Hidden smDown>
			<RecipeDetailsDesktop />
		</Hidden>
		<Hidden mdUp>
			<RecipeDetailsMobile />
		</Hidden>
	</>
}