import React from "react";
import { Row, Cell } from 'sfl-components/Containers2';

import CircleButton from 'sfl-components/Buttons/CircleButton';
import Portions from 'sfl-components/Portions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import generateEntry from './Entry';
import css from './styles.module.css';

export default function Food(props) {
    const { cartToggle, favoriteToggle, editEntry } = props.actions;
    const indentCells = [...props.indentCells];
    const { members } = props;
    const { uuid, name, ingredient_description, portions = [], shopping_list, favorite, displayChildren } = props.data

    const buttons = [
        <CircleButton key='grocery' style={{ marginLeft: '10px' }} className={shopping_list ? 'greenText' : 'hoverGreenText'} onClick={cartToggle.bind(this, uuid)}><FontAwesomeIcon icon={['fas', 'shopping-cart']} /></CircleButton>,
        <CircleButton key='favorite' style={{ marginLeft: '10px' }} className={favorite ? 'redText' : 'hoverRedText'} onClick={favoriteToggle.bind(this, uuid)}><FontAwesomeIcon icon={['fas', 'heart']} /></CircleButton>,
        /*<CircleButton style={{ marginLeft: '10px' }} className='hoverBlueText' onClick={helpToggle.bind(this, uuid)}><FontAwesomeIcon icon={['fas', 'question-circle']} /></CircleButton>*/
    ]


    if (indentCells.length === 0) {
        indentCells.push(<Cell key='indent' style={{ flexGrow: '0', width: '20px' }} />)
    }
    const childContent = displayChildren && generateEntry(props.mealPlanEntries, props.members, uuid, props.actions, [...indentCells])

    return [
        <Row hover key={uuid}>
            {indentCells}
            <Cell
                className={css.hoverCursor}
                style={{ padding: '10px 10px 10px 0' }}
                onClick={() => { editEntry(uuid) }}
            >
                <b className={css.hoverUnderline}>{name}</b>
                {ingredient_description !== '' ? <div>{ingredient_description}</div> : null}
            </Cell>
            <Cell className='cellBorders' style={{ flexGrow: '0', width: '200px', minWidth: '200px', padding: '10px' }}>
                <Portions members={members} portions={portions} />
            </Cell>
            <Cell style={{ flexGrow: '0', width: '150px', minWidth: '150px', textAlign: 'right', padding: '10px' }}>
                {buttons}
            </Cell>
        </Row>,
        childContent
    ]
}
