import React, { useState } from "react";
import CardButton from 'sfl-components/Card/CardButton';
import Dialog from 'sfl-components/Dialog';
import DateCycle from "sfl-components/DateCycle";

import List from './List';
import { FormattedMessage } from 'react-intl';


export default function FoodLogMealPlanForm(props) {
    const { memberUuid, date, onClose } = props;
    const [pastLogsDate, setPastLogsDate] = useState(props.date);
    const [triggerSave, setTriggerSave] = useState(false);


    const handleSaveClick = () => {
        setTriggerSave(true)
    }

    const buttons = [
        <CardButton key='cancel' icon='backspace' onClick={onClose}><FormattedMessage id="app.cancel" defaultMessage="Cancel" /></CardButton>,
        <CardButton key='save' icon='backup' onClick={(handleSaveClick)}><FormattedMessage id="app.save" defaultMessage="Save" /></CardButton>
    ]


    return <Dialog buttons={buttons} onClose={onClose} fullWidth={true} maxWidth='lg'
        PaperProps={{
            style: { verticalAlign: 'top', backgroundColor: 'transparent', boxShadow: 'none' }
        }}
    >
        <DateCycle date={pastLogsDate} onChange={setPastLogsDate} />
        <div style={{minHeight:'500px'}}>
            <List memberUuid={memberUuid} triggerSave={triggerSave} date={date} pastLogsDate={pastLogsDate} {...props} />
        </div>
    </Dialog>
}