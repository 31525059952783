import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSFLApi } from 'api';


export default function FoodInput(props) {
    const [options, setOptions] = useState([]);
    const { get } = useSFLApi();
    const { value = '',onChange } = props;

    useEffect(() => {
        (async () => {
            if (value.length < 3) {
                setOptions([]);
            } else {
                const response = await get(`foods?fields=name&limit=10&name=${value}`)
                setOptions(response.foods.map(food => food.name));
            }

        })();
    }, [value, get]);

    return <Autocomplete
        freeSolo
        options={options}
        value={value}
        onInputChange={(event, newValue) => {
            onChange(newValue ? newValue : '')
        }}
        renderInput={(params) => {
            return <TextField
                {...params}
                label={props.label}
                variant="outlined"
            />
        }}
    />
}