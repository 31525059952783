import React from 'react';
import Chart from "react-apexcharts";

export default function ProgressChart(props) {
    const { series } = props

    const options = {
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        colors: ['#6b3374', '#b4bb00'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', '#f3f3f3'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            type: 'datetime',
        }
    }

    return <Chart options={options} series={series} type="line" height={350} />
}