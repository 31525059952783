import React from "react";

import Grid from '@material-ui/core/Grid';

import MedicalConditionSelect from 'sfl-components/MedicalConditionSelect';
import Card from 'sfl-components/SFLCards/Card';

import Question from './Question';


const QuestionnaireMedical = props => {
    const {
        handleChange,
        family_doctor = '',
        last_checkup = '',
        setMedicalConditions,
        medicalConditions = [],
        medical_conditions_extra = '',
        medications = ''
    } = props


    return <Card key='medical' title="Medical Information" titleMode="inner">
        <Grid container spacing={3} style={{ padding: '0 20px' }}>
            <Grid item xs={12} >
                <Grid container spacing={3}>
                    <Question
                        id="family_doctor"
                        label="Who is your family doctor?"
                        helperText=" "
                        value={family_doctor}
                        onChange={handleChange}
                    />
                    <Question
                        id="last_checkup"
                        label="When was your last checkup?"
                        helperText="An approximate date is sufficient if you're unsure."
                        value={last_checkup}
                        onChange={handleChange}
                    />
                    <Grid item xs={12} >
                        <MedicalConditionSelect
                            id='MedicalConditionSelect'
                            label="Do you suffer from any of the following medical conditions?"
                            helperText=" "
                            selectedOptions={medicalConditions}
                            onChange={(medicalConditionsList) => { setMedicalConditions(medicalConditionsList) }}
                        />
                    </Grid>
                    <Question
                        id="medical_conditions_extra"
                        label="Do you have any other medical conditions?"
                        multiline
                        rows={2}
                        rowsMax={10}
                        value={medical_conditions_extra}
                        onChange={handleChange}
                    />
                    <Question
                        id="medications"
                        label="Which medications do you take?"
                        multiline
                        rows={2}
                        rowsMax={10}
                        value={medications}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Card>
}


export default React.memo(QuestionnaireMedical);