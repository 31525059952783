import React from "react";
import { Row, Cell } from 'sfl-components/Containers2';

import CircleButton from 'sfl-components/Buttons/CircleButton';
import Portions from 'sfl-components/Portions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import generateEntry from './Entry';
import { FormattedMessage } from 'react-intl';
import css from './styles.module.css';

export default function Food(props) {
    const { cartToggle, favoriteToggle, editEntry } = props.actions;
    const parentIndentCells = [...props.indentCells];
    const childIndentCells = [...props.indentCells];
    const { members } = props;
    const { uuid, name, ingredient_description, portions = [], shopping_list, recipe_servings, favorite, displayChildren } = props.data

    const buttons = [
        <CircleButton key='grocery' style={{ marginLeft: '10px' }} className={shopping_list ? 'greenText' : 'hoverGreenText'} onClick={cartToggle.bind(this, uuid)}><FontAwesomeIcon icon={['fas', 'shopping-cart']} /></CircleButton>,
        <CircleButton key='favorite' style={{ marginLeft: '10px' }} className={favorite ? 'redText' : 'hoverRedText'} onClick={favoriteToggle.bind(this, uuid)}><FontAwesomeIcon icon={['fas', 'heart']} /></CircleButton>,
        /*<CircleButton style={{ marginLeft: '10px' }} className='hoverBlueText' onClick={helpToggle.bind(this, uuid)}><FontAwesomeIcon icon={['fas', 'question-circle']} /></CircleButton>*/
    ]

    if (childIndentCells.length === 0) {
        childIndentCells.push(<Cell style={{ flexGrow: '0', width: '20px' }} />)
        parentIndentCells.push(<Cell style={{ flexGrow: '0', width: '20px' }} />)
    }
    childIndentCells.push(<Cell key='indentBorder' style={{ flexGrow: '0', width: '20px', borderLeft: '5px solid #6e3076' }} />)
    const childContent = displayChildren && [
        <Row hover key={uuid} >
            {childIndentCells}
            <Cell style={{ padding: '10px 10px 10px 0' }} onClick={() => { editEntry(uuid) }}>
                <b className='purple'><FormattedMessage id="app.recipeIngredients" defaultMessage="Recipe Ingredients" /></b>
                <div>
                    <FormattedMessage
                        id="app.MakesXServings"
                        defaultMessage="Makes {recipe_servings, plural, one {# serving} other {# servings}}"
                        values={{ recipe_servings: recipe_servings }}
                    />
                </div>
            </Cell >
            <Cell className='cellBorders' style={{ flexGrow: '0', width: '200px', minWidth: '200px', padding: '10px' }}>

            </Cell>
            <Cell style={{ flexGrow: '0', width: '150px', minWidth: '150px', textAlign: 'right', padding: '10px' }}>

            </Cell>
        </Row>,
        ...generateEntry(props.mealPlanEntries, props.members, uuid, props.actions, childIndentCells)
    ]



    return [
        <Row hover key={uuid}>
            {parentIndentCells}
            <Cell
                className={css.hoverCursor}
                style={{ padding: '10px 10px 10px 0' }}
                onClick={() => { editEntry(uuid) }}
            >
                <b className={css.hoverUnderline}>{name}</b>
                {ingredient_description !== '' ? <div>{ingredient_description}</div> : null}
            </Cell>
            <Cell className='cellBorders' style={{ flexGrow: '0', width: '200px', minWidth: '200px', padding: '10px' }}>
                <Portions members={members} portions={portions} />
            </Cell>
            <Cell style={{ flexGrow: '0', width: '150px', minWidth: '150px', textAlign: 'right', padding: '10px' }}>
                {buttons}
            </Cell>
        </Row>,
        childContent
    ]
}
