import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { ClickAwayListener, Grow, Paper, Popper, Button, ButtonGroup, MenuItem, MenuList } from '@material-ui/core';

const RowButton = (props) => {
	const { actions, actionId } = props;
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);


	const handleToggle = () => {
		setOpen(!open);
	};

	const handleClick = (action) => {
		setOpen(false);
		action(actionId);
	}

	if (actions.length === 1) {
		return <ButtonGroup
			variant="contained"
			color="primary"
			ref={anchorRef}
			size='small'
		>
			<Button onClick={() => handleClick(actions[0].onClick)} style={{ textTransform: 'none', background: '#656469 ', borderColor: 'white', whiteSpace: 'nowrap' }}>
				{actions[0].title}
			</Button>
		</ButtonGroup>
	}

	return (<>
		<ButtonGroup
			variant="contained"
			color="primary"
			ref={anchorRef}
			size='small'
		>
			<Button onClick={() => handleClick(actions[0].onClick)} style={{ textTransform: 'none', background: '#656469 ', borderColor: 'white', whiteSpace: 'nowrap' }}>
				{actions[0].title}
			</Button>
			<Button onClick={handleToggle} style={{ background: '#656469 ', borderColor: 'white' }}>
				<FontAwesomeIcon icon={faAngleDown} fixedWidth />
			</Button>
		</ButtonGroup>
		<Popper open={open} anchorEl={anchorRef.current} transition placement='bottom-end' style={{ zIndex: 10 }} disablePortal>
			<Grow in={true} style={{ transformOrigin: 'top' }}>
				<Paper>
					<ClickAwayListener onClickAway={handleToggle}>
						<MenuList>
							{actions.map((action, action_key) => {
								return <MenuItem onClick={() => handleClick(action.onClick)} key={action_key}>
									{action.title}
								</MenuItem>
							})}

						</MenuList>
					</ClickAwayListener>
				</Paper>
			</Grow>
		</Popper>
	</>);
}

export default RowButton;