import React, { useState, Fragment } from 'react';
import CheckboxDialog from 'sfl-components/CheckboxDialog';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

export default function CheckboxesTags(props) {
    const { options, selectedOptions, onChange } = props;
    const [open, setOpen] = useState(false);

    return [
        <CheckboxDialog
            key='CheckboxDialog'
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            options={options}
            {...props}
        />,
        <List key='List'>
            <ListItem style={{ paddingLeft: '0', paddingRight: '0' }} onClick={() => {
                setOpen(true)
            }}>
                <ListItemText
                    secondary="View List"
                />
                <ListItemIcon style={{ justifyContent: 'center' }}>
                    <SearchIcon />
                </ListItemIcon>
            </ListItem>
            <Divider />
            {options.filter(option => selectedOptions.includes(option.uuid))
                .map((option, index) => <Fragment key={index}>
                    <ListItem style={{ paddingLeft: '0', paddingRight: '0' }} >
                        <ListItemText
                            primary={option.name}
                            secondary={option.description}
                        />
                        <ListItemIcon style={{ justifyContent: 'center' }} onClick={() => {
                            onChange(selectedOptions.filter(selectedOption => selectedOption !== option.uuid))
                        }}>
                            <DeleteIcon />
                        </ListItemIcon>
                    </ListItem>
                    <Divider />
                </Fragment>)
            }
        </List>
    ]
}