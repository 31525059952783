import React from "react";

import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';

import AllergySelect from 'sfl-components/AllergySelect';
import FoodPreferenceSelect from 'sfl-components/FoodPreferenceSelect';

import Question from './Question';


const QuestionnairePreferences = props => {
    const {
        handleChange,
        average_food='',
        setLikedFoods,
        likedFoods = [],
        setDislikedFoods,
        dislikedFoods = [],
        setAllergies,
        allergies = [],
        allergies_restrictions_other=''
    } = props


    return <Card key='preferences' title="What are you food preferences?" titleMode="inner">
        <Grid container spacing={3} style={{ padding: '0 20px' }}>
            <Grid item xs={12} >
                <Grid container spacing={3}>
                    <Question
                        id="average_food"
                        label="What do you normally eat during the run of a day?"
                        helperText="For example: I normally have a muffin and a latte for breakfast and for lunch I have..."
                        multiline
                        rows={2}
                        rowsMax={10}
                        value={average_food}
                        onChange={handleChange}
                    />
                    <Grid item xs={12} >
                        <FoodPreferenceSelect
                            id="FoodPreferenceSelect1"
                            label="What types of foods do you love to eat?"
                            helperText=" "
                            selectedOptions={likedFoods}
                            onChange={setLikedFoods}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <FoodPreferenceSelect
                            id="FoodPreferenceSelect2"
                            label="What types of foods do you not like to eat?"
                            helperText=" "
                            selectedOptions={dislikedFoods}
                            onChange={setDislikedFoods}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <AllergySelect
                            id="AllergySelect"
                            label="What types of foods are you allergic to?"
                            helperText=" "
                            selectedOptions={allergies}
                            onChange={(allergyList) => { setAllergies(allergyList) }}
                        />
                    </Grid>
                    <Question
                        id="allergies_restrictions_other"
                        label="Are there any other allergies or restrictions you would like to mention?"
                        multiline
                        rows={2}
                        rowsMax={10}
                        value={allergies_restrictions_other}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Card>
}


export default React.memo(QuestionnairePreferences);