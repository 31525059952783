import React from "react";

import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import Popper from '@material-ui/core/Popper';


export default function DatePickerPopover(props) {
    const { open, onClose, anchorEl, onDateChange, date, calendarProps } = props;


    return <Popper
        id='simple-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        transition
        placement='bottom-end'
        style={{ zIndex: 10 }}
        disablePortal
        anchororigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformorigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
    >
        <DayPickerSingleDateController
            date={date}
            onDateChange={onDateChange}
            focused={true}
            hideKeyboardShortcutsPanel={true}
            {...calendarProps}
        />
    </Popper>
}