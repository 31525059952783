import React from "react";
import Button from '@material-ui/core/Button';

import FilledInput from '@material-ui/core/FilledInput';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function WeightTextField(props) {
    const updateValue = event => {
        props.onWeightChange(event.target.value);
    }


    const mode = props.scale === 'kg' ? 'kg' : 'lbs';

    const setWeightToKg = () => {
        if (mode !== 'kg') {
            props.onWeightScaleChange('kg');
            //props.onWeightChange(Math.round(Number(props.weight) * 10 * 0.453592) / 10);
        }
    }

    const setWeightToLbs = () => {
        if (mode === 'kg') {
            props.onWeightScaleChange('lbs');
            //props.onWeightChange(Math.round(Number(props.weight) * 10 * 2.20462) / 10);
        }
    }

    const activeStyle = {
        minWidth: '0',
        padding: '0 10px',
        color: 'white',
        backgroundColor: '#202020'
    }
    const inactiveStyle = {
        minWidth: '0',
        padding: '0 10px'
    }

    const InputComponent = props.variant === 'standard' ? Input : FilledInput;

    return <FormControl fullWidth variant={props.variant === 'standard' ? 'standard' : 'filled'}>
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <InputComponent
            id={props.id}
            type="text"
            fullWidth
            value={props.weight}
            onChange={updateValue}
            endAdornment={
                <InputAdornment position="end">
                    <Button style={mode === 'kg' ? activeStyle : inactiveStyle} onClick={setWeightToKg}>Kg</Button>
                    <Button style={mode !== 'kg' ? activeStyle : inactiveStyle} onClick={setWeightToLbs}>Lbs</Button>
                </InputAdornment>
            }
        />
        <FormHelperText id="component-helper-text">{props.helperText}</FormHelperText>
    </FormControl>
}