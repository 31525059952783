import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './store'

import ViewportProvider from 'sfl-components/ViewportProvider'
import LanguageProvider from 'sfl-components/LanguageProvider'

// core components
import Layout from "layouts";
import "./common.css";


import "assets/css/material-dashboard-react.css?v=1.8.0";
import { loadState, saveState } from 'store/sessionStorage';


import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faBuilding, faUser, faPhone, faStar, faArrowsAlt, faPrint, faChevronDown, faChevronUp, faHistory, faSearch, faHome, faExclamationCircle, faSmileBeam, faChevronLeft, faTimes, faChevronRight, faDotCircle, faCloudUploadAlt, faCheckSquare, faCircle, faShare, faBackspace, faTrash, faSync, faCalendarAlt, faPlusSquare, faEdit, faTimesCircle, faPlusCircle, faShoppingCart, faHeart, faIndent, faNewspaper, faQuestionCircle, faCommentDots, faEllipsisV, faSquare as faSolidSquare } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faFilePdf, faSquare, faHeart as faHeartRegular, faCalendarAlt as faCalendarAltRegular } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faInstagram, faYoutube, faTwitter, faPinterest } from '@fortawesome/free-brands-svg-icons';




import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

library.add(
	faChevronUp,
	faBuilding,
	faUser,
	faPhone,
	faEnvelope,
	faSolidSquare,
	faCalendarAltRegular,
	faArrowsAlt,
	faStar,
	faSmileBeam,
	faHeartRegular,
	faFilePdf,
	faPrint,
	faChevronDown,
	faHistory,
	faPinterest,
	faFacebook,
	faInstagram,
	faYoutube,
	faTwitter,
	faSearch,
	faHome,
	faTimes,
	faChevronRight,
	faChevronLeft,
	faCloudUploadAlt,
	faSquare,
	faCheckSquare,
	faCalendarAlt,
	faShare,
	faDotCircle,
	faCircle,
	faExclamationCircle,
	faSync,
	faBackspace,
	faPlusSquare,
	faEdit,
	faIndent,
	faNewspaper,
	faTimesCircle,
	faPlusCircle,
	faShoppingCart,
	faHeart,
	faQuestionCircle,
	faCommentDots,
	faEllipsisV,
	faTrash
);

const browserHistory = createBrowserHistory();

const persistedState = loadState();
const store = createStore(rootReducer, persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
store.subscribe(() => {
	saveState(store.getState());
});


ReactDOM.render(
	<ViewportProvider>
		<Provider store={store}>
			<LanguageProvider>
				<Router history={browserHistory}>
					<Layout />
				</Router>
			</LanguageProvider>
		</Provider>
	</ViewportProvider>,
	document.getElementById("root")
);
