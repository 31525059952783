import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import Dialog from 'sfl-components/Dialog';
import Grid from '@material-ui/core/Grid';
import CardButton from 'sfl-components/Card/CardButton';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from 'sfl-components/TextField/Outlined';
import { useSFLApi } from 'api';
import DateField from 'sfl-components/Inputs/DateField';
import { toast } from 'react-toastify';
import moment from 'moment';


export default function BloodPressureDialog(props) {
    const { userUuid } = useSelector(state => state.session);
    const { memberUuid = userUuid } = useParams();
    const { open, onClose, uuid } = props;
    const [state, setState] = useState({ date: moment().format('YYYY-MM-DD'), upper_value: '', lower_value: '' })
    const { get, post } = useSFLApi();
    const intl = useIntl();
    const { date, upper_value = '', lower_value = '' } = state

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (uuid) {
                    const results = await get(`measurements/bloodPressure/${uuid}`)
                    setState(results.bloodPressure)
                } else {
                    setState({ date: moment().format('YYYY-MM-DD'), weight: '', upper_value: '', lower_value: '' })
                }
            }
            catch (error) {
                console.log('error', error)
                toast.error(intl.formatMessage({ id: "app.error", defaultMessage: 'Error!' }));
            }
        }
        fetchData()
    }, [props])

    const handleChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }

    const handleNumericChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }

    const handleSave = async () => {
        try {
            await post(uuid ? `measurements/bloodPressure/${uuid}` : `measurements/bloodPressure`, { member_uuid: memberUuid, ...state })
            toast.success('Measurement saved!')
            onClose()
        }
        catch (error) {
            toast.error('Save Failed!')
        }
    }

    const buttons = [
        <CardButton key='cancel' icon='backspace' onClick={onClose}><FormattedMessage id="app.cancel" defaultMessage="Cancel" /></CardButton>,
        <CardButton key='save' icon='backup' onClick={handleSave}><FormattedMessage id="app.save" defaultMessage="Save" /></CardButton>
    ]

    return <Dialog buttons={buttons} onClose={onClose} fullWidth={true} maxWidth='sm' open={open}>
        <Grid container spacing={3} style={{ color: '#868e96' }}>
            <Grid item xs={12}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, }}>
                        <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '15px' }}>
                            <FormattedMessage id="app.date" defaultMessage="Date" />
                        </div>
                    </div>
                    <div style={{ width: '220px', marginLeft: '20px' }}>
                        <DateField
                            id="date"
                            label={intl.formatMessage({ id: "app.Date", defaultMessage: "Date" })}
                            variant="outlined"
                            value={date}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, }}>
                        <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '15px' }}>
                            <FormattedMessage id="app.bloodPressure" defaultMessage="Blood Pressure" />
                        </div>
                    </div>
                    <div style={{ flex: 0, marginLeft: '20px' }}>
                        <TextField
                            id="upper_value"
                            label={intl.formatMessage({ id: "app.BloodPressureUpper.helperText", defaultMessage: "Ex: 100" })}
                            style={{ width: '100px' }}
                            helperText={intl.formatMessage({ id: "app.upper", defaultMessage: "Upper" })}
                            value={upper_value}
                            type="number"
                            onChange={handleNumericChange}
                        />
                    </div>
                    <div style={{ flex: 0, marginLeft: '20px' }}>
                        <TextField
                            id="lower_value"
                            label={intl.formatMessage({ id: "app.BloodPressureLower.helperText", defaultMessage: "Ex: 70" })}
                            style={{ width: '100px' }}
                            helperText={intl.formatMessage({ id: "app.lower", defaultMessage: "Lower" })}
                            value={lower_value}
                            type="number"
                            onChange={handleNumericChange}
                        />
                    </div>
                </div>
            </Grid>
        </Grid>
    </Dialog>
}