import React from "react";
import { useParams,useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CardTitle } from 'sfl-components/Containers2'
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";
import Ingredients from './components/Ingredients';

import { useApi } from 'api';
import imagePlaceholder from "assets/images/recipe/placeholder.jpg";

export default function RecipeDetails() {
    const { recipeUuid } = useParams();
    const history = useHistory();
    const { data: { recipe = {} } } = useApi(`recipes/${recipeUuid}`)

    const { name, images = [], directions, servings } = recipe;
    const mainImage = images[0]?.path || imagePlaceholder;

    return [
        <MobileNavbar key='MobileNavbar'>
            <NavBarButton onClick={() => { history.goBack() }}>
                <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
            </NavBarButton>
            <NavBarTitle><FormattedMessage id="app.recipes" defaultMessage="Recipes" /></NavBarTitle>
        </MobileNavbar>,
        <img key='recipeImage' alt='' src={mainImage} style={{ width: '100%', display: 'block' }} />,
        <Paper key='servings' elevation={3} style={{ padding: '20px' }}>
            <CardTitle>{name}</CardTitle>
            <div><FormattedMessage
                id="app.MakesXServings"
                defaultMessage="Makes {recipe_servings, plural, one {# serving} other {# servings}}"
                values={{ recipe_servings: servings }}
            /></div>
        </Paper>,
        <Paper key='ingredients' elevation={3} style={{ marginTop: '40px', padding: '20px' }}>
            <div style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '20px' }}><FormattedMessage id="app.ingredients" defaultMessage="Ingredients" /></div>
            <Ingredients recipeUuid={recipeUuid} />
        </Paper>,
        <Paper key='directions' elevation={3} style={{ marginTop: '40px', padding: '20px' }}>
            <div style={{ fontWeight: 'bold', fontSize: '24px' }}><FormattedMessage id="app.directions" defaultMessage="Directions" /></div>
            <ReactMarkdown>{directions}</ReactMarkdown>
        </Paper>
    ]
}