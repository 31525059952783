import React from "react";
import SectionEntry from 'sfl-components/MealPlan/SectionEntry';
import SectionEntryCell from 'sfl-components/MealPlan/SectionEntryCell';
import Card from 'sfl-components/SFLCards/Card';
import CardList from 'sfl-components/SFLCards/CardList';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';


import { formatAMPM } from 'misc';
import { FormattedMessage } from 'react-intl';

export default function MealPlanLayout(props) {
    const { title, entries, buttons, onEdit, onDelete, toggleChildren } = props;

    const generateEntry = (entries, parentUuid, indentCells = []) => {
        return entries.filter(entry => entry.parent_log_uuid === parentUuid)
            .map(entry => {
                const { uuid, name, ingredient_description, portion, scale, time, displayChildren } = entry;

                return [
                    <SectionEntry
                        key={uuid}
                        title={name}
                        description={ingredient_description}
                        onClick={() => { toggleChildren(uuid) }}
                        before={indentCells}
                    >
                        <SectionEntryCell>
                            {formatAMPM(time)}
                        </SectionEntryCell>
                        <SectionEntryCell>
                            {portion} {scale}
                        </SectionEntryCell>
                        <SectionEntryCell style={{ paddingRight: '0px' }}>
                            <IconButton style={{ width: '30px', height: '30px', fontSize: '15px', borderRadius: '25px', color: 'white', backgroundColor: '#6b3374' }} onClick={() => { onEdit(uuid) }}>
                                <FontAwesomeIcon icon={['fas', 'edit']} fixedWidth />
                            </IconButton>
                        </SectionEntryCell>
                        <SectionEntryCell>
                            <IconButton style={{ width: '30px', height: '30px', fontSize: '15px', borderRadius: '25px', color: 'white', backgroundColor: '#6b3374' }} onClick={() => { onDelete(uuid) }}>
                                <FontAwesomeIcon icon={['fas', 'times']} fixedWidth />
                            </IconButton>
                        </SectionEntryCell>
                    </SectionEntry>,
                    displayChildren === true && generateEntry(entries, uuid, [indentCells, <div key='indent' style={{ display: 'flex', width: '20px' }} />])
                ]
            });
    }

    return <Card title={title} buttons={buttons} titleMode='inner' style={{ marginBottom: '60px' }}>
        <CardList>
            {entries.length > 0
                ? generateEntry(props.entries, null, [])
                : <div style={{ textAlign: 'center', padding: '10px' }}>
                    <FormattedMessage id="foodLog.sectionEmpty" defaultMessage="Nothing to show yet. Try adding some food first." />
                </div>
            }
        </CardList>
    </Card>
}

