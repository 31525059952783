import React from "react";
import SectionTitle from 'sfl-components/MealPlan/SectionTitle';
import SectionBody from 'sfl-components/MealPlan/SectionBody';
import SectionEntry from 'sfl-components/MealPlan/SectionEntry';

export default function MealPlanLayout(props) {
    return [
        <SectionTitle key='title' title={props.title} onClick={props.onTitleClick} style={{ borderRadius: '0' }} />,
        <SectionBody key='content'>
            {generateEntry(props.entries, null, props.editEntry, [])}
            {props.children}
        </SectionBody>
    ]
}

export function generateEntry(entries, parentUuid, editEntry, indentCells = []) {
    return entries.filter(entry => entry.parent_log_uuid === parentUuid)
        .map(entry => {
            const { uuid, name, portion, scale, displayChildren } = entry;


            return [
                <SectionEntry
                    key={uuid}
                    title={name}
                    description={`${portion} ${scale}`}
                    onClick={() => { editEntry(uuid) }}
                    before={indentCells}
                >
                </SectionEntry>,
                displayChildren === true && generateEntry(entries, uuid, editEntry, [indentCells, <div key='indent' style={{ display: 'flex', width: '20px' }} />])
            ]
        });
}