import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useSFLApi } from 'api';
import { useIntl, FormattedMessage } from 'react-intl';
import { ConfirmationDialogStateContext } from "sfl-components/Dialog/ConfirmationDialogStateProvider";
import { toast } from 'react-toastify';
import ColoredButton from 'sfl-components/Buttons/Button';
import Paper from "@material-ui/core/Paper";
import Dialog from './Dialog';
import TimeframeSelect from '../TimeframeSelect';
import EntryOptions from '../EntryOptions';
import Pagination from 'sfl-components/Pagination';
import ProgressChart from '../../ProgressChart';
import { TitleBar, TitleBarItem } from '../Layout';
import DateDisplay from 'sfl-components/DateDisplay';


export default function WeightPanel() {
    const [records, setRecords] = useState([])
    const { get, del } = useSFLApi();
    const [showOptionsDialog, setShowOptionsDialog] = useState()
    const [editSettings, setEditSettings] = useState()
    const [page, setPage] = useState(1)
    const [timeframeId, setTimeframeId] = useState('3')
    const [showTimeframeSelect, setShowTimeframeSelect] = useState()
    const intl = useIntl();
    const { openDialog } = React.useContext(ConfirmationDialogStateContext);
    const startDate = moment().subtract(timeframeId, 'months').format('YYYY-MM-01')

    const loadData = async () => {
        try {
            const results = await get(`measurements/sleep?date_start=${startDate}`)
            setRecords(results.sleep)
        }
        catch (error) {
            setRecords([])
        }
    }

    const deleteEntry = async (entryUuid) => {
        try {
            await del(`measurements/sleep/${entryUuid}`)
            toast.success(intl.formatMessage({ id: "app.entryDeleted", defaultMessage: 'Entry Deleted' }));
            loadData();
        }
        catch (error) {
            console.log(error);
            toast.error(intl.formatMessage({ id: "app.deleteFail", defaultMessage: 'Deletion Failed.' }));
        }
    }

    const handleDeleteClick = (uuid) => {
        openDialog({
            open: true,
            title: intl.formatMessage({ id: "app.deleteConfirmation", defaultMessage: 'Delete Confirmation' }),
            description: intl.formatMessage({ id: "app.deleteConfirmationMessage", defaultMessage: 'Are you sure you want to delete this entry?' }),
            onSubmit: () => { deleteEntry(uuid) }
        });
    }

    useEffect(() => {
        loadData()
    }, [timeframeId])


    const series = [{
        name: intl.formatMessage({ id: "app.sleep" }),
        data: records.map(e => ({
            x: new Date(e.date),
            y: e.duration
        }))
    }]

    const timeframeOptions = [
        { id: '1', name: <FormattedMessage id={`app.months`} values={{ count: 1 }} /> },
        { id: '3', name: <FormattedMessage id={`app.months`} values={{ count: 3 }} /> },
        { id: '6', name: <FormattedMessage id={`app.months`} values={{ count: 6 }} /> },
        { id: '12', name: <FormattedMessage id={`app.years`} values={{ count: 1 }} /> },
        { id: '36', name: <FormattedMessage id={`app.years`} values={{ count: 3 }} /> },
        { id: '60', name: <FormattedMessage id={`app.years`} values={{ count: 5 }} /> },
        { id: '500', name: <FormattedMessage id={`app.showAll`} /> }
    ]

    return [
        <TimeframeSelect
            key='timeframeSelect'
            title='Select Timeframe'
            options={timeframeOptions}
            selectedOptionId={timeframeId}
            onSelect={setTimeframeId}
            open={showTimeframeSelect}
            onClose={setShowTimeframeSelect}
        />,
        <EntryOptions
            key='entryOptions'
            title='Measurement Options'
            onEditSelect={() => {
                setEditSettings({ uuid: showOptionsDialog })
            }}
            onDeleteSelect={() => {
                handleDeleteClick(showOptionsDialog)
            }}
            open={showOptionsDialog ? true : false}
            onClose={setShowOptionsDialog}
        />,
        <Dialog
            key='editDialog'
            open={editSettings ? true : false}
            onClose={() => { loadData(); setEditSettings() }}
            {...editSettings}
        />,
        <Paper
            key='content'
            elevation={3}
            style={{ marginTop: '40px' }}
            onClick={() => { setEditSettings({}) }}
        >
            <TitleBar>
                <TitleBarItem style={{ fontSize: '24px' }}>
                    <FormattedMessage id="app.currentMeasurement" defaultMessage="Current Measurement" />
                </TitleBarItem>
                <TitleBarItem style={{ fontSize: '18px', textAlign: 'right' }}>
                    <FontAwesomeIcon icon={['fas', 'edit']} />
                </TitleBarItem>
            </TitleBar>
            <div style={{ color: '#3c4858', padding: '50px 20px', textAlign: 'center' }}>
                {records.length > 0
                    ? <>
                        <b style={{ fontSize: '72px' }}>{records[0].duration}</b>
                        <b style={{ fontSize: '24px' }}> {intl.formatMessage({ id: "app.variable.hours" }, { count: records[0]?.duration })}</b>
                        <div style={{ marginTop: '20px', fontSize: '18px' }}>
                            <DateDisplay date={records[0].date} />
                        </div>
                    </>
                    : <>
                        <FormattedMessage id="app.noMeasurementsYet" defaultMessage="You haven't recorded any measurements yet!" />
                        <div style={{ marginTop: '50px' }}>
                            <ColoredButton color='purple' type="submit" startIcon={<FontAwesomeIcon icon={['fas', 'edit']} />} >
                            <FormattedMessage id="app.addMeasurement" defaultMessage="Add Measurement" />
                            </ColoredButton>
                        </div>
                    </>
                }
            </div>
        </Paper>,
        <TitleBar key='chartTitle' style={{ marginTop: '40px' }} onClick={() => { setShowTimeframeSelect(true) }}>
            <TitleBarItem>
                <div style={{ fontSize: '24px' }}>
                    <FormattedMessage id="app.pastProgress" defaultMessage="Past Progress" />
                </div>
                {timeframeOptions.find(e => e.id === timeframeId)?.name}
            </TitleBarItem>
            <TitleBarItem style={{ textAlign: 'right', fontSize: '18px' }}>
                <FontAwesomeIcon icon={['fas', 'edit']} />
            </TitleBarItem>
        </TitleBar>,
        <Paper key='chart' elevation={3} style={{ padding: '10px' }}>
            <ProgressChart series={series} />
        </Paper>,
        records.length > 0 && <Paper key='results' elevation={3} style={{ marginTop: '60px' }}>
            <TitleBar>
                <TitleBarItem style={{ fontSize: '24px' }}>
                    <FormattedMessage id="app.pastProgress" defaultMessage="Past Progress" />
                </TitleBarItem>
                <TitleBarItem style={{ textAlign: 'right' }}>
                    <span style={{ fontSize: '18px' }} onClick={() => { setShowOptionsDialog({}) }}>
                        <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </span>
                </TitleBarItem>
            </TitleBar>
            {records.slice((page - 1) * 10, page * 10).map((e, index) => {
                return <div key={index} style={{ display: 'flex', padding: '20px', borderBottom: '1px solid #eeeeee' }} onClick={() => { setShowOptionsDialog(e.uuid) }}>
                    <div style={{ flex: 1 }}>
                        <DateDisplay date={e.date} />
                    </div>
                    <div>{e.duration} {intl.formatMessage({ id: "app.variable.hours" }, { count: e.duration })}</div>
                </div>
            })}
        </Paper>,
        <Pagination
            key='paging'
            page={page}
            count={records.length}
            shape="rounded"
            onChange={setPage}
        />

    ]
}