import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';

import TextField from 'sfl-components/TextField/Outlined';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import OtherInput from 'sfl-components/Inputs/OtherInput';
import FoodInput from 'sfl-components/FoodInput.js';
import CardButton from 'sfl-components/Card/CardButton';
import Dialog from 'sfl-components/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSFLApi } from 'api';


export default function FormOther(props) {
    const { uuid, onClose, date, section, memberUuid } = props;
    const intl = useIntl();


    const [state, setState] = useState({});
    const { get, post } = useSFLApi();
    const { name = '', scale, portion = '', time = '' } = state;

    useEffect(() => {
        if (!uuid) {
            return;
        }
        get(`foodLogs/${uuid}`)
            .then(results => {
                setState(results.foodLog);
            })
            .catch(error => {
                console.log('error', error);
            })
    }, [get, uuid]);


    const handleChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }


    const handleSave = async () => {
        const path = uuid ? `foodLogs/${uuid}` : 'foodLogs';
        const data = {
            ...state,
            member_uuid: memberUuid,
            section: section,
            date: date
        };
        try {
            await post(path, data);

            toast.success(intl.formatMessage({ id: "app.entrySaved", defaultMessage: "Entry Saved" }));
            props.refresh();
            onClose();
        }
        catch (error) {
            console.log('error', error);
            toast.error(intl.formatMessage({ id: "app.saveFailed", defaultMessage: "Save Failed" }))
        }
    }


    const buttons = [
        <CardButton key='cancel' icon='backspace' onClick={onClose}><FormattedMessage id="app.cancel" defaultMessage="Cancel" /></CardButton>,
        <CardButton key='save' icon='backup' onClick={handleSave}><FormattedMessage id="app.save" defaultMessage="Save" /></CardButton>
    ]

    const scaleOptions = [
        undefined,
        '',
        intl.formatMessage({ id: "app.scales.serving", defaultMessage: "Serving" }),
        intl.formatMessage({ id: "app.scales.cup", defaultMessage: "Cup" }),
        intl.formatMessage({ id: "app.scales.tbsp", defaultMessage: "Tbsp" }),
        intl.formatMessage({ id: "app.scales.tsp", defaultMessage: "Tsp" }),
        intl.formatMessage({ id: "app.scales.ml", defaultMessage: "mL" }),
        intl.formatMessage({ id: "app.scales.gram", defaultMessage: "Gram" }),
        intl.formatMessage({ id: "app.scales.ounce", defaultMessage: "Ounce" })
    ]

    console.log(scaleOptions)

    return <Dialog buttons={buttons} onClose={onClose}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="foodLog.whatDidYouEat" defaultMessage="What did you eat?" />
                </div>
                <FoodInput
                    id="name"
                    label={intl.formatMessage({ id: "foodLog.foodSearch", defaultMessage: "Food Search" })}
                    value={name}
                    onChange={newValue => handleChange('name', newValue)}
                />
            </Grid>
            <Grid item xs={4}>
                <div style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="foodLog.portion" defaultMessage="Portion" />
                </div>
                <TextField
                    id="portion"
                    label={intl.formatMessage({ id: "foodLog.portion.helperText", defaultMessage: "Ex: 2" })}
                    value={portion}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={8}>
                <div style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="foodLog.scale" defaultMessage="Scale" />
                </div>
                <OtherInput
                    id='scale'
                    label={intl.formatMessage({ id: "foodLog.scale.helperText", defaultMessage: "Ex: Grams" })}
                    value={scale}
                    textMode={![undefined, '', ...scaleOptions].includes(scale)}
                    onChange={newValue => handleChange('scale', newValue)}
                >
                    <MenuItem key='empty' value=''></MenuItem>
                    {scaleOptions.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                </OtherInput>
            </Grid>
            <Grid item xs={6}>
                <div style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="foodLog.when" defaultMessage="When?" />
                </div>
                <TextField
                    id="time"
                    label={intl.formatMessage({ id: "foodLog.time", defaultMessage: "Time" })}
                    type="time"
                    value={time || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 900, // 5 min
                    }}
                />
            </Grid>
        </Grid>
    </Dialog>
}