import React, { useState } from "react";
import { toast } from 'react-toastify';

import { useSFLApi } from 'api';

import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CardTitle } from 'sfl-components/Containers2';
import TextField from 'sfl-components/TextField';
import CardButton from 'sfl-components/SFLCards/CardButton';
import Card from 'sfl-components/SFLCards/Card';


import Title from 'sfl-components/Title';
import bowlImg from 'assets/images/bowls/Bal_Leaves_circle_dishes_400px.png';
import { FormattedMessage, useIntl } from 'react-intl';

export default function MemberFeedback() {
    const [state, setState] = useState({});
    const [formComplete, setFormComplete] = useState(false);
    const { post } = useSFLApi();
    const intl = useIntl();

    const { general_rating = '', general_feedback = '' } = state;

    const handleChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }

    const handleSave = () => {
        post('feedbacks', state)
            .then(() => {
                setFormComplete(true)
                toast.success(intl.formatMessage({ id: "feedback.success", defaultMessage: 'Your feedback has been submitted!' }));
            })
            .catch(error => {
                console.log(error)
                toast.error(intl.formatMessage({ id: "feedback.fail", defaultMessage: 'An error has occured.' }))
            });
    }


    if (formComplete) {
        return [
            <div key='title' style={{ display: 'flex', marginBottom: '40px' }}>
                <Title>
                    <FormattedMessage id="feedback.title" defaultMessage="Member Feedback" />
                </Title>
            </div>,
            <div key='content' style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '32px', marginTop: '180px', marginBottom: '40px' }}>
                    <i><FormattedMessage id="feedback.yourFeedbackHasBeenSubmitted" defaultMessage="Your feedback has been submitted!" /></i>
                </div>
                <img src={bowlImg} alt='' style={{ maxWidth: '300px' }} />
            </div>
        ]
    }


    return [
        <div key='title' style={{ display: 'flex', marginBottom: '40px' }}>
            <Title>
                <FormattedMessage id="feedback.title" defaultMessage="Member Feedback" />
            </Title>
            <CardButton onClick={handleSave}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
                <FormattedMessage id="feedback.submitFeedback" defaultMessage="Submit Feedback" />
            </CardButton>
        </div>,
        <Card key='content'>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ paddingLeft: '32px', paddingRight: '32px' }}>
                    <CardTitle>
                        <FormattedMessage id="feedback.howAreWeDoing" defaultMessage="How are we doing?" />
                    </CardTitle>
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: '32px', paddingRight: '50%' }}>
                    <FormattedMessage
                        id="feedback.intro2"
                        defaultMessage="Simply For Life wouldn't be where it is today without our members. 
                                    We don’t believe in perfection, but feedback is key to improve or services and your experience 
                                    If yould like to provide some feedback (good or bad), we'd love to hear from you."
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: '32px', paddingRight: '32px' }}>
                    <TextField
                        id="general_rating"
                        name="general_rating"
                        label={intl.formatMessage({ id: "feedback.howWouldYouRateUs", defaultMessage: "How would you rate your experience at Simply For Life?" })}
                        select
                        value={general_rating}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'>1 (<FormattedMessage id="feedback.theWorst" defaultMessage="The Worst" />)</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='2' value='2'>2</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='3' value='3'>3</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='4' value='4'>4</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='5' value='5'>5 (<FormattedMessage id="feedback.average" defaultMessage="Average" />)</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'>6</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='7' value='7'>7</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='8' value='8'>8</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='9' value='9'>9</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='10' value='10'>10 (<FormattedMessage id="feedback.theBest" defaultMessage="The Best" />)</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: '32px', paddingRight: '32px' }}>
                    <TextField
                        id="general_feedback"
                        name="general_feedback"
                        label={intl.formatMessage({ id: "feedback.howCanWeDoBetter", defaultMessage: "What could we do to make your experience better?" })}
                        multiline
                        rows={5}
                        rowsMax={20}
                        value={general_feedback}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid >
        </Card>
    ]
}