import { getIntegerValue } from 'misc'

const FIRST_PAGE = 'FIRST_PAGE';
const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
const NEXT_PAGE = 'NEXT_PAGE';
const LAST_PAGE = 'LAST_PAGE';
const SET_RESULTS_COUNT = 'SET_RESULTS_COUNT';
const SET_RESULTS_PER_PAGE = 'SET_RESULTS_PER_PAGE';
const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const setPage = (page) => {
	const options = {
		first: FIRST_PAGE,
		previous: PREVIOUS_PAGE,
		next: NEXT_PAGE,
		last: LAST_PAGE
	}
	return { type: options[page] }
}
export const setDataLength = (resultsCount) => {
	return { type: SET_RESULTS_COUNT, resultsCount: getIntegerValue(resultsCount, 0) }
}
export const setSearchParameters = (searchParameters) => {
	return { type: SET_SEARCH_PARAMETERS, searchParameters: searchParameters }
}
export const setResultsPerPage = (resultsPerPage) => {
	return { type: SET_RESULTS_PER_PAGE, resultsPerPage: getIntegerValue(resultsPerPage, 10) }
}

const initialState = {
	currentPage: 1,
	offset: 0,
	resultsPerPage: 10,
	totalResults: 0,
	totalPages: 1,
	search: {
		name: '',
		status: 'active'
	}
}

export const locations = (state = initialState, action) => {
	let newPage;
	switch (action.type) {
		case SET_SEARCH_PARAMETERS:
			return {
				...state,
				search: {
					name: action.searchParameters.name || '',
					status: action.searchParameters.status || 'active'
				}
			};
		case SET_RESULTS_PER_PAGE:
			return {
				...state,
				resultsPerPage: action.resultsPerPage,
				totalPages: Math.ceil(state.totalResults / action.resultsPerPage),
				currentPage: 1,
				offset: 0
			};
		case SET_RESULTS_COUNT:
			return {
				...state,
				totalResults: action.resultsCount,
				totalPages: Math.ceil(action.resultsCount / state.resultsPerPage)
			};
		case FIRST_PAGE:
			return {
				...state,
				currentPage: 1,
				offset: 0
			};
		case PREVIOUS_PAGE:
			newPage = state.currentPage > 1 ? state.currentPage - 1 : 1;
			return {
				...state, currentPage: newPage,
				offset: (newPage - 1) * state.resultsPerPage
			};
		case NEXT_PAGE:
			newPage = state.currentPage < state.totalPages ? state.currentPage + 1 : state.totalPages;
			return {
				...state, currentPage: newPage,
				offset: (newPage - 1) * state.resultsPerPage
			};
		case LAST_PAGE:
			newPage = state.totalPages;
			return {
				...state, currentPage: newPage,
				offset: (newPage - 1) * state.resultsPerPage
			};
		default:
			return state
	}
}