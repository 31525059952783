import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage } from 'react-intl';

const timeframeOptions = [
    { id: '1', name: <FormattedMessage id={`app.months`} values={{ count: 1 }} /> },
    { id: '3', name: <FormattedMessage id={`app.months`} values={{ count: 3 }} /> },
    { id: '6', name: <FormattedMessage id={`app.months`} values={{ count: 6 }} /> },
    { id: '12', name: <FormattedMessage id={`app.years`} values={{ count: 1 }} /> },
    { id: '36', name: <FormattedMessage id={`app.years`} values={{ count: 3 }} /> },
    { id: '60', name: <FormattedMessage id={`app.years`} values={{ count: 5 }} /> },
    { id: '500', name: <FormattedMessage id={`app.showAll`} /> }
]


export const getTimeframeName = (timeframeId) => {
    const tf = timeframeOptions.find(e => e.id === timeframeId)
    if (tf) {
        return tf.name
    }
    return ''
}

export const TimeframeSelect = (props) => {
    const { open = false, selectedOptionId, onSelect, onClose } = props;

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    return <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} >
        <DialogTitle id="simple-dialog-title">
            <FormattedMessage id={`app.selectTimeframe`} />
        </DialogTitle>
        <List>
            {timeframeOptions.map(option => <ListItem key={option.id} button onClick={() => { handleClose(); onSelect(option.id); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                    {option.id === selectedOptionId
                        ? <FontAwesomeIcon icon={['fas', 'dot-circle']} style={{ color: '6e3076' }} />
                        : <FontAwesomeIcon icon={['fas', 'circle']} />}
                </ListItemIcon>
                <ListItemText primary={option.name} style={{ padding: '0px 20px' }} />
            </ListItem>)}
        </List>
    </Dialog>
}