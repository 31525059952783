import React from 'react';


export const TitleBar = ({ children, style, ...rest }) => {
    return <div style={{
        display: 'flex',
        backgroundColor: '#6e3076',
        padding: '20px 15px',
        color: 'white',
        ...style
    }}
        {...rest}>
        {children}
    </div>
}

export const TitleBarItem = (props) => {
    const { style } = props

    return <div style={{
        flex: '1',
        ...style
    }}>
        {props.children}
    </div>
}