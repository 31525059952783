import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useIntl } from 'react-intl';



export default function EntryDialog(props) {
    const { open, entry = {}, onClose, onSelect } = props;
    const intl = useIntl();

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    return <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{entry.name}</DialogTitle>
        <List>
            <ListItem button onClick={() => { handleClose(); onSelect('editPortion') }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}><FontAwesomeIcon icon={['fas', 'edit']} /></ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'app.editPortion', defaultMessage: "Edit Portion" })} style={{ padding: '0px 20px' }} />
            </ListItem>
            {entry.type === 'recipe' && <ListItem button onClick={() => { onSelect('toggleChildren'); handleClose(); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}><FontAwesomeIcon icon={['fas', 'indent']} /></ListItemIcon>
                <ListItemText
                    primary={entry.displayChildren
                        ? intl.formatMessage({ id: 'app.hideIngredients', defaultMessage: "Hide Ingredients" })
                        : intl.formatMessage({ id: 'app.showIngredients', defaultMessage: "Show Ingredients" })
                    }
                    style={{ padding: '0px 20px' }} />
            </ListItem>
            }
        </List>
    </Dialog>
}