import React from 'react';

export const ViewportContext = React.createContext({});

export default function ViewportProvider({ children }) {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <ViewportContext.Provider value={{ width, height }}>
        {children}
    </ViewportContext.Provider>
}