import React from "react";
import SectionTitle from 'sfl-components/MealPlan/SectionTitle';
import SectionBody from 'sfl-components/MealPlan/SectionBody';
import SectionEntry from 'sfl-components/MealPlan/SectionEntry';
import SectionEntryCell from 'sfl-components/MealPlan/SectionEntryCell';
import Portions from 'sfl-components/Portions';
import { FormattedMessage } from 'react-intl';

export default function MealPlanLayout(props) {
    return generateEntry(props.mealPlanEntries, props.members, null, props.editEntry, []);
}

export function generateEntry(mealPlanEntries, members = [], parentUuid, editEntry, indentCells = []) {

    return mealPlanEntries.filter(entry => entry.parent_entry_uuid === parentUuid)
        .map(entry => {
            const { uuid, name, ingredient_description, portions = [], recipe_servings, displayChildren } = entry;

            if (['section'].includes(entry.type)) {
                const childContent = generateEntry(mealPlanEntries, members, uuid, editEntry)

                return [
                    <SectionTitle key='title' title={name} description={ingredient_description} />,
                    <SectionBody key='body'>
                        {childContent}
                    </SectionBody>
                ]
            }

            if (['group', 'group_public'].includes(entry.type)) {

                return [
                    <SectionEntry
                        key='SectionEntry'
                        title={name}
                        description={ingredient_description}
                        before={[...indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '10px', borderLeft: '5px solid #b5bb00' }} />]}
                        style={{ color: '#b5bb00', paddingLeft: '5px' }}
                    />,
                    generateEntry(mealPlanEntries, members, uuid, editEntry, [...indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '10px', borderLeft: '5px solid #b5bb00' }} />])
                ]

            }

            if (['food', 'food_child'].includes(entry.type)) {
                return [
                    <div
                        key='sectionEntry'
                        style={{ display: 'flex' }}
                        onClick={() => { editEntry(uuid) }}>
                        {indentCells}
                        <SectionEntryCell style={{ flexGrow: '1' }}>
                            <b>{name}</b>
                            {ingredient_description}
                            <div style={{ textAlign: 'right' }}>
                                <Portions members={members} portions={portions} />
                            </div>
                        </SectionEntryCell>
                    </div>,
                    displayChildren && generateEntry(mealPlanEntries, members, uuid, editEntry, [indentCells, <div key='indent' style={{ display: 'flex', width: '20px' }} />])
                ]
            }

            if (['recipe'].includes(entry.type)) {
                return [
                    <div
                        key='sectionEntry'
                        style={{ display: 'flex' }}
                        onClick={() => { editEntry(uuid) }}>
                        {indentCells}
                        <SectionEntryCell style={{ flexGrow: '1' }}>
                            <b>{name}</b>
                            {ingredient_description}
                            <div style={{ textAlign: 'right' }}>
                                <Portions members={members} portions={portions} />
                            </div>
                        </SectionEntryCell>
                    </div>,
                    displayChildren && [
                        <div key='recipeIntro' style={{ display: 'flex' }}>
                            {[...indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '5px', borderLeft: '5px solid #6e3076' }} />]}
                            <SectionEntryCell >
                                <b className='purple'><FormattedMessage id="app.recipeIngredients" defaultMessage="Recipe Ingredients" /></b>
                                <div><FormattedMessage
                                    id="app.MakesXServings"
                                    defaultMessage="Makes {recipe_servings, plural, one {# serving} other {# servings}}"
                                    values={{ recipe_servings: recipe_servings }}
                                /></div>
                            </SectionEntryCell>
                        </div>,
                        ...generateEntry(mealPlanEntries, members, uuid, editEntry, [indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '5px', borderLeft: '5px solid #6e3076' }} />])
                    ]
                ]
            }

            return null;
        });
}