import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useIntl } from 'react-intl';

import { Row } from 'sfl-components/Containers2';
import Title from 'sfl-components/Title';
import Card from 'sfl-components/SFLCards/Card';
import Ingredients from './components/Ingredients';
import ImageModal from './components/ImageModal';
import DownloadRecipeButton from './components/DownloadRecipeButton';
import FancyBox from './components/FancyBox';
import ServingsBox from './components/ServingsBox';

import { useApi } from 'api';
import imagePlaceholder from "assets/images/recipe/placeholder.jpg";

export default function RecipeDetails() {
	const { recipeUuid } = useParams();
	const [imageIndex, setImageIndex] = useState();
	const { data: { recipe = {} } } = useApi(`recipes/${recipeUuid}`)
	const intl = useIntl();

	const theme = useTheme();
	const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

	const { name, images = [], directions, servings = '*' } = recipe;
	const mainImage = images[0]?.path || imagePlaceholder;


	return <div>
		<ImageModal images={images} imageIndex={imageIndex} onClose={() => { setImageIndex() }} />
		<Row style={{ marginBottom: '20px', paddingRight: '40px' }}>
			<Title>{name}</Title>
			<DownloadRecipeButton uuid={recipeUuid} />
		</Row>
		<Card>
			<Grid container style={{ padding: '20px' }}>
				<Grid item xs={12} lg={6} style={{ paddingTop: '40px', paddingRight: isLarge ? '80px' : '0' }}>
					<img alt='' src={`${mainImage}?width=700`} style={{ width: '100%', borderRadius: '8px' }} onClick={() => setImageIndex(0)} />
				</Grid>
				<Grid item xs={12} lg={6}>
					<FancyBox title={intl.formatMessage({ id: 'app.ingredients', defaultMessage: 'Ingredients' })}>
						<div style={{ display: 'flex', padding: '40px 0' }}>
							<div style={{ flexGrow: 1, paddingRight: '40px' }}>
								<Ingredients recipeUuid={recipeUuid} />
							</div>
							<div>
								<ServingsBox servings={servings} />
							</div>
						</div>
					</FancyBox>
					<FancyBox title={intl.formatMessage({ id: 'app.directions', defaultMessage: 'Directions' })}>
						<ReactMarkdown>{directions}</ReactMarkdown>
					</FancyBox>
				</Grid>
			</Grid>
		</Card>
	</div>
}