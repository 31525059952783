import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
    >
        <path fill={color} d="M256 94.457c-49.238 0-89.3 40.059-89.3 89.3 0 49.24 40.062 89.298 89.3 89.298s89.3-40.059 89.3-89.297c0-49.242-40.062-89.301-89.3-89.301zm0 0" />
        <path fill={color} d="M256 0C114.84 0 0 114.84 0 256c0 65.313 24.598 124.977 64.996 170.254 8.652-37.992 28.508-72.656 57.5-99.66 20.348-18.953 44.156-33.137 69.89-41.957-33.437-21.16-55.687-58.469-55.687-100.88 0-65.784 53.52-119.3 119.301-119.3s119.297 53.516 119.297 119.3c0 42.391-22.23 79.688-55.645 100.852 27.875 9.547 53.426 25.399 74.875 46.848 26.207 26.207 44.301 59.148 52.457 94.813C487.398 380.992 512 321.32 512 256 512 114.84 397.16 0 256 0zm0 0" />
        <path fill={color} d="M91.102 451.648C135.68 489.281 193.23 512 256 512s120.32-22.723 164.898-60.352c-4.105-37.296-20.867-72.261-47.582-98.976-31.343-31.344-73.007-48.606-117.316-48.606-84.39 0-155.738 64.38-164.898 147.582zm0 0" />
    </svg >
}

export default SvgComponent