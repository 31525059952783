import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

export default function Cycle(props) {
    const [open, setOpen] = useState(false)
    const { options, title, value, onChange } = props;

    const handleNext = () => {
        const newValue = value + 1
        onChange(options[newValue] ? newValue : 0)

    }

    const handlePrevious = () => {
        const newValue = value - 1
        onChange(options[newValue] ? newValue : options.length - 1)
    }

    return [
        <div key='cycle' style={{ display: 'flex', color: '#444444', alignItems: 'center', fontSize: '16px' }}>
            <div style={{ display: 'flex', padding: '10px' }} onClick={handlePrevious}>
                <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
            </div>
            <div style={{ display: 'flex', padding: '10px', flexGrow: '1', justifyContent: 'center' }} onClick={() => { setOpen(true) }}>{options[value]}</div>
            <div style={{ display: 'flex', padding: '10px' }} onClick={handleNext}>
                <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            </div>
        </div>,
        <Dialog key='dialog' onClose={() => { setOpen(false) }} aria-labelledby="simple-dialog-title" open={open} >
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <List>
                {options.map((option, index) => <ListItem key={index} button onClick={() => { setOpen(false); onChange(index); }}>
                    <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                        {index === value
                            ? <FontAwesomeIcon icon={['fas', 'dot-circle']} style={{ color: '6e3076' }} />
                            : <FontAwesomeIcon icon={['fas', 'circle']} />}
                    </ListItemIcon>
                    <ListItemText primary={option} style={{ padding: '0px 20px' }} />
                </ListItem>)}
            </List>
        </Dialog>
    ]
}