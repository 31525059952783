import React from "react";
import CardContainer from 'sfl-components/SFLCards/CardContainer';
import { Row, Cell, CellHeader, CellFooter } from 'sfl-components/Containers2';
import { FormattedMessage } from 'react-intl';

import generateEntry from './Entry';

export default function Section(props) {
    const { uuid, name, ingredient_description } = props.data;


    const childContent = generateEntry(props.mealPlanEntries, props.members, uuid, props.actions)

    return [
        <Row key='sectionTitle' style={{ marginTop: '20px', padding: '20px 20px 0 20px' }}>
            <Cell style={{ padding: '10px' }}>
                <b className='title purple'>{name}</b>
                {ingredient_description !== '' ? <div>{ingredient_description}</div> : null}
            </Cell>
        </Row>,
        <CardContainer key='sectionBody'>
            <Row style={{ justifyContent: 'flex-end' }}>
                <CellHeader style={{ flexGrow: '0', width: '210px', marginRight: '170px' }}><FormattedMessage id="app.portion" defaultMessage="Portion" /></CellHeader>
            </Row>
            <div className='rowAlternating' style={{ borderRadius: '15px', overflow: 'hidden' }}>
                {childContent}
            </div>
            <Row style={{ justifyContent: 'flex-end' }}>
                <CellFooter style={{ flexGrow: '0', width: '210px', marginRight: '170px' }} />
            </Row>
        </CardContainer>
    ]
}
