import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Error from 'sfl-components/Error';
import CardButton from 'sfl-components/SFLCards/CardButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@material-ui/core/Paper';


import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";

import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";


import BottomNavbar from "../Shared/BottomNavBar";


import { useSFLApi } from 'api';


export default function Grocerylist() {
    const [mealPlan, setMealPlan] = useState({});
    const [mealPlanEntries, setMealPlanEntries] = useState([]);
    const [groceryCategories, setGroceryCategories] = useState([]);
    const [error, setError] = useState();
    const { mealPlanUuid } = useParams();
    const { get } = useSFLApi();


    const fetchData = useCallback(async () => {
        setError();

        try {
            let mealPlan;
            if (mealPlanUuid) {
                const results = await get(`mealPlans/${mealPlanUuid}`);
                mealPlan = results.mealPlan;
            } else {
                const results = await get(`mealPlans?status=active&sort=start_date:desc&limit=1`);
                mealPlan = results.mealPlans.pop();
            }
            setMealPlan(mealPlan);


            await get('groceryCategories?status=active&sort=name')
                .then(results => {
                    results.groceryCategories.push({
                        uuid: null,
                        name: 'Misc.'
                    })
                    setGroceryCategories(results.groceryCategories)
                })

            get(`mealPlanEntries?status=active&meal_plan_uuid=${mealPlan.uuid}&sort=name`)
                .then(results => {
                    if (results.mealPlanEntries.length === 0) {
                        setError({
                            type: 'warning',
                            title: 'Warning',
                            description: 'The selected meal plan appears to be empty. Please contact your consultant for assistance.'
                        });
                    }
                    setMealPlanEntries(results.mealPlanEntries)
                })
        }
        catch (error) {
            setError({
                type: 'error',
                title: 'Error',
                description: 'A error has occured while retreiving the selected meal plan. Please contact your consultant for assistance.'
            });
            console.log('error', error)
        }

    }, [get, mealPlanUuid]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if (error) {
        return [
            <MobileNavbar key='MobileNavbar'>
                <SidebarToggle />
                <NavBarTitle>Meal Plan</NavBarTitle>
            </MobileNavbar>,
            <BottomNavbar key='BottomNavbar' activeTab={3} />,
            <Error key='error' title="Error!" description="A error has occured while retreiving your meal plans. Please contact your consultant for assistance.">
                <CardButton key="refresh" onClick={fetchData}>
                    <FontAwesomeIcon icon={['fas', 'sync']} />
                    <div>Try Again</div>
                </CardButton>
            </Error>
        ]
    }


    /*
    Used to enable all ingredients under a recipe if the recipe is active.
    Allows for users to easily add recipes to their shopping list without having to enable each ingredient.
    */
    const enableIngredients = parentUuid => {
        const childEntries = mealPlanEntries.filter(e => e.parent_entry_uuid === parentUuid);

        for (let i = 0; i < childEntries.length; i++) {
            const index = mealPlanEntries.findIndex(e => e.uuid === childEntries[i].uuid)
            mealPlanEntries[index].shopping_list = true;
            enableIngredients(childEntries[i].uuid)
        }
    }


    const activeRecipeUuids = mealPlanEntries.filter(e => e.type === 'recipe' && e.shopping_list === true).map(e => e.uuid);

    for (let i = 0; i < activeRecipeUuids.length; i++) {
        enableIngredients(activeRecipeUuids[i])
    }


    return [
        <MobileNavbar key='MobileNavbar'>
            <SidebarToggle />
            <NavBarTitle>Meal Plan</NavBarTitle>
        </MobileNavbar>,
        <BottomNavbar key='BottomNavbar' activeTab={3} />,
        <Paper key='title' elevation={3} style={{ padding: '20px' }}>
            <span style={{ fontSize: '32px' }}>{mealPlan.name}</span>
            {mealPlan.description !== ''
                ? <div style={{ whiteSpace: 'pre-line' }}>{mealPlan.description}</div>
                : null
            }
        </Paper>,
        <div key='list' style={{ paddingBottom: '100px' }}>
            {groceryCategories.map(groceryCategory => {
                //Get all entries on the shopping list that are food for the current category
                let foodEntries = mealPlanEntries.filter(e => e.shopping_list === true && ['food', 'food_child', 'food_parent'].includes(e.type) && e.name !== '' && e.grocery_category_uuid === groceryCategory.uuid);

                if (foodEntries.length === 0) {
                    return null
                }

                return [
                    <div key='name' style={{ display: 'flex', backgroundColor: '#6e3076', padding: '10px', borderLeft: '5px solid #6e3076', color: 'white', marginTop: '20px', borderRadius: '15px 15px 0 0' }}>
                        <div style={{ display: 'flex', flexGrow: '1', flexDirection: 'column', justifyContent: 'center' }} >
                            <div style={{ fontSize: '24px' }}>{groceryCategory.name}</div>
                        </div>
                    </div>,
                    foodEntries.map(entry => <div key={entry.uuid} style={{ display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'white', borderBottom: '1px solid #eeeeee' }}>
                        <div style={{ fontWeight: '400', fontSize: '18px', color: 'black' }}>{entry.name}</div>
                        <div style={{ color: '#888888' }}>{entry.grocery_description}</div>
                    </div>
                    )
                ]
            })}
        </div>
    ]
}
