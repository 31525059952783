
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { clearSessionToken } from 'store/session'

export default function SignOut() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearSessionToken());
    });

    return <div />
}