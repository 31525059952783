import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';


import { useSFLApi } from 'api';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";
import DateCycle from "sfl-components/DateCycle";
import FoodLogContent from './FoodLogContent.mobile';

import AddEntryDialog from './AddEntryDialog';
import EditEntryDialog from './EditEntryDialog';

import FoodLogMealPlanForm from "views/FoodLog-MealPlanForm";
import FoodLogPastLogForm from "views/FoodLog-PastLogForm";
import FoodLogOtherForm from "views/FoodLog-OtherForm";


import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl';



const today = moment().format('YYYY-MM-DD');


export default function FoodLogMobile() {
    const { userUuid } = useSelector(state => state.session);
    const { memberUuid = userUuid, date: dateParam } = useParams();

    const [foodLogEntries, setFoodLogEntries] = useState([]);
    const [dateString, setDateString] = useState(dateParam ? dateParam : today);
    const [selectedMode, setSelectedMode] = useState();
    const [selectedSection, setSelectedSection] = useState();

    const [editEntry, setEditEntry] = useState();
    const { get, del } = useSFLApi();
    const intl = useIntl();



    const [showAddEntrySelect, setShowAddEntrySelect] = useState(false);
    const [showEditEntrySelect, setShowEditEntrySelect] = useState(false);


    const sections = [
        { key: 'breakfast', name: <FormattedMessage id="app.breakfast" defaultMessage="Breakfast" /> },
        { key: 'morning_snack', name: <FormattedMessage id="app.morningSnack" defaultMessage="Morning Snack" /> },
        { key: 'lunch', name: <FormattedMessage id="app.lunch" defaultMessage="Lunch" /> },
        { key: 'afternoon_snack', name: <FormattedMessage id="app.afternoonSnack" defaultMessage="Afternoon Snack" /> },
        { key: 'supper', name: <FormattedMessage id="app.supper" defaultMessage="Supper" /> },
        { key: 'evening_snack', name: <FormattedMessage id="app.eveningSnack" defaultMessage="Evening Snack" /> }
    ]


    const fetchData = useCallback((dateString) => {
        return get(`foodLogs?status=active&parent_status=active&member_uuid=${memberUuid}&date=${dateString}`)
            .then(data => {
                setFoodLogEntries(data.foodLogs);
            })
            .catch(error => {
                console.log('error', error);
                setFoodLogEntries([]);
            })
    }, [get, memberUuid]);

    useEffect(() => {
        fetchData(dateString);
    }, [fetchData, dateString]);


    const clearEditItem = () => {
        setEditEntry(null);
    }

    const referesh = () => {
        clearEditItem();
        fetchData(dateString);
    }

    const deleteFoodLogEntry = (entryUuid) => {
        del(`foodLogs/${entryUuid}`)
            .then(() => {
                referesh();
            })
            .catch(error => {
                console.log(error);
                toast.error(intl.formatMessage({ id: "foodLog.deleteFail", defaultMessage: 'Deletion Failed.' }));
            });
    }




    /*
    * Toggle the display children state of the UUID which will make recipe ingredient visible.
    */
    const toggleChildren = () => {
        setFoodLogEntries(currentState => {
            const entryIndex = foodLogEntries.findIndex(e => e.uuid === editEntry);
            currentState[entryIndex].displayChildren = !currentState[entryIndex].displayChildren;
            return currentState;
        })
    }


    const handleEditOptionSelect = (option) => {
        if (option === 'delete') {
            deleteFoodLogEntry(editEntry)
        }
        if (option === 'ingredientsToggle') {
            toggleChildren();
        }
        if (option === 'edit') {
            setSelectedMode('edit');
        }
    }

    if (selectedMode === 'mealPlan') {
        return <FoodLogMealPlanForm memberUuid={memberUuid} section={selectedSection} date={dateString} refresh={referesh} onClose={setSelectedMode} />
    }

    if (selectedMode === 'pastLog') {
        return <FoodLogPastLogForm memberUuid={memberUuid} section={selectedSection} date={dateString} refresh={referesh} onClose={setSelectedMode} />
    }

    if (selectedMode === 'other') {
        return <FoodLogOtherForm memberUuid={memberUuid} section={selectedSection} date={dateString} refresh={referesh} onClose={setSelectedMode} />
    }

    if (selectedMode === 'edit') {
        return <FoodLogOtherForm uuid={editEntry} section={selectedSection} date={dateString} refresh={referesh} onClose={setSelectedMode} />
    }

    const handleEntryClick = (uuid) => {
        setEditEntry(uuid);
        setShowEditEntrySelect(true);
    }

    return [
        <MobileNavbar key='navBar'>
            <SidebarToggle />
            <NavBarTitle>
                <FormattedMessage id="app.foodLog" defaultMessage="Food Log" />
            </NavBarTitle>
        </MobileNavbar>,
        <DateCycle key='dateCycle' date={dateString} onChange={setDateString} />,
        <AddEntryDialog key='addDialog' open={showAddEntrySelect} onSelect={setSelectedMode} onClose={() => setShowAddEntrySelect(false)} />,
        <EditEntryDialog key='editDialog' open={showEditEntrySelect} onSelect={handleEditOptionSelect} onClose={() => setShowEditEntrySelect(false)} />,
        <div key='bg' style={{ paddingTop: '40px', backgroundSize: '100% auto', backgroundPosition: 'fixed' }}>
            {sections.map(section => {
                const entries = foodLogEntries.filter(log => log.section === section.key);

                return <FoodLogContent key={section.key} title={section.name} entries={entries} editEntry={handleEntryClick} onTitleClick={() => setShowAddEntrySelect(true)} >
                    <Paper key='add' elevation={3} style={{ color: '#555555' }}>
                        <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '#f7f7f7' }} onClick={() => { setShowAddEntrySelect(true); setSelectedSection(section.key) }}>
                            <FontAwesomeIcon icon={faPlus} fixedWidth /> <FormattedMessage id="foodLog.addFood" defaultMessage="Add Food" />
                        </div>
                    </Paper >
                </FoodLogContent>
            })}
        </div>
    ]

}