import React from "react";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fbImg from 'assets/images/social/facebook.jpg';
import instaImg from 'assets/images/social/instagram.jpg';
import pinImg from 'assets/images/social/pinterest.jpg';
import ytImg from 'assets/images/social/youtube.jpg';
import twImg from 'assets/images/social/twitter.jpg';


export default function SocialTile({ url = '', type = '' }) {
    if (url.trim() === '') {
        return null
    }

    const tileTypes = [
        { type: 'facebook', img: fbImg, name: 'Facebook', icon: ['fab', 'facebook'] },
        { type: 'instagram', img: instaImg, name: 'Instagram', icon: ['fab', 'instagram'] },
        { type: 'youtube', img: ytImg, name: 'YouTube', icon: ['fab', 'youtube'] },
        { type: 'pinterest', img: pinImg, name: 'Pinterest', icon: ['fab', 'pinterest'] },
        { type: 'ecom', img: twImg, name: 'Online Shop', icon: ['fas', 'shopping-cart'] }
    ]

    const selected = tileTypes.find(e => e.type === type)

    if (!selected) {
        return null
    }

    return <Grid item xs={12} sm={6} lg={3} style={{ padding: '10px', boxSizing: 'border-box' }}>
        <a href={url} target='_blank' rel="noopener noreferrer">
            <div style={{ backgroundImage: `url(${selected.img})`, backgroundSize: 'cover', color: 'white', textAlign: 'center', fontSize: '1.5rem', padding: '20px' }}>
                <FontAwesomeIcon icon={selected.icon} fixedWidth /> {selected.name}
            </div>
        </a>
    </Grid>
}