import React from "react";
import NavBarButton from './NavBarButton';
import { useDispatch } from "react-redux";
import { toggleSidebar } from 'store/appSettings';
import Menu from "@material-ui/icons/Menu";

export default function SidebarToggle() {
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    dispatch(toggleSidebar());
  };

  return (<NavBarButton onClick={handleDrawerToggle}>
    <Menu style={{ fontSize: '30px' }} />
  </NavBarButton>);
}