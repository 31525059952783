import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        viewBox="0 0 64 64"
        width={size}
    >
        <path fill={color} d="M11 15c3 0 4-2 4-2l-2 6h10l-2-6s1 2 4 2c2 0 4-2 4-5s-3-7-11-7S7 7 7 10s2 5 4 5zM59.091 51H3v4a6 6 0 006 6h30a6 6 0 006-6v-2h5.696c.541.7 1.35 1.186 2.292 1.272l5.93.539A1.91 1.91 0 1059.091 51zM29.05 35.05a7 7 0 109.9 9.9l9.899-9.9a9 9 0 000-12.728 9 9 0 00-15.19 8.119zm12.728-9.899a2 2 0 112.828 2.828 2 2 0 01-2.828-2.828zM46 4V2c-5.514 0-10 4.486-10 10h2c0-4.411 3.589-8 8-8zM7 23h2c0-3.309-2.691-6-6-6v2c2.206 0 4 1.794 4 4zM26 26h2c0-2.757 2.243-5 5-5v-2c-3.86 0-7 3.14-7 7zM48 2h2v2h-2zM42 46h2v2h-2zM4.172 43.172a4 4 0 005.657 0 3.94 3.94 0 00.683-.952 3.985 3.985 0 002.852-1.169 3.99 3.99 0 00.413-.494L18.314 46l5.657-5.657-5.444-4.537a3.99 3.99 0 00.494-.413 3.986 3.986 0 001.169-2.852 3.94 3.94 0 00.952-.683 4 4 0 00-5.657-5.657 4 4 0 00-5.657 0 3.94 3.94 0 00-.683.952c-1.032-.006-2.065.382-2.852 1.169s-1.175 1.82-1.169 2.852a3.94 3.94 0 00-.952.683 4 4 0 000 5.657 4 4 0 000 5.658z" />
    </svg >
}

export default SvgComponent