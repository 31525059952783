import React from "react";
//import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import css from './styles.module.css';

//import { useSelector, useDispatch } from 'react-redux'
//import { setLanguage } from 'store/appSettings';
import { FormattedMessage } from 'react-intl';

import { version } from "../../misc";

const UnAuthLayout = props => {
    const { sideImage } = props;
    const contentWidth = sideImage ? 6 : 12;

    //const dispatch = useDispatch();
    //const { language = 'en' } = useSelector(state => state.appSettings);


    return [
        <div key='languageToggle' style={{ position: 'absolute', top: '10px', right: '10px' }}>
            {/*
                <Button onClick={() => { dispatch(setLanguage('fr')) }} className={language === 'fr' ? css.languageActive : css.languageInactive} style={{ marginRight: '20px' }}>FR</Button>
                <Button onClick={() => { dispatch(setLanguage('en')) }} className={language === 'en' ? css.languageActive : css.languageInactive}>EN</Button>
            */}
        </div>,
        <Grid key='content' container style={{ flexDirection: 'column' }} className={css.background}>
            <Grid container item xs={11} sm={9} md={11} lg={11} className={css.contentBox}>
                <Grid item xs={12} sm={12} md={contentWidth} className={css.contentPadding}>
                    {props.children}
                </Grid>
                <Hidden smDown>
                    <Grid item xs={6} className={css.sidebarImage} style={{ backgroundImage: `url(${sideImage})` }}></Grid>
                </Hidden>
            </Grid>
            <Grid item xs={12} className={css.copyright}>
                ©2020 Simply For Life.&nbsp;
                <FormattedMessage
                    id="app.allRightsReserved"
                    defaultMessage="All rights reserved."
                />&nbsp;
                <FormattedMessage
                    id="app.version"
                    defaultMessage="Version"
                />: {version}<br />
                <a href="/terms-of-use">
                    <FormattedMessage
                        id="app.termsOfUse"
                        defaultMessage="Terms Of Use"
                    />
                </a>
                    &nbsp;|&nbsp;
                <a href="/privacy-policy">
                    <FormattedMessage
                        id="app.privacyPolicy"
                        defaultMessage="Privacy Policy"
                    />
                </a>
            </Grid>
        </Grid >
    ]
}

export default UnAuthLayout;