import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

export default function BottomNavbar(props) {
    return <AppBar position="fixed" color="primary" style={{ height: '40px', top: 'auto', bottom: '0px', }}>
        <Toolbar style={{ alignItems: 'stretch', height: '40px', minHeight: '40px', marginRight: '0px', paddingLeft: '0', paddingRight: '0',...props.style }}>
            {props.children}
        </Toolbar>
    </AppBar>
}