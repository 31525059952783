import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LoginIcon from '@material-ui/icons/ExitToApp';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import ColoredButton from 'sfl-components/Buttons/Button';

import logo from "assets/images/logo-with-tagline.png";

import { useSFLApi } from 'api';
import { toast } from 'react-toastify';

import { FormattedMessage, useIntl } from 'react-intl';


export default function PasswordResetForm() {
    const [state, setState] = useState({});
    const { passwordResetToken } = useParams();
    const history = useHistory();
    const { get, post } = useSFLApi();
    const { tokenValidation = 'pending', username = '', password = '', showPassword = false } = state;
    const intl = useIntl();

    useEffect(() => {
        get(`tokens/${passwordResetToken}`)
            .then(data => {
                if (data.token.status === 'active' && data.token.type === 'passwordReset') {
                    setState({ username: data.token.user_username, tokenValidation: 'active' })
                } else {
                    setState({ tokenValidation: 'inactive' })
                }
            })
            .catch(error => {
                console.log(error);
                setState({ tokenValidation: 'error' })
            });
    }, [get, passwordResetToken]);

    const handleChange = (name, value) => {
        setState(currentState => ({ ...currentState, [name]: value }))
    }

    const preventDefault = (event) => {
        event.preventDefault();
    }

    const submitReset = () => {
        post(`actions/passwordReset/${passwordResetToken}`, { username: username, password: password })
            .then(() => {
                setState({ password: '', tokenValidation: 'complete' });
                toast.success(intl.formatMessage({ id: "resetPassword.resetComplete", defaultMessage: "Reset Complete" }), { toastId: 'ResetPasswordSuccess' })
            })
            .catch(() => toast.error(intl.formatMessage({ id: "resetPassword.resetFail", defaultMessage: "Reset Failed" }), {
                toastId: 'ResetPasswordError'
            }));
    }

    let message = 'Validating'
    if (tokenValidation === 'error') {
        message = <div style={{ padding: '20px 30px', margin: '40px 0' }}>
            <FormattedMessage id="resetPassword.tokenError" defaultMessage="An error has occured while validating your password reset request." />
        </div>
    }
    if (tokenValidation === 'inactive') {
        message = <div style={{ padding: '20px 30px', margin: '40px 0' }}>
            <FormattedMessage id="resetPassword.tokenInactive" defaultMessage="This password reset token is no longer valid." />
        </div>
    }
    if (tokenValidation === 'complete') {
        message = <div style={{ padding: '20px 30px', margin: '40px 0' }}>
            <FormattedMessage id="resetPassword.tokenComplete" defaultMessage="Your account has been updated. Please proceed to the login page to sign in to your account." />
        </div>
    }
    if (tokenValidation === 'active') {
        message = <>
            <div style={{ padding: '20px 30px', fontWeight: 'bold', marginTop: '40px' }}>
                <FormattedMessage id="resetPassword.intro" defaultMessage="Please enter your new username and password." />
            </div>
            <div style={{ padding: '20px 30px' }}>
                <TextField
                    id="username"
                    label={intl.formatMessage({ id: "app.username", defaultMessage: "Username" })}
                    value={username}
                    fullWidth
                    variant="outlined"
                    onChange={event => handleChange('username', event.target.value)}
                />
            </div>
            <div style={{ padding: '20px 30px' }}>
                <FormControl fullWidth variant="filled">
                    <InputLabel htmlFor="password">
                        <FormattedMessage id="app.password" defaultMessage="Password" />
                    </InputLabel>
                    <OutlinedInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={event => handleChange('password', event.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleChange('showPassword', !showPassword)}
                                    onMouseDown={preventDefault}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <div style={{ padding: '10px 30px' }}>
                <ColoredButton color='purple' type="submit" style={{ width: '100%', marginBottom: '2rem' }} startIcon={<LoginIcon />} onClick={submitReset}>
                    <FormattedMessage id="app.reset" defaultMessage="Reset" />
                </ColoredButton>
            </div>
            <hr />
        </>
    }


    return <form onSubmit={preventDefault} action="/">
        <div style={{ padding: '80px 30px 10px 30px' }}>
            <img src={logo} alt='Simply For Life' style={{ width: '100%' }} />
        </div>
        {message}
        <Grid item xs={12} style={{ padding: '1rem 0', textAlign: 'center' }}>
            <Button onClick={() => history.push('/sign-in')}>
                <FormattedMessage id="app.backToSignIn" defaultMessage="Back To Sign In" />
            </Button>
        </Grid>
    </form>
}