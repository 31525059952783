import React from "react";
import css from "./styles.module.css";

export function Row(props) {
    let { hover, style, className = '', rest } = props;
    if (hover) {
        className = `${css.Row} ${className}`
    }

    return <div className={className} style={{ display: 'flex', ...style }} {...rest}>{props.children}</div>
}

export function Title(props) {
    return <Cell style={props.style}>{props.children}</Cell>
}

export function PageTitle(props) {
    return <Cell style={{ fontSize: '48px', fontWeight: 'bold', lineHeight: 'normal', ...props.style }}>{props.children}</Cell>
}

export function CardTitle(props) {
    return <Cell style={{ color: '#603373', fontSize: '32px', fontWeight: 'bold', lineHeight: 'normal', ...props.style }}>{props.children}</Cell>
}

export function Cell(props) {
    const { style, ...rest } = props;
    const cellStyle = { flexGrow: '1', ...style }
    return <div style={cellStyle} {...rest}>{props.children}</div>
}

export function CellHeader(props) {
    let { className = '', ...rest } = props;
    className = `cellHeader ${className}`
    return <Cell className={className} {...rest}>{props.children}</Cell>
}

export function CellFooter(props) {
    let { className = '', ...rest } = props;
    className = `cellFooter ${className}`
    return <Cell className={className} {...rest}>{props.children}</Cell>
}

export function AlternatingRows(props) {
    return <div style={{ display: 'flex', width: '100%' }}>{props.children}</div>
}



export function Card(props) {
    const style = {
        backgroundColor: '#f3f0d0',
        borderRadius: '15px',
        margin: '30px 0',
        padding: '20px',
        ...props.style
    }

    return <div style={style}>
        {props.children}
    </div>
}