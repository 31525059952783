import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';


export default function DialogListSelect(props) {
    const { open = false, title = '', onEditSelect, onDeleteSelect, onClose } = props;

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }


    return <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} >
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <List>
            <ListItem button onClick={() => { handleClose(); onEditSelect(); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                    <FontAwesomeIcon icon={['fas', 'edit']} />
                </ListItemIcon>
                <ListItemText primary='Edit' style={{ padding: '0px 20px' }} />
            </ListItem>
            <ListItem button onClick={() => { handleClose(); onDeleteSelect(); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                    <FontAwesomeIcon icon={['fas', 'trash']} />
                </ListItemIcon>
                <ListItemText primary='Delete' style={{ padding: '0px 20px' }} />
            </ListItem>
        </List>
    </Dialog>
}