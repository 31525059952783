export const version = '1.5.3';
export const supportedLanguages = [
	{
		id: 'en',
		locale: 'en-ca',
		name: 'Canadian English',
		dateFormat: 'MMM Do YYYY'
	},
	{
		id: 'fr',
		locale: 'fr-ca',
		name: 'Canadian French',
		dateFormat: 'D MMM YYYY'
	}
]

export const getDateFormat = (locale) => {
	const language = supportedLanguages.find(e => e.locale === locale)
	return language.dateFormat
}

export const dynamicSort = (property) => {
	var sortOrder = 1;

	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return function (a, b) {
		if (sortOrder === -1) {
			return b[property].localeCompare(a[property]);
		} else {
			return a[property].localeCompare(b[property]);
		}
	}
}

export const dynamicNumberSort = (property) => {
	var sortOrder = 1;

	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return function (a, b) {
		if (a[property] < b[property]) {
			return -1 * sortOrder;
		}
		if (a[property] > b[property]) {
			return 1 * sortOrder;
		}
		return 0;
	}
}


export const buildQuerystring = (searchParameters) => {

	Object.keys(searchParameters).forEach(key => {
		if (searchParameters[key] === '' || searchParameters[key] === null) {
			delete searchParameters[key];
		}
	})

	return new URLSearchParams(searchParameters).toString()
}

export const getIntegerValue = (value, defaultValue) => {
	const parsed = parseInt(value);
	return isNaN(parsed) ? defaultValue : parsed;
}

export const entriesSort = (entries) => {
	entries = entries.sort(dynamicNumberSort('order'));

	let returnUpdated = false;
	let returnArray = [];
	const moveEntries = (entry, index) => {
		if (entry === null) {
			return;
		}

		if (entry.parent_entry_uuid) {
			const returnIndex = returnArray.findIndex(e => e.uuid === entry.parent_entry_uuid) + 1;
			if (returnIndex > 0) {
				returnArray.splice(returnIndex, 0, entry);
				entries[index] = null;
				returnUpdated = true;
			}
		} else {
			returnArray.push(entry);
			entries[index] = null;
			returnUpdated = true;
		}
	}
	do {
		returnUpdated = false;
		entries.forEach(moveEntries);
	}
	while (returnUpdated);

	return returnArray;
}

export const formatAMPM = (time) => {
	if (time === null) {
		return '';
	}
	const date = new Date(`2020-05-05 ${time}`)
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
}

export const getChildKeys = (list, parentId, key, parentKey) => {
	const getChildEntries = parent => {
		const childEntries = list.filter(e => e[parentKey] === parent).map(e => e[key]);
		const grandChildEntries = childEntries.map(getChildEntries).flat();

		return [...childEntries, ...grandChildEntries];
	}

	return getChildEntries(parentId);
}

export const modifiedIngredientAmount = (originalAmount, modifier) => {
	/*
	Original amount should be something like: 1, 1/4, 2-4, etc.
	Modified should be (member servings / recipe servings), so 0.5 if the recipe makes 2 and the member eats 1.
	*/

	//If the originalAmount isn't defined, return the original
	if (!originalAmount || originalAmount === '' || originalAmount === '0') {
		return originalAmount;
	}

	//If the modifier isn't defined, return the original
	if (!modifier) {
		return originalAmount;
	}

	//Change the modifier to a number in case it isn't already
	modifier = Number(modifier);

	//If the modifier is 1, return the original amount as there's nothing we can do
	if (modifier === 1) {
		return originalAmount;
	}

	//TODO: other values like 2-4 or whatever are possible and we don't do anything with it yet
	if (originalAmount.indexOf('/') >= 0) {
		let numArray = originalAmount.split('/').map(Number);
		numArray[1] = numArray[1] / modifier;

		return reduce(numArray[0], numArray[1]).join('/');
	}

	//Change the amount to a number
	originalAmount = Number(originalAmount);

	//For small number, we want to show a bit more precision
	//So 0.66 apples, will be 0.6 and not 0;
	const precision = originalAmount < 10 ? 1 : 0;
	return Number((originalAmount * modifier).toFixed(precision));
}


function reduce(numerator, denominator) {
	var gcd = function gcd(a, b) {
		return b ? gcd(b, a % b) : a;
	};
	gcd = gcd(numerator, denominator);
	return [numerator / gcd, denominator / gcd];
}

export const convertWeight = (weight, scale, desiredScale) => {
	if (scale === 'kg' && desiredScale === 'lb') {
		return Math.round(Number(weight) * 10 * 2.20462) / 10
	}
	if (scale === 'lb' && desiredScale === 'kg') {
		return Math.round(Number(weight) * 10 * 0.453592) / 10
	}

	return weight
}