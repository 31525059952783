import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Title from 'sfl-components/Title';
import Paper from "@material-ui/core/Paper";

import WeightPanel from './desktop/weight/Panel'
import BloodPressurePanel from './desktop/bloodPressure/Panel'
import SleepPanel from './desktop/sleep/Panel'
import RestingHeartRatePanel from './desktop/restingHeartRate/Panel'
import BreathsPerMinutePanel from './desktop/breathsPerMinute/Panel'



const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(3),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function CustomizedTabs() {
    const classes = useStyles();
    const [tabId, setTabId] = React.useState(0);
    const intl = useIntl();

    const handleChange = (event, newValue) => {
        setTabId(newValue);
    };



    return <div style={{ marginBottom: '50px' }}>
        <div style={{ display: 'flex', marginBottom: '40px' }}>
            <Title><FormattedMessage id="app.progressTracker" defaultMessage="Progress Tracker" /></Title>
        </div>
        <div className={classes.root}>
            <div className={classes.demo1}>
                <AntTabs value={tabId} onChange={handleChange} aria-label="ant example">
                    <AntTab label={intl.formatMessage({ id: 'app.weight', defaultMessage: 'Weight' })} />
                    <AntTab label={intl.formatMessage({ id: 'app.bloodPressure', defaultMessage: 'Blood Pressure' })} />
                    <AntTab label={intl.formatMessage({ id: 'app.sleep', defaultMessage: 'Sleep' })} />
                    <AntTab label={intl.formatMessage({ id: 'app.restingHeartRate', defaultMessage: 'Resting Heart Rate' })} />
                    <AntTab label={intl.formatMessage({ id: 'app.breathsPerMinute', defaultMessage: 'Breaths Per Minute' })} />
                </AntTabs>
            </div>
        </div>
        <Paper elevation={3} style={{ padding: '30px', backgroundColor: '#f3f0d0' }}>
            {tabId === 0 && <WeightPanel />}
            {tabId === 1 && <BloodPressurePanel />}
            {tabId === 2 && <SleepPanel />}
            {tabId === 3 && <RestingHeartRatePanel />}
            {tabId === 4 && <BreathsPerMinutePanel />}
        </Paper>
    </div>
}