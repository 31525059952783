import React, { useState, useEffect } from 'react';
import CheckboxSelect from 'sfl-components/CheckboxSelect';
import { useSFLApi } from 'api';


export default function FoodPreferenceSelect(props) {
    const [options, setOptions] = useState([]);
    const { get } = useSFLApi();

    useEffect(() => {
        get(`foodCategories?fields=uuid,name&status=active&sort=name`)
            .then(results => {
                setOptions(results.foodCategories.map(category => ({ id: category.uuid, name: category.name })))
            })
    }, [get]);

    return <CheckboxSelect
        options={options}
        {...props}
    />
}