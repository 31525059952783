import React from "react";
import { FormattedMessage } from 'react-intl';

export default function ServingSize(props) {
    const { amount = '', size = '', scale = '' } = props;
    const definedScales = ['serving', 'cup', 'tbsp', 'tsp', 'ml', 'gram', 'ounce']


    try {
        const scaleLower = scale ? scale.toLowerCase() : ''
        const amountNumerator = amount.split('/')[0]
        if (definedScales.includes(scaleLower)) {
            return <FormattedMessage
                id={`app.servingSizes.${scaleLower}`}
                defaultMessage="${amount}${size} ${scale}"
                values={{ amount: amount, amountNumerator: amountNumerator, size: size, scale: scale }}
            />
        } else {
            return `${amount}${size} ${scale}`
        }
    }
    catch(error){
        console.log('error',error)
        return ''
    }
}