import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchInput from 'sfl-components/Inputs/SearchInput'
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";
import CategoryList from './CategoryList'
import RecipeList from './RecipeList'

import { useApi } from 'api';


export default function RecipesList() {
	const { categoryUuid } = useParams();
	const [recipeLimit, setRecipeLimit] = useState(100)
	const [searchValue, setSearchValue] = useState('')
	const history = useHistory();
	const intl = useIntl();
	const { data: { recipeCategories = [] } } = useApi(`recipeCategories?status=active&sort=name`)
	const { data: { recipes = [] }, setUrl, isLoading } = useApi()

	useEffect(() => {
		handleSearchUpdate(searchValue)
	}, [recipeLimit, searchValue])

	const handleSearchUpdate = (updatedSearchValue) => {
		setSearchValue(updatedSearchValue)

		let url = `recipes?sort=name&limit=${recipeLimit + 1}`;
		if (categoryUuid) {
			url += `&recipe_categories=${categoryUuid}`;
		}
		if (searchValue) {
			url += `&search=${searchValue}`;
			setUrl(url)
		}

		if (categoryUuid || searchValue) {
			setUrl(url)
		}
	}

	const selectedCategory = recipeCategories.find(e => e.uuid === categoryUuid);

	return [
		<MobileNavbar key='MobileNavbar'>
			{categoryUuid
				? <NavBarButton onClick={() => { history.goBack() }}>
					<FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
				</NavBarButton>
				: <SidebarToggle />
			}
			<NavBarTitle>{selectedCategory ? selectedCategory.name : <FormattedMessage id="app.recipeCategories" defaultMessage="Recipe Categories" />}</NavBarTitle>
		</MobileNavbar>,
		<div key='search'>
			<SearchInput
				title={intl.formatMessage({ id: "app.searchForARecipe", defaultMessage: 'Search for a recipe' })}
				value={searchValue}
				style={{ borderRadius: '0px' }}
				onChange={handleSearchUpdate}
			/>
		</div>,
		<div key='data' style={{ minHeight: '100vh' }}>
			{categoryUuid || searchValue
				? <RecipeList
					isLoading={isLoading}
					data={recipes.slice(0, recipeLimit)}
					hasMore={recipes.length > recipeLimit}
					loadMore={() => { setRecipeLimit(recipeLimit + 100) }}
				/>
				: <CategoryList data={recipeCategories} />}
		</div>

	]
}