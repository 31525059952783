import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "sfl-components/Sidebar";

import routes from "./memberRoutes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/images/SFL-logo-black-trans.png";


import MealPlanList from "views/MemberMealPlans/List";
import GroceryList from "views/MemberMealPlans/GroceryList";
import CurrentMealPlan from "views/MemberMealPlans/Current";
import RecipeCategories from "views/RecipeCategories";
import RecipeDetails from "views/RecipeDetails";
import Profile from "views/Profile";
import FoodLogMealPlanForm from "views/FoodLog-MealPlanForm";

import MainPanel from 'sfl-components/MainPanel'



const switchRoutes = (
	<Switch>
		{routes.map((prop, key) => {
			return (
				<Route
					exact
					path={prop.path}
					component={prop.component}
					key={key}
				/>
			);
		})}
		<Route
			path='/food-log/meal-plan'
			component={FoodLogMealPlanForm}
			key='FoodLogMealPlanForm'
		/>
		<Route
			path='/meal-plan/list/:mealPlanUuid'
			component={MealPlanList}
			key='MealPlanListSelected'
		/>
		<Route
			path='/meal-plan/list'
			component={MealPlanList}
			key='MealPlanList'
		/>
		<Route
			path='/meal-plan/grocery-list/:mealPlanUuid'
			component={GroceryList}
			key='GroceryListUuid'
		/>
		<Route
			path='/meal-plan/grocery-list'
			component={GroceryList}
			key='GroceryList'
		/>
		<Route
			path='/meal-plan/:mealPlanUuid'
			component={CurrentMealPlan}
			key='CurrentMealPlan'
		/>
		<Route
			path='/recipe-categories/:categoryUuid'
			component={RecipeCategories}
			key='RecipeCategories'
		/>
		<Route
			path='/recipe/:recipeUuid'
			component={RecipeDetails}
			key='Recipe'
		/>
		<Route
			path='/profile'
			component={Profile}
			key='Profile'
		/>
		<Redirect from="/" to="/dashboard" />
	</Switch>
);

const useStyles = makeStyles(styles);

export default function Member({ ...rest }) {
	let sidebarOpen = useSelector(state => state.appSettings.sidebarOpen);
	// styles
	const classes = useStyles();

	return 	<div className={classes.wrapper}>
			<Sidebar
				routes={routes}
				logo={logo}
				open={sidebarOpen}
				{...rest}
			/>
			<div className={classes.mainPanel}>
				<MainPanel>
					{switchRoutes}
				</MainPanel>
			</div>
		</div>
}