import React from 'react'
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            marginTop: theme.spacing(2),
            justifyContent: "center",
            display: 'flex'
        },
    },
}));


export default function Paging(props) {
    const classes = useStyles();
    const { page, count, onChange } = props

    return <div className={classes.root}>
        <Pagination
            page={page}
            count={Math.ceil(count / 10)}
            shape="rounded"
            showFirstButton
            showLastButton
            onChange={(event, value) => { onChange(value) }}
        />
    </div>
}