import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardButton from 'sfl-components/SFLCards/CardButton';
import Title from 'sfl-components/Title';

import Intro from './Intro';
import Goals from './Goals';
import Preferences from './Preferences';
import Habits from './Habits';
import Medical from './Medical';
import Employement from './Employement';


import { useSFLApi } from 'api';



export default function QuestionnaireForm(props) {
	const {onSave} = props;
	const { tokenUuid } = useParams();
	const [state, setState] = useState({});
	const { post } = useSFLApi();

	const [allergies, setAllergies] = useState([]);
	const [likedFoods, setLikedFoods] = useState([]);
	const [dislikedFoods, setDislikedFoods] = useState([]);
	const [medicalConditions, setMedicalConditions] = useState([]);



	const {
		employement_status = '',
		employment_activity_level = '',
		employment_company_name = '',
		height = '',
		height_scale = 'ft',
		current_weight = '',
		current_weight_scale = 'pounds',
		goal_weight = '',
		goal_weight_scale = 'pounds',
		exercise_level = '',
		family_doctor = '',
		last_checkup = '',
		average_food = '',
		medical_conditions_extra,
		medications,
		what_other_programs = '',
		what_brings_you = '',
		household = '',
		pets = '',
		gender = '',
		primary_goal = '',
		goal_celebration = '',
		restaurant_frequency = '',
		cook_frequency = '',
		allergies_restrictions_other,
		tune = '',
		date_of_birth
	} = state;



	const handleChange = React.useCallback((name, value) => {
		setState(currentState => ({ ...currentState, [name]: value }));
	}, [setState]);


	const handleSave = React.useCallback(async () => {
		post(`questionnaires/token/${tokenUuid}`, {
			data: {
				...state,
				allergies: allergies,
				liked_foods: likedFoods,
				disliked_foods: dislikedFoods,
				medical_conditions: medicalConditions
			}
		})
			.then(() => {
				onSave();
			})
			.catch(() => toast.error('Save Failed'));
	}, [post, tokenUuid, onSave,state, allergies, likedFoods, dislikedFoods, medicalConditions]);




	return [
		<div key='title' style={{ display: 'flex', marginBottom: '20px', marginTop: '20px' }}>
			<Title>Member Questionnaire</Title>
		</div>,
		<div key='msg' style={{margin:'20px 0'}}>
			Is it going to be hard, yes, but that’s the reason why you chose to work with an expert. <br />
			We take on the challenges with you so you never have to feel alone in your journey.
		</div>,
		<Intro
			key='intro'
			handleChange={handleChange}
			what_brings_you={what_brings_you}
			what_other_programs={what_other_programs}
			household={household}
			pets={pets}
			tune={tune}
			height={height}
			height_scale={height_scale}
			gender={gender}
			date_of_birth={date_of_birth}
		/>,
		<Goals
			key='goals'
			handleChange={handleChange}
			primary_goal={primary_goal}
			current_weight={current_weight}
			current_weight_scale={current_weight_scale}
			goal_weight={goal_weight}
			goal_weight_scale={goal_weight_scale}
			goal_celebration={goal_celebration}
		/>,
		<Preferences
			key='Preferences'
			handleChange={handleChange}
			average_food={average_food}
			setLikedFoods={setLikedFoods}
			likedFoods={likedFoods}
			setDislikedFoods={setDislikedFoods}
			dislikedFoods={dislikedFoods}
			setAllergies={setAllergies}
			allergies={allergies}
			allergies_restrictions_other={allergies_restrictions_other}
		/>,
		<Habits
			key='Habits'
			handleChange={handleChange}
			exercise_level={exercise_level}
			restaurant_frequency={restaurant_frequency}
			cook_frequency={cook_frequency}
		/>,
		<Medical
			key='Medical'
			handleChange={handleChange}
			family_doctor={family_doctor}
			last_checkup={last_checkup}
			setMedicalConditions={setMedicalConditions}
			medicalConditions={medicalConditions}
			medical_conditions_extra={medical_conditions_extra}
			medications={medications}
		/>,
		<Employement
			key='Employement'
			handleChange={handleChange}
			employement_status={employement_status}
			employment_activity_level={employment_activity_level}
			employment_company_name={employment_company_name}
		/>,
		<div key='footer' style={{ textAlign: 'center' }}>
			<CardButton onClick={handleSave}>
				<FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
				Submit Questionnaire
            </CardButton>
		</div>
	]
}