import React, { useState, useEffect } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from 'sfl-components/TextField/Outlined';
import OtherInput from 'sfl-components/Inputs/OtherInput';
import Button from '@material-ui/core/Button';




import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';



export default function PortionDialog(props) {
    const { open, onSave, onClose } = props;
    const [state, setState] = useState({});
    const { portion = '', scale } = state;
    const intl = useIntl();

    useEffect(() => {
        setState({ portion: props?.entry?.portion, scale: props?.entry?.scale });
    }, [props])

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    const handleChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }

    const handleSave = () => {
        if (onSave) {
            onSave(portion, scale)
        }
    }


    return <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">
            <FormattedMessage id="foodLog.portionEdit" defaultMessage="Portion Edit" />
        </DialogTitle>
        <DialogContent>
            <div style={{ fontWeight: 'bold' }}>
                <FormattedMessage id="foodLog.amount" defaultMessage="Amount" />
            </div>
            <TextField
                id="portion"
                label={intl.formatMessage({ id: "foodLog.portion.helperText", defaultMessage: "Ex: 2" })}
                value={portion}
                onChange={handleChange}
            />
            <div style={{ fontWeight: 'bold', marginTop: '40px' }}>
                <FormattedMessage id="foodLog.scale" defaultMessage="Scale" />
            </div>
            <OtherInput
                id='scale'
                label={intl.formatMessage({ id: "foodLog.scale.helperText", defaultMessage: "Ex: Grams" })}
                value={scale}
                textMode={![undefined, '', 'Serving', 'Cup', 'Tbsp', 'Tsp', 'ml', 'Gram', 'Ounce'].includes(scale)}
                onChange={newValue => handleChange('scale', newValue)}
            >
                <MenuItem key='empty' value=''></MenuItem>
                <MenuItem key='Serving' value='Serving'><FormattedMessage id="foodLog.serving" defaultMessage="Serving" /></MenuItem>
                <MenuItem key='Cup' value='Cup'><FormattedMessage id="foodLog.cup" defaultMessage="Cup" /></MenuItem>
                <MenuItem key='Tbsp' value='Tbsp'><FormattedMessage id="foodLog.tbsp" defaultMessage="Tbsp" /></MenuItem>
                <MenuItem key='Tsp' value='Tsp'><FormattedMessage id="foodLog.tsp" defaultMessage="Tsp" /></MenuItem>
                <MenuItem key='ML' value='ML'><FormattedMessage id="foodLog.ml" defaultMessage="ML" /></MenuItem>
                <MenuItem key='Gram' value='Gram'><FormattedMessage id="foodLog.gram" defaultMessage="Gram" /></MenuItem>
                <MenuItem key='Ounce' value='Ounce'><FormattedMessage id="foodLog.ounce" defaultMessage="Ounce" /></MenuItem>
            </OtherInput>
        </DialogContent>
        <DialogActions style={{ marginTop: '40px' }}>
            <Button onClick={handleClose} color="primary">
                <FormattedMessage id="foodLog.cancel" defaultMessage="Cancel" />
            </Button>
            <Button onClick={handleSave} color="primary">
                <FormattedMessage id="foodLog.update" defaultMessage="Update" />
            </Button>
        </DialogActions>
    </Dialog>
}