import React, { useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from 'sfl-components/TextField/Outlined';
import { useSFLApi } from 'api';
import DateField from 'sfl-components/Inputs/DateField';
import { toast } from 'react-toastify';
import moment from 'moment';

import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardButton from 'sfl-components/SFLCards/CardButton';

import CardBody from "components/Card/CardBody.js";
import BottomNavbar from "sfl-components/Navbars/BottomNavbar.js";


export default function BreathsPerMinuteDialog(props) {
    const { open, onClose, uuid } = props;
    const [state, setState] = useState({ date: moment().format('YYYY-MM-DD'), breaths_per_minute: '' })
    const { get, post } = useSFLApi();
    const intl = useIntl();
    const { date, breaths_per_minute = '' } = state

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (uuid) {
                    const results = await get(`measurements/breathsPerMinute/${uuid}`)
                    setState(results.breathsPerMinute)
                }
            }
            catch (error) {
                console.log('error', error)
                toast.error(intl.formatMessage({ id: "app.error", defaultMessage: 'Error!' }));
            }
        }
        fetchData()
    }, [uuid])

    const handleChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }

    const handleNumericChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }

    const handleSave = async () => {
        try {
            await post(uuid ? `measurements/breathsPerMinute/${uuid}` : `measurements/breathsPerMinute`, state)
            toast.success('Measurement saved!')
            setState({ date: moment().format('YYYY-MM-DD'), breaths_per_minute: '' })
            onClose()
        }
        catch (error) {
            toast.error('Save Failed!')
        }
    }

    return <Dialog fullScreen onClose={onClose} fullWidth={true} maxWidth='lg' open={open}>
        <MobileNavbar>
            <NavBarButton onClick={onClose}>
                <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
            </NavBarButton>
            <NavBarTitle>
                <FormattedMessage id="app.breathsPerMinute" defaultMessage="Breaths Per Minute" />
            </NavBarTitle>
        </MobileNavbar>
        <BottomNavbar>
            <CardButton key="save" onClick={handleSave} style={{ borderRadius: '0px', padding: '0', flexGrow: '1', marginLeft: '0px', height: '40px', backgroundColor: '#b4bb00' }}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} style={{ marginRight: '10px' }} /> <FormattedMessage id="app.save" defaultMessage="Save" />
            </CardButton>
        </BottomNavbar>
        <CardBody style={{ paddingTop: '100px' }}>
            <Grid container spacing={3} style={{ color: '#868e96', padding: '20px' }}>
                <Grid item xs={12}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, }}>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '15px' }}>
                                <FormattedMessage id="app.date" defaultMessage="Date" />
                            </div>
                        </div>
                        <div style={{ width: '220px', marginLeft: '20px' }}>
                            <DateField
                                id="date"
                                label={intl.formatMessage({ id: "app.Date", defaultMessage: "Date" })}
                                variant="outlined"
                                value={date}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, }}>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '15px' }}>
                                <FormattedMessage id="app.breathsPerMinute" defaultMessage="Breaths Per Minute" />
                            </div>
                        </div>
                        <div style={{ width: '220px', marginLeft: '20px' }}>
                            <TextField
                                id="breaths_per_minute"
                                label={intl.formatMessage({ id: "app.breathsPerMinute", defaultMessage: "BPM" })}
                                helperText={intl.formatMessage({ id: "app.breathsPerMinute.helperText", defaultMessage: "Ex: 35" })}
                                value={breaths_per_minute}
                                type="number"
                                onChange={handleNumericChange}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </CardBody>
    </Dialog>
}