import React from "react";

import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';

import Question from './Question';
import { FormattedMessage, useIntl } from 'react-intl';


const QuestionnaireEmployement = props => {
    const {
        handleChange,
        employement_status = '',
        employment_activity_level = '',
        employment_company_name = '',
    } = props
    const intl = useIntl();


    return <Card key='employement' title={intl.formatMessage({ id: "questionnaire.whatDoYouDoForALiving", defaultMessage: "What do you do for a living?" })} titleMode="inner" style={{ marginBottom: '75px' }}>
        <Grid container spacing={3} style={{ padding: '0 20px 50px 20px' }}>
            <Grid item xs={12} >
                <Grid container spacing={3}>
                    <Question
                        id="employement_status"
                        label={intl.formatMessage({ id: "questionnaire.employmentSatus", defaultMessage: "Which option best describes your employement status?" })}
                        select
                        helperText=" "
                        value={employement_status}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='full_time' value='full_time'><FormattedMessage id="questionnaire.employedFullTime" defaultMessage="Employed Full time" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='part_time' value='part_time'><FormattedMessage id="questionnaire.employedPartTime" defaultMessage="Employed Part time" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='education' value='education'><FormattedMessage id="questionnaire.unemployedEduction" defaultMessage="Unemployed in order to further my eduction" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='illness' value='illness'><FormattedMessage id="questionnaire.unemployedDueToDisbility" defaultMessage="Unemployed due to disbility, stress, sick leave, etc." /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='unemployed' value='unemployed'><FormattedMessage id="questionnaire.temporarilyUnemployed" defaultMessage="Temporarily unemployed, but looking for work" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='retired' value='retired'><FormattedMessage id="questionnaire.retired" defaultMessage="Retired (congratulations!)" /></MenuItem>
                    </Question>
                    {['full_time', 'part_time'].includes(employement_status) &&
                        <>
                            <Question
                                id="employment_activity_level"
                                label={intl.formatMessage({ id: "questionnaire.employmentActivityLevel", defaultMessage: "How physically engaged are you at work?" })}
                                helperText=" "
                                select
                                value={employment_activity_level}
                                onChange={handleChange}
                            >
                                <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                                <MenuItem style={{ whiteSpace: 'normal' }} key='low' value='low'><FormattedMessage id="questionnaire.employmentActivityLevel.low" defaultMessage="Very little (mostly sitting)" /></MenuItem>
                                <MenuItem style={{ whiteSpace: 'normal' }} key='medium' value='medium'><FormattedMessage id="questionnaire.employmentActivityLevel.medium" defaultMessage="A moderate amount (mostly walking and standing)" /></MenuItem>
                                <MenuItem style={{ whiteSpace: 'normal' }} key='high' value='high'><FormattedMessage id="questionnaire.employmentActivityLevel.high" defaultMessage="Heavy Activity (manual labor)" /></MenuItem>
                            </Question>
                            <Question
                                id="employment_company_name"
                                label={intl.formatMessage({ id: "questionnaire.companyName", defaultMessage: "What's the name of your company?" })}
                                value={employment_company_name}
                                onChange={handleChange}
                            />
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    </Card>
}


export default React.memo(QuestionnaireEmployement);