import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Title from 'sfl-components/Title';
import { FormattedMessage } from 'react-intl';
import { useApi } from 'api';


export default function LatestNews() {
    const { data: { articles = [] } } = useApi('articles')

    if (articles.length === 0) {
        return null
    }

    return [
        <Title key='title' style={{ marginBottom: '40px' }}>
            <FormattedMessage
                id="app.LatestNewsFromSimplyForLife"
                defaultMessage="Latest News From Simply For Life"
            />
        </Title>,
        <div key='news' style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '50px' }}>
            {articles.map(article => {
                return <Grid key={article.uuid} item xs={12} sm={6} lg={3} style={{ padding: '10px', marginBottom: '40px' }}>
                    <Paper elevation={3} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <a href={article.url} target='_blank' rel="noopener noreferrer" style={{ color: 'rgb(60, 72, 88)' }}>
                            <img alt={article.name} src={`${article.image.path}?width=500`} style={{ display: 'block', width: '100%' }} />
                            <div style={{ padding: '10px', paddingTop: '20px', fontSize: '20px', fontWeight: 'bold' }}>{article.name}</div>
                            <div style={{ padding: '10px', paddingTop: '0px', overflow: 'hidden', whiteSpace: 'pre' }}>{article.description}</div>
                        </a>
                    </Paper>
                </Grid>
            })}
        </div>
    ]
}