
import React, { useState, useEffect, useCallback } from "react";

import Paper from '@material-ui/core/Paper';
import CardButton from 'sfl-components/SFLCards/CardButton';
import Error from 'sfl-components/Error';

import { useSFLApi } from 'api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";
import DialogListSelect from 'sfl-components/DialogListSelect';
import { FormattedMessage, useIntl } from 'react-intl';

import List from './List';


export default function FoodLogMealPlanForm(props) {
    const { memberUuid, onClose } = props;
    const [mealPlans, setMealPlans] = useState([]);
    const [selectedMealPlanUuid, setSelectedMealPlanUuid] = useState();
    const [error, setError] = useState();
    const [displayMealPlanSelect, setDisplayMealPlanSelect] = useState(false);
    const { get } = useSFLApi();
    const intl = useIntl();

    const { name: mealPlanName = '', description: mealPlanDescription = '' } = mealPlans.find(mp => mp.uuid === selectedMealPlanUuid) || {};

    /*
    * Fetch all meal plans from the API and add them to the state.
    * Default selected meal plan is the last on the list which should be the latest active one.
    */
    const fetchData = useCallback(async () => {
        try {
            const results = await get(`mealPlans?status=active&fields=uuid,name,description&sort=start_date:desc`);
            setMealPlans(results.mealPlans);
            setSelectedMealPlanUuid(results.mealPlans[0].uuid);
            setError();
        }
        catch (error) {
            setError({
                type: 'error',
                title: 'Error',
                description: intl.formatMessage({ id: "mealPlan.mealPlanFail", defaultMessage: 'A error has occured while retreiving the selected meal plan. Please contact your consultant for assistance.' })
            });
            console.log('error', error)
        }

    }, [get, intl]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if (error) {
        return [
            <MobileNavbar key='MobileNavbar'>
                <NavBarButton onClick={props.close}>
                    <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
                </NavBarButton>
                <NavBarTitle>
                    <FormattedMessage id="app.mealPlan" defaultMessage="Meal Plan" />
                </NavBarTitle>
            </MobileNavbar>,
            <Error key='Error' title={error.title} description={error.description}>
                <CardButton key="refresh" onClick={fetchData}>
                    <FontAwesomeIcon icon={['fas', 'sync']} />
                    <div>
                        <FormattedMessage id="app.tryAgain" defaultMessage="Try Again" />
                    </div>
                </CardButton>
            </Error>
        ]
    }

    return <>
        <DialogListSelect
            title={intl.formatMessage({ id: "mealPlan.selectAMealPlan", defaultMessage: 'Select A Meal Plan' })}
            options={mealPlans}
            selectedUuid={selectedMealPlanUuid}
            onSelect={setSelectedMealPlanUuid}
            open={displayMealPlanSelect}
            onClose={setDisplayMealPlanSelect}
        />
        <MobileNavbar>
            <NavBarButton onClick={onClose}>
                <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
            </NavBarButton>
            <NavBarTitle>
                <FormattedMessage id="app.foodLog" defaultMessage="Food Log" />
            </NavBarTitle>
        </MobileNavbar>
        <Paper elevation={3} style={{ padding: '20px' }} onClick={() => { setDisplayMealPlanSelect(true) }}>
            <span style={{ fontSize: '32px' }}>{mealPlanName}</span>
            {mealPlanDescription !== ''
                ? <div style={{ whiteSpace: 'pre-line' }}>{mealPlanDescription}</div>
                : null
            }
        </Paper>
        <List memberUuid={memberUuid} uuid={selectedMealPlanUuid} {...props} />
    </>
}