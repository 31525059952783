import React, { useState, useEffect, useCallback } from "react";
import SectionTitle from 'sfl-components/MealPlan/SectionTitle';
import SectionBody from 'sfl-components/MealPlan/SectionBody';
import SectionEntry from 'sfl-components/MealPlan/SectionEntry';
import SectionEntryCell from 'sfl-components/MealPlan/SectionEntryCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Dialog from './Dialog';
import PortionDialog from './PortionDialog';
import { useSFLApi } from 'api';
import { getChildKeys } from 'misc';
import CardButton from 'sfl-components/SFLCards/CardButton';
import BottomNavbar from "sfl-components/Navbars/BottomNavbar.js";
import { FormattedMessage, useIntl } from 'react-intl';

export default function FoodLogMealPlanLayoutMobile(props) {
    const { uuid: mealPlanUuid, section, date, refresh, onClose, memberUuid } = props;
    const [mealPlanEntries, setMealPlanEntries] = useState([]);
    const [editEntry, setEditEntry] = useState();
    const [showOptionsDialog, setShowOptionsDialog] = useState(false);
    const [showPortionDialog, setShowPortionDialog] = useState(false);
    const { get, post } = useSFLApi();
    const intl = useIntl();

    const fetchData = useCallback(async () => {
        try {
            get(`mealPlanEntries?status=active&meal_plan_uuid=${mealPlanUuid}`)
                .then(results => {
                    const data = results.mealPlanEntries.map(e => {
                        const portion = e.portions.find(p => p.member_uuid === memberUuid) || {}
                        return {
                            ...e,
                            amount: portion.amount || '',
                            size: portion.size || '',
                            scale: portion.scale || ''
                        }
                    })
                    setMealPlanEntries(data)
                })
        }
        catch (error) {
            console.log('error', error)
        }

    }, [get, memberUuid, mealPlanUuid]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    /*
    * Toggle the display children state of the UUID which will make recipe ingredient visible.
    */
    const toggleChildren = () => {
        setMealPlanEntries(currentState => {
            const entryIndex = mealPlanEntries.findIndex(e => e.uuid === editEntry.uuid);
            currentState[entryIndex].displayChildren = !currentState[entryIndex].displayChildren;
            return currentState;
        })
    }




    /*
    * Toggle the selected state for the selected UUID and all child entries.
    */
    const selectEntry = (uuid) => {
        const isSelected = mealPlanEntries.find(e => e.uuid === uuid).selected;
        const uuids = [uuid, ...getChildKeys(mealPlanEntries, uuid, 'uuid', 'parent_entry_uuid')];

        /*
        * Loop over all the entries and update all the selected states.
        */
        setMealPlanEntries(
            mealPlanEntries.map(e => ({
                ...e,
                selected: uuids.includes(e.uuid)
                    ? !isSelected
                    : e.selected
            }))
        )
    }


    const saveEntry = async (entry, parentLogUuid) => {
        const results = await post('foodLogs', {
            section: section,
            name: entry.name,
            portion: entry.amount,
            scale: entry.type === 'recipe' ? 'Serving' : entry.scale,
            date: date,
            parent_log_uuid: parentLogUuid
        });

        return results.foodLog.uuid;
    }

    const handleSave = async () => {
        let uuidLog = [];

        try {
            /*
            * Get child keys will return return everything in order of nesting (top first, second next, etc.), so we don't have to loop children
            * Entries should already be in order as well
            */
            const allEntries = getChildKeys(mealPlanEntries, null, 'uuid', 'parent_entry_uuid');


            for (let i = 0; i < allEntries.length; i++) {
                /*
                * Get the entry and look to see if we have saved the parent already.
                * We'll need the parent log uuid to save it to the proper parent
                */
                const entry = mealPlanEntries.find(e => e.uuid === allEntries[i]);
                const parentLogUuid = uuidLog.find(e => e.entryUuid === entry.parent_entry_uuid)?.logUuid || null;

                /*
                * Recipes and foods get saved if selected, but everything else gets skipped.
                * For entries that get skipped, we all their uuid to the log with the log uuid of the parent
                */
                if (['recipe', 'food', 'food_child', 'food_parent'].includes(entry.type) && entry.selected) {
                    const newUuid = await saveEntry(entry, parentLogUuid);
                    uuidLog.push({ entryUuid: entry.uuid, logUuid: newUuid })
                } else {
                    uuidLog.push({ entryUuid: entry.uuid, logUuid: parentLogUuid })
                }
            }
        }
        catch (error) {
            console.log('error', error);
            return toast.error(intl.formatMessage({ id: "app.saveFailed", defaultMessage: "Save Failed" }));
        }

        onClose();
        refresh();
        return toast.success(intl.formatMessage({ id: "app.entrySaved", defaultMessage: "Entry Saved" }));
    }


    const generateEntry = (parentUuid, indentCells = []) => {
        return mealPlanEntries.filter(entry => entry.parent_entry_uuid === parentUuid)
            .map(entry => {
                const { uuid, name, ingredient_description, amount, size, scale, recipe_servings, displayChildren } = entry;

                if (['section'].includes(entry.type)) {
                    return [
                        <SectionTitle key='title' title={name} description={ingredient_description} />,
                        <SectionBody key='body'>
                            {generateEntry(uuid)}
                        </SectionBody>
                    ]
                }

                if (['group', 'group_public'].includes(entry.type)) {

                    return [
                        <SectionEntry
                            key={uuid}
                            title={name}
                            description={ingredient_description}
                            before={[...indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '10px', borderLeft: '5px solid #b5bb00' }} />]}
                            style={{ color: '#b5bb00', paddingLeft: '5px' }}
                        />,
                        generateEntry(uuid, [...indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '10px', borderLeft: '5px solid #b5bb00' }} />])
                    ]

                }

                if (['food', 'food_child', 'food_parent'].includes(entry.type)) {
                    return [
                        <SectionEntry
                            key={uuid}
                            title={name}
                            description={entry.type === 'recipe'
                                ? Number(amount) > 1 ? `${amount} Servings` : `${amount} Serving`
                                : `${amount} ${size}${scale}`
                            }
                            onClick={() => { setEditEntry(entry); setShowOptionsDialog(true) }}
                            before={indentCells}
                        >
                            <SectionEntryCell onClick={() => selectEntry(entry.uuid)}>
                                {entry.selected === true
                                    ? <FontAwesomeIcon icon={['fas', 'check-square']} style={{ fontSize: '20px', color: '#6e3076' }} />
                                    : <FontAwesomeIcon icon={['far', 'square']} style={{ fontSize: '20px' }} />
                                }
                            </SectionEntryCell>
                        </SectionEntry>,
                        displayChildren === true && generateEntry(uuid, [indentCells, <div key='indent' style={{ display: 'flex', width: '20px' }} />])
                    ]
                }

                if (['recipe'].includes(entry.type)) {
                    return [
                        <SectionEntry
                            key={uuid}
                            title={name}
                            description={entry.type === 'recipe'
                                ? Number(amount) > 1 ? `${amount} Servings` : `${amount} Serving`
                                : `${amount} ${size}${scale}`
                            }
                            onClick={() => { setEditEntry(entry); setShowOptionsDialog(true) }}
                            before={indentCells}
                        >
                            <SectionEntryCell onClick={() => selectEntry(entry.uuid)}>
                                {entry.selected === true
                                    ? <FontAwesomeIcon icon={['fas', 'check-square']} style={{ fontSize: '20px', color: '#6e3076' }} />
                                    : <FontAwesomeIcon icon={['far', 'square']} style={{ fontSize: '20px' }} />
                                }
                            </SectionEntryCell>
                        </SectionEntry>,
                        displayChildren === true && [
                            <div key='RecipeIntro' style={{ display: 'flex' }}>
                                {[...indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '5px', borderLeft: '5px solid #6e3076' }} />]}
                                <SectionEntryCell >
                                    <b className='purple'><FormattedMessage id="app.recipeIngredients" defaultMessage="Recipe Ingredients" /></b>
                                    <div><FormattedMessage
                                        id="app.MakesXServings"
                                        defaultMessage="Makes {recipe_servings, plural, one {# serving} other {# servings}}"
                                        values={{ recipe_servings: recipe_servings }}
                                    /></div>
                                </SectionEntryCell>
                            </div>,
                            ...generateEntry(uuid, [indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '15px', borderLeft: '5px solid #6e3076' }} />])
                        ]

                    ]
                }

                return null;
            });
    }

    const handleDialogSelect = option => {
        if (option === 'editPortion') {
            setShowPortionDialog(true);
        }
        if (option === 'toggleChildren') {
            toggleChildren();
        }
    }

    const handlePortionUpdate = (amount, scale) => {
        setMealPlanEntries(currentState => {
            let entryIndex = currentState.findIndex(e => e.uuid === editEntry.uuid);
            currentState[entryIndex].amount = amount;
            currentState[entryIndex].scale = scale;
            return [...currentState];
        })
        setShowPortionDialog(false);
        setEditEntry();
    }

    return [
        <Dialog key='optionsDialog' open={showOptionsDialog} onClose={() => { setShowOptionsDialog(false) }} entry={editEntry} onSelect={handleDialogSelect} />,
        <PortionDialog key='portionsDialog' open={showPortionDialog} onClose={() => setShowPortionDialog(false)} entry={editEntry} onSave={handlePortionUpdate} />,
        <BottomNavbar key='bottomNavBar'>
            <CardButton key="save" onClick={handleSave} style={{ borderRadius: '0px', padding: '0', flexGrow: '1', marginLeft: '0px', height: '40px', backgroundColor: '#b4bb00' }}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} style={{ marginRight: '10px' }} /> <FormattedMessage id="app.save" defaultMessage="Save" />
            </CardButton>
        </BottomNavbar>,
        generateEntry(null)
    ]
}