import React from "react";
import Box from '@material-ui/core/Box';
import CardButton from 'sfl-components/SFLCards/CardButton';

export default function Card(props) {
    const { title = '', buttons = [],style } = props;

    return <Box style={{ padding: '20px', display: 'flex', alignItems: 'center', fontSize: '32px', fontWeight: '500',...style }}>
        <div style={{ flexGrow: '1', fontWeight:'bold' }}>{title}</div>
        {buttons.map((button,index) => {
            const { title: buttonTitle = '', style, ...rest } = { ...button };
            if(button){
                return <CardButton key={index} {...rest} style={{ marginLeft: '20px', ...style }}>{buttonTitle}</CardButton>
            }

            return null
        })}
        {props.children}
    </Box>
}