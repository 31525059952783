import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import FoodPreferenceSelect from 'sfl-components/FoodPreferenceSelect/Mobile';
import MedicalConditionSelect from 'sfl-components/MedicalConditionSelect/Mobile';
import TextField from 'sfl-components/TextField/Standard';
import HeightTextField from "sfl-components/Inputs/HeightTextField";
import WeightTextField from "sfl-components/Inputs/WeightTextField";

import CardButton from 'sfl-components/SFLCards/CardButton';




import { useSFLApi } from 'api';


const QuestionTitle = (props) => {
    return <div style={{ fontWeight: 'bold', marginTop: '20px', }}>{props.children}</div>
}
const StyledPaper = (props) => {
    return <Paper key='intro' style={{ padding: '30px 20px', marginBottom: '30px' }}>
        <div key='title' style={{ color: '#603373', fontSize: '32px', fontWeight: 'bold', marginBottom: '30px', marginTop: '10px', lineHeight: '2rem' }}>{props.title}</div>
        {props.children}
    </Paper>
}

const Question = React.memo((props) => {
    const { onChange, title, label, ...rest } = props

    return <Grid item xs={12} >
        <QuestionTitle>{title}</QuestionTitle>
        <TextField
            {...rest}
            fullWidth
            label={label}
            onChange={onChange}
        >{props.children}</TextField>
    </Grid>
}, (prevProps, nextProps) => prevProps.value === nextProps.value);
Question.displayName = 'QuestionnaireQuestion'


export default function QuestionnaireForm(props) {
    const { onSave } = props;
    const { tokenUuid } = useParams();
    const [state, setState] = useState({});
    const { post } = useSFLApi();

    const [allergies, setAllergies] = useState([]);
    const [likedFoods, setLikedFoods] = useState([]);
    const [dislikedFoods, setDislikedFoods] = useState([]);
    const [medicalConditions, setMedicalConditions] = useState([]);




    const {
        employement_status = '',
        employment_activity_level = '',
        employment_company_name = '',
        height = '',
        height_scale = 'ft',
        current_weight = '',
        current_weight_scale = 'pounds',
        goal_weight = '',
        goal_weight_scale = 'pounds',
        exercise_level = '',
        family_doctor = '',
        last_checkup = '',
        allergies_restrictions_other = '',
        medical_extra = '',
        what_other_programs = '',
        what_brings_you = '',
        household = '',
        pets = '',
        gender = '',
        primary_goal = '',
        goal_celebration = '',
        restaurant_frequency = '',
        cook_frequency = '',
        average_food = '',
        tune = ''
    } = state;




    const handleChange = React.useCallback((key, value) => {
        setState(currentState => ({ ...currentState, [key]: value }));
    }, [setState]);


    const handleSave = React.useCallback(async () => {
        post(`questionnaires/token/${tokenUuid}`, {
            data: {
                ...state,
                allergies: allergies,
                liked_foods: likedFoods,
                disliked_foods: dislikedFoods,
                medical_conditions: medicalConditions
            }
        })
            .then(() => {
                onSave();
            })
            .catch(() => toast.error('Save Failed'));
    }, [post, tokenUuid, onSave, state, allergies, likedFoods, dislikedFoods, medicalConditions]);

    const intro = () => {
        return <Grid container spacing={3}>
            <Question
                id="what_brings_you"
                title="What brings you to Simply For Life?"
                label="What brought me is..."
                multiline
                rows={1}
                rowsMax={10}
                value={what_brings_you}
                onChange={handleChange}
            />
            <Question
                id="what_other_programs"
                title="What have you tried previously and why didn't it work?"
                label="Previous Experiences"
                helperText="Ex: Number of attempts, program names, results, etc."
                multiline
                rows={1}
                rowsMax={10}
                value={what_other_programs}
                onChange={handleChange}
            />
            <Question
                id="household"
                title="How many people are there in your household and who usualy does the cooking?"
                label="Household"
                helperText="Ex: Me, my wife, and our two kids (6 and 8). We usualy take turns preparing the meals."
                multiline
                rows={1}
                rowsMax={10}
                value={household}
                onChange={handleChange}
            />
            <Question
                id="pets"
                title="Do you have any pets?"
                label="Pets"
                helperText="Make sure to show a picture to your consultant!"
                multiline
                rows={1}
                rowsMax={10}
                value={pets}
                onChange={handleChange}
            />
            <Question
                id="tune"
                title="What is your favourite tune?"
                label="Tune"
                value={tune}
                onChange={handleChange}
            />
            <Grid item xs={12} >
                <QuestionTitle>How tall are you?</QuestionTitle>
                <HeightTextField
                    id="height"
                    label="Height"
                    variant="standard"
                    height={height}
                    scale={height_scale}
                    onHeightChange={(updatedHeight) => handleChange('height', updatedHeight)}
                    onHeightScaleChange={(updatedScale) => handleChange('height_scale', updatedScale)}
                />
            </Grid>
            <Question
                id="gender"
                title="What is your gender?"
                label="Gender"
                helperText="Gender is a factor for determining appropriate portion sizes. If you're unsure which option to pick, please select 'Other' and your consultant will work with you to determine the appropriate portion sizes."
                select
                value={gender}
                onChange={handleChange}
            >
                <MenuItem key='empty' value=''></MenuItem>
                <MenuItem key='male' value='male'>Male</MenuItem>
                <MenuItem key='female' value='female'>Female</MenuItem>
                <MenuItem key='other' value='other'>Other</MenuItem>
            </Question>
        </Grid>
    }

    const intro2 = () => {
        return <Grid container spacing={3}>
            <Question
                id="primary_goal"
                title="What is your primary goal?"
                label="Primary goal"

                select
                value={primary_goal}
                onChange={handleChange}
            >
                <MenuItem key='empty' value=''></MenuItem>
                <MenuItem key='moreEnergy' value='moreEnergy'>I want to have more energy</MenuItem>
                <MenuItem key='feelBetter' value='feelBetter'>I want to feel better</MenuItem>
                <MenuItem key='loseWeight' value='loseWeight'>I want to lose weight</MenuItem>
            </Question>
            <Grid item xs={12} >
                <QuestionTitle>What's your current weight?</QuestionTitle>
                <WeightTextField
                    id="current_weight"
                    label="Weight"

                    variant="standard"
                    weight={current_weight}
                    scale={current_weight_scale}
                    onWeightChange={(updatedWeight) => handleChange('current_weight', updatedWeight)}
                    onWeightScaleChange={(updatedWeightScale) => handleChange('current_weight_scale', updatedWeightScale)}
                />
            </Grid>
            <Grid item xs={12} >
                <QuestionTitle>What's your goal weight?</QuestionTitle>
                <WeightTextField
                    id="goal_weight"
                    label="Goal Weight"

                    variant="standard"
                    weight={goal_weight}
                    scale={goal_weight_scale}
                    onWeightChange={(updatedWeight) => handleChange('goal_weight', updatedWeight)}
                    onWeightScaleChange={(updatedWeightScale) => handleChange('goal_weight_scale', updatedWeightScale)}
                />
            </Grid>
            <Question
                id="goal_celebration"
                title="Once you reach your goal, how would you like to celebrate?"
                label="Celebration"
                multiline
                rows={1}
                rowsMax={10}
                value={goal_celebration}
                onChange={handleChange}
            />
        </Grid>
    }

    const food = () => {
        return <Grid container spacing={3}>
            <Question
                id="average_food"
                title="What do you normally eat during the run of a day?"
                label="Daily Meals"
                helperText="For example: I normally have a muffin and a latte for breakfast and for lunch I have..."
                multiline
                rows={1}
                rowsMax={10}
                value={average_food}
                onChange={handleChange}
            />
            <Grid item xs={12} >
                <QuestionTitle>What types of foods do you love to eat?</QuestionTitle>
                <FoodPreferenceSelect id='likedFoods' label="Favourite Foods" variant="standard" selectedOptions={likedFoods} onChange={setLikedFoods} />
            </Grid>
            <Grid item xs={12} >
                <QuestionTitle>What types of foods do you not like to eat?</QuestionTitle>
                <FoodPreferenceSelect id='dislikedFoods' label="Disliked Foods" variant="standard" selectedOptions={dislikedFoods} onChange={setDislikedFoods} />
            </Grid>
            <Grid item xs={12} >
                <QuestionTitle>What types of foods are you allergic to?</QuestionTitle>
                <FoodPreferenceSelect id='allergyFoods' label="Food Allergies" variant="standard" selectedOptions={allergies} onChange={setAllergies} />
            </Grid>
            <Question
                id="allergies_restrictions_other"
                title="Are there any other allergies or restrictions you would like to mention?"
                label="Other allergies and restrictions"
                multiline
                rows={1}
                rowsMax={10}
                value={allergies_restrictions_other}
                onChange={handleChange}
            />
        </Grid>
    }

    const exercise = () => {
        return <Grid container spacing={0}>
            <Question
                id="exercise_level"
                title="How many hours per week do you exercise?"
                label="Exercise"
                select
                value={exercise_level}
                onChange={handleChange}
            >
                <MenuItem key='empty' value=''></MenuItem>
                <MenuItem key='none' value='none'>I don't exercise</MenuItem>
                <MenuItem key='low' value='low'>Under 2 hours</MenuItem>
                <MenuItem key='medium' value='medium'>Between 2 to 5 hours</MenuItem>
                <MenuItem key='high' value='high'>More than 5 hours</MenuItem>
            </Question>
            <Question
                id="restaurant_frequency"
                title="On average, how many times per week do you eat meals from restaurant?"
                label="Resutants per week"
                helperText="This includes dine in, take out, etc."
                select
                value={restaurant_frequency}
                onChange={handleChange}
            >
                <MenuItem key='empty' value=''></MenuItem>
                <MenuItem key='1' value='1'>1-5</MenuItem>
                <MenuItem key='6' value='6'>6-10</MenuItem>
                <MenuItem key='11' value='11'>11+</MenuItem>
            </Question>
            <Question
                id="cook_frequency"
                title="On average, how many times per week do you cook?"
                label="Cooking per week"
                select
                value={cook_frequency}
                onChange={handleChange}
            >
                <MenuItem key='empty' value=''></MenuItem>
                <MenuItem key='1' value='1'>1-5</MenuItem>
                <MenuItem key='6' value='6'>6-10</MenuItem>
                <MenuItem key='11' value='11'>11+</MenuItem>
            </Question>
        </Grid>
    }

    const medical = () => {
        return <Grid container spacing={3}>
            <Question
                id="family_doctor"
                title="Who is your family doctor?"
                label="Family Doctor"
                value={family_doctor}
                onChange={handleChange}
            />
            <Question
                id="last_checkup"
                title="When was your last checkup?"
                label="Last Checkup"
                helperText="An approximate date is sufficient if you're unsure."
                value={last_checkup}
                onChange={handleChange}
            />
            <Grid item xs={12} >
                <QuestionTitle>Do you suffer from any of the following medical conditions?</QuestionTitle>
                <MedicalConditionSelect id='medicalConditions' label="Medical Conditions" variant="standard" selectedOptions={medicalConditions} onChange={(medicalConditionsList) => { setMedicalConditions(medicalConditionsList) }} />
            </Grid>
            <Question
                id="medical_extra"
                title="Do you have any other medical conditions or take any other medications?"
                label="Other conditions and medications"
                multiline
                rows={1}
                rowsMax={10}
                value={medical_extra}
                onChange={handleChange}
            />
        </Grid>
    }

    const employement = () => {
        return <Grid container spacing={3}>
            <Question
                id="employement_status"
                title="Which option best describes your employement status?"
                label="Employement Status"
                select
                value={employement_status}
                onChange={handleChange}
            >
                <MenuItem key='empty' value=''></MenuItem>
                <MenuItem key='full_time' value='full_time'>Employed Full time</MenuItem>
                <MenuItem key='part_time' value='part_time'>Employed Part time</MenuItem>
                <MenuItem key='education' value='education'>Unemployed in order to further my eduction</MenuItem>
                <MenuItem key='illness' value='illness'>Unemployed due to disbility, stress, sick leave, etc.</MenuItem>
                <MenuItem key='unemployed' value='unemployed'>Temporarily unemployed, but looking for work</MenuItem>
                <MenuItem key='retired' value='retired'>Retired (congratulations!)</MenuItem>
            </Question>
            {['full_time', 'part_time'].includes(employement_status) &&
                <>
                    <Question
                        id="employment_activity_level"
                        title="How physically engaged are you at work?"
                        label="Physical Engagement"
                        select
                        value={employment_activity_level}
                        onChange={handleChange}
                    >
                        <MenuItem key='empty' value=''></MenuItem>
                        <MenuItem key='low' value='low'>Very little (mostly sitting)</MenuItem>
                        <MenuItem key='medium' value='medium'>A moderate amount (mostly walking and standing)</MenuItem>
                        <MenuItem key='high' value='high'>Heavy Activity (manual labor)</MenuItem>
                    </Question>
                    <Question
                        id="employment_company_name"
                        title="What's the name of your company?"
                        label="Company Name"
                        value={employment_company_name}
                        onChange={handleChange}
                    />
                </>
            }
        </Grid>
    }


    return <div style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
        <div style={{ padding: '30px 20px', backgroundColor: 'white' }}>
            Is it going to be hard, yes, but that’s the reason why you chose to work with an expert.
            We take on the challenges with you so you never have to feel alone in your journey.
        </div>
        <StyledPaper key='intro' title="Tell us about yourself">
            {intro()}
        </StyledPaper>
        <StyledPaper key='intro2' title="What are you goals?">
            {intro2()}
        </StyledPaper>
        <StyledPaper key='food' title="What are you food preferences?">
            {food()}
        </StyledPaper>
        <StyledPaper key='exercise' title="What habits do you have?">
            {exercise()}
        </StyledPaper>
        <StyledPaper key='medical' title="Medical Information">
            {medical()}
        </StyledPaper>
        <StyledPaper key='employement' title="What do you do for a living?">
            {employement()}
        </StyledPaper>
        <div style={{ textAlign: 'center', padding: '20px 0 80px 0' }}>
            <CardButton onClick={handleSave}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
                Submit Questionnaire
            </CardButton>
        </div>
    </div>
}