import React from "react";
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";
import { FormattedMessage } from 'react-intl';
import Appointments from "./Appointments";
import LatestNews from "./LatestNews";
import Socials from "./Socials";


export default function DashboardMemberMobile() {
    return [
        <MobileNavbar key='MobileNavbar'>
            <SidebarToggle />
            <NavBarTitle>
                <FormattedMessage
                    id="app.dashboard"
                    defaultMessage="Dashboard"
                /></NavBarTitle>
        </MobileNavbar>,
        <Appointments key='appointment' />,
        <LatestNews key='news' />,
        <Socials key='socials' />
    ]
}