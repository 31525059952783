import React from "react";
import Appointments from "./Appointments";
import LatestNews from "./LatestNews";
import Socials from "./Socials";


export default function DashboardMemberDesktop() {
    return [
        <Appointments key='appointment' />,
        <LatestNews key='news' />,
        <Socials key='socials' />
    ]
}