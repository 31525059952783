import React from "react";
import Hidden from "@material-ui/core/Hidden";
import LayoutMobile from './mobile';
import LayoutDesktop from './desktop';

export default function DashboardMember(props) {
    return <>
        <Hidden smDown key='desktop'>
            <LayoutDesktop {...props} />
        </Hidden>
        <Hidden mdUp key='mobile'>
            <LayoutMobile {...props} />
        </Hidden>
    </>
}