import React, { useState, useEffect, useCallback } from "react";
import SectionTitle from 'sfl-components/MealPlan/SectionTitle';
import SectionBody from 'sfl-components/MealPlan/SectionBody';
import SectionEntry from 'sfl-components/MealPlan/SectionEntry';
import SectionEntryCell from 'sfl-components/MealPlan/SectionEntryCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Dialog from './Dialog';
import PortionDialog from './PortionDialog';
import { useSFLApi } from 'api';
import { getChildKeys } from 'misc';
import { FormattedMessage, useIntl } from 'react-intl';


export default function MealPlanLayout(props) {
    const { section, date, pastLogsDate, refresh, onClose, triggerSave, memberUuid } = props;
    const [foodLogEntries, setFoodLogEntries] = useState([]);
    const [editEntry, setEditEntry] = useState();
    const [showOptionsDialog, setShowOptionsDialog] = useState(false);
    const [showPortionDialog, setShowPortionDialog] = useState(false);
    const { get, post } = useSFLApi();
    const intl = useIntl();


    const sections = [
        { key: 'breakfast', name: <FormattedMessage id="app.breakfast" defaultMessage="Breakfast" /> },
        { key: 'morning_snack', name: <FormattedMessage id="app.morningSnack" defaultMessage="Morning Snack" /> },
        { key: 'lunch', name: <FormattedMessage id="app.lunch" defaultMessage="Lunch" /> },
        { key: 'afternoon_snack', name: <FormattedMessage id="app.afternoonSnack" defaultMessage="Afternoon Snack" /> },
        { key: 'supper', name: <FormattedMessage id="app.supper" defaultMessage="Supper" /> },
        { key: 'evening_snack', name: <FormattedMessage id="app.eveningSnack" defaultMessage="Evening Snack" /> }
    ]


    const fetchData = useCallback(async (date) => {
        try {
            get(`foodLogs?status=active&date=${date}&member_uuid=${memberUuid}`)
                .then(results => {
                    setFoodLogEntries(results.foodLogs)
                })
        }
        catch (error) {
            console.log('error', error)
        }

    }, [get, setFoodLogEntries, memberUuid]);


    useEffect(() => {
        fetchData(pastLogsDate);
    }, [fetchData, pastLogsDate]);


    /*
    * Toggle the display children state of the UUID which will make recipe ingredient visible.
    */
    const toggleChildren = () => {
        setFoodLogEntries(currentState => {
            const entryIndex = foodLogEntries.findIndex(e => e.uuid === editEntry.uuid);
            currentState[entryIndex].displayChildren = !currentState[entryIndex].displayChildren;
            return currentState;
        })
    }




    /*
    * Toggle the selected state for the selected UUID and all child entries.
    */
    const selectEntry = (uuid) => {
        const isSelected = foodLogEntries.find(e => e.uuid === uuid).selected;
        const uuids = [uuid, ...getChildKeys(foodLogEntries, uuid, 'uuid', 'parent_log_uuid')];

        /*
        * Loop over all the entries and update all the selected states.
        */
        setFoodLogEntries(
            foodLogEntries.map(e => ({
                ...e,
                selected: uuids.includes(e.uuid)
                    ? !isSelected
                    : e.selected
            }))
        )
    }

    const handleSave = React.useCallback(async () => {


        const saveEntry = async (entry, parentLogUuid) => {
            const results = await post('foodLogs', {
                member_uuid: memberUuid,
                section: section,
                name: entry.name,
                portion: entry.portion,
                scale: entry.scale,
                date: date,
                parent_log_uuid: parentLogUuid
            });

            return results.foodLog.uuid;
        }
        let uuidLog = [];

        try {
            /*
            * Get child keys will return return everything in order of nesting (top first, second next, etc.), so we don't have to loop children
            * Entries should already be in order as well
            */
            const allEntries = getChildKeys(foodLogEntries, null, 'uuid', 'parent_log_uuid');


            for (let i = 0; i < allEntries.length; i++) {
                /*
                * Get the entry and look to see if we have saved the parent already.
                * We'll need the parent log uuid to save it to the proper parent
                */
                const entry = foodLogEntries.find(e => e.uuid === allEntries[i]);
                const parentLogUuid = uuidLog.find(e => e.entryUuid === entry.parent_log_uuid)?.logUuid || null;

                /*
                * Recipes and foods get saved if selected, but everything else gets skipped.
                * For entries that get skipped, we all their uuid to the log with the log uuid of the parent
                */
                if (entry.selected) {
                    const newUuid = await saveEntry(entry, parentLogUuid);
                    uuidLog.push({ entryUuid: entry.uuid, logUuid: newUuid })
                } else {
                    uuidLog.push({ entryUuid: entry.uuid, logUuid: parentLogUuid })
                }
            }
        }
        catch (error) {
            console.log('error', error);
            return toast.error(intl.formatMessage({ id: "app.saveFailed", defaultMessage: "Save Failed" }));
        }

        onClose();
        refresh();
        return toast.success(intl.formatMessage({ id: "app.entrySaved", defaultMessage: "Entry Saved" }));
    }, [foodLogEntries, intl, onClose, refresh, date, post, section, memberUuid])


    useEffect(() => {
        if (triggerSave) {
            handleSave();
        }
    }, [triggerSave, memberUuid, handleSave]);



    const generateEntry = (parentUuid, sectionKey, indentCells = []) => {
        return foodLogEntries.filter(entry => entry.parent_log_uuid === parentUuid && entry.section === sectionKey)
            .map(entry => {
                const { uuid, name, portion, scale, displayChildren } = entry;

                return [
                    <SectionEntry
                        key={uuid}
                        title={name}
                        description={`${portion} ${scale}`}
                        onClick={() => { setEditEntry(entry); setShowOptionsDialog(true) }}
                        before={indentCells}
                    >
                        <SectionEntryCell onClick={() => selectEntry(entry.uuid)}>
                            {entry.selected === true
                                ? <FontAwesomeIcon icon={['fas', 'check-square']} style={{ fontSize: '20px', color: '#6e3076' }} />
                                : <FontAwesomeIcon icon={['far', 'square']} style={{ fontSize: '20px' }} />
                            }
                        </SectionEntryCell>
                    </SectionEntry>,
                    displayChildren === true && generateEntry(uuid, sectionKey, [indentCells, <div key={`indent${uuid}`} style={{ display: 'flex', width: '20px' }} />])
                ]
            });
    }

    const handleDialogSelect = option => {
        if (option === 'editPortion') {
            setShowPortionDialog(true);
        }
        if (option === 'toggleChildren') {
            toggleChildren();
        }
    }

    const handlePortionUpdate = (portion, scale) => {
        setFoodLogEntries(currentState => {
            let entryIndex = currentState.findIndex(e => e.uuid === editEntry.uuid);
            currentState[entryIndex].portion = portion;
            currentState[entryIndex].scale = scale;
            return [...currentState];
        })
        setShowPortionDialog(false);
        setEditEntry();
    }




    const content = sections.map(section => {
        const contentArray = generateEntry(null, section.key);
        if (contentArray.length > 0) {
            return [
                <SectionTitle key='title' title={section.name} />,
                <SectionBody key='body'>
                    {contentArray}
                </SectionBody>
            ]
        }
        return undefined;
    }).filter(e => e)

    if (content.length === 0) {
        return <div style={{ minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '20px' }}>
            <FormattedMessage id='app.foodLog.listEmptyForDate' defaultMessage='Your food log is empty for the selected date.' />
        </div>
    }


    return [
        <Dialog key='Dialog' open={showOptionsDialog} onClose={() => { setShowOptionsDialog(false) }} entry={editEntry} onSelect={handleDialogSelect} />,
        <PortionDialog key='PortionDialog' open={showPortionDialog} onClose={() => setShowPortionDialog(false)} entry={editEntry} onSave={handlePortionUpdate} />,
        content
    ]
}