import React from "react";
import SectionEntryCell from 'sfl-components/MealPlan/SectionEntryCell';

export default function SectionEntry(props) {
    const { title, description, style, onClick } = props;

    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
    }

    return <div key='sectionEntry' style={{ display: 'flex' }}>
        {props.before}
        <SectionEntryCell key='first' style={{ flexGrow: '1', ...style }} onClick={handleClick}>
            <b>{title}</b>
            {description !== '' ? <div>{description}</div> : null}
        </SectionEntryCell>
        {props.children}
    </div >
}
