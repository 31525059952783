import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';



const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmationDialog = (props) => {
	const { open, type, title, description, onSubmit, onClose, cancelText = 'Cancel', submitText = 'OK' } = props;

	if (type === 'warning') {
		return (<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			style={{ zIndex: '2000' }}
		>
			<DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					{description}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Ok
				</Button>
			</DialogActions>
		</Dialog>);
	}

	return (<Dialog
		open={open}
		TransitionComponent={Transition}
		keepMounted
		onClose={onClose}
		aria-labelledby="alert-dialog-slide-title"
		aria-describedby="alert-dialog-slide-description"
		style={{ zIndex: '2000' }}
	>
		<DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
		<DialogContent>
			<DialogContentText id="alert-dialog-slide-description">
				{description}
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose} color="primary">
				{cancelText}
			</Button>
			<Button onClick={onSubmit} color="primary">
				{submitText}
			</Button>
		</DialogActions>
	</Dialog>);
}





