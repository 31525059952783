import React, { useState } from "react";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TextField from 'sfl-components/TextField/Standard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardButton from 'sfl-components/SFLCards/CardButton';

import { useSFLApi } from 'api';

import BottomNavbar from "sfl-components/Navbars/BottomNavbar.js";
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage, useIntl } from 'react-intl';


export default function MemberForm() {
    const [state, setState] = useState({});
    const { post } = useSFLApi();
    const intl = useIntl();

    const { general_rating = '', general_feedback = '' } = state;

    const handleChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }

    const handleSave = () => {
        post('feedbacks', state)
            .then(() => {
                setState({});
                toast.success(intl.formatMessage({ id: "feedback.success", defaultMessage: 'Your feedback has been submitted!' }));
            })
            .catch(error => {
                console.log(error)
                toast.error(intl.formatMessage({ id: "feedback.fail", defaultMessage: 'An error has occured.' }))
            });
    }


    return [
        <MobileNavbar key='MobileNavbar'>
            <SidebarToggle />
            <NavBarTitle>
                <FormattedMessage id="feedback.title" defaultMessage="Member Feedback" />
            </NavBarTitle>
        </MobileNavbar>,
        <BottomNavbar key='BottomNavbar'>
            <CardButton key="save" onClick={handleSave} style={{ borderRadius: '0px', padding: '0', flexGrow: '1', marginLeft: '0px', height: '40px', backgroundColor: '#b4bb00' }}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} style={{ marginRight: '10px' }} />
                <FormattedMessage id="feedback.submitFeedback" defaultMessage="Submit Feedback" />
            </CardButton>
        </BottomNavbar>,
        <Paper key='content' style={{ padding: '30px 20px' }}>
            <div style={{ color: '#603373', fontSize: '32px', fontWeight: 'bold', marginBottom: '10px' }}>
                <FormattedMessage id="feedback.howAreWeDoing" defaultMessage="How are we doing?" />
            </div>
            <Grid item xs={12}>
                <FormattedMessage
                    id="feedback.intro"
                    defaultMessage="Simply For Life wouldn't be where it is today without our members. 
                                    If yould like to provide some feedback (good or bad), we'd love to hear from you."
                />
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '40px' }}>
                <Grid item xs={12}>
                    <div style={{ fontWeight: 'bold' }}>
                        <FormattedMessage
                            id="feedback.howWouldYouRateUs"
                            defaultMessage="How would you rate your experience at Simply For Life?"
                        />
                    </div>
                    <TextField
                        id="general_rating"
                        name="general_rating"
                        label={intl.formatMessage({ id: "feedback.yourRating", defaultMessage: "Your Rating" })}
                        select
                        value={general_rating}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'>1 (<FormattedMessage id="feedback.theWorst" defaultMessage="The Worst" />)</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='2' value='2'>2</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='3' value='3'>3</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='4' value='4'>4</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='5' value='5'>5 (<FormattedMessage id="feedback.average" defaultMessage="Average" />)</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'>6</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='7' value='7'>7</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='8' value='8'>8</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='9' value='9'>9</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='10' value='10'>10 (<FormattedMessage id="feedback.theBest" defaultMessage="The Best" />)</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '40px' }}>
                    <div style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="feedback.howCanWeDoBetter" defaultMessage="What could we do to make your experience better?" />
                    </div>
                    <TextField
                        id="general_feedback"
                        name="general_feedback"
                        label={intl.formatMessage({ id: "feedback.yourFeedback", defaultMessage: "Your Feedback" })}
                        multiline
                        rows={1}
                        rowsMax={20}
                        value={general_feedback}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </Paper>
    ]
}