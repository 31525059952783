import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
    >
        <g>
            <g>
                <path fill={color} d="M316,30h-17.563C292.24,12.539,275.556,0,256,0c-19.556,0-36.24,12.539-42.437,30H196c-24.814,0-45,20.186-45,45
			c0,8.291,6.709,15,15,15h180c8.291,0,15-6.709,15-15C361,50.186,340.814,30,316,30z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={color} d="M406,60h-16.518c0.989,4.849,1.518,9.864,1.518,15c0,24.814-20.186,45-45,45H166c-24.814,0-45-20.186-45-45
			c0-5.136,0.529-10.151,1.518-15H106c-24.814,0-45,20.186-45,45v362c0,24.814,20.186,45,45,45h300c24.814,0,45-20.186,45-45V105
			C451,80.186,430.814,60,406,60z M211,420h-60c-8.291,0-15-6.709-15-15c0-8.291,6.709-15,15-15h60c8.291,0,15,6.709,15,15
			C226,413.291,219.291,420,211,420z M211,315h-60c-8.291,0-15-6.709-15-15c0-8.291,6.709-15,15-15h60c8.291,0,15,6.709,15,15
			C226,308.291,219.291,315,211,315z M211,210h-60c-8.291,0-15-6.709-15-15s6.709-15,15-15h60c8.291,0,15,6.709,15,15
			S219.291,210,211,210z M356.605,424.395c5.859,5.859,5.859,15.352,0,21.211c-2.93,2.93-6.768,4.395-10.605,4.395
			c-3.837,0-7.676-1.465-10.605-4.395L316,426.211l-19.395,19.395c-2.93,2.93-6.768,4.395-10.605,4.395
			c-3.837,0-7.676-1.465-10.605-4.395c-5.859-5.859-5.859-15.352,0-21.211L294.789,405l-19.395-19.395
			c-5.859-5.859-5.859-15.352,0-21.211s15.352-5.859,21.211,0L316,383.789l19.395-19.395c5.859-5.859,15.352-5.859,21.211,0
			s5.859,15.352,0,21.211L337.211,405L356.605,424.395z M371.605,280.605l-45,45c-2.93,2.93-6.768,4.395-10.605,4.395
			c-3.837,0-7.676-1.465-10.605-4.395l-30-30c-5.859-5.859-5.859-15.352,0-21.211s15.352-5.859,21.211,0L316,293.789l34.395-34.395
			c5.859-5.859,15.352-5.859,21.211,0C377.465,265.253,377.465,274.746,371.605,280.605z M371.605,190.605l-45,45
			c-2.93,2.93-6.768,4.395-10.605,4.395c-3.837,0-7.676-1.465-10.605-4.395l-30-30c-5.859-5.859-5.859-15.352,0-21.211
			s15.352-5.859,21.211,0L316,203.789l34.395-34.395c5.859-5.859,15.352-5.859,21.211,0S377.465,184.746,371.605,190.605z"/>
            </g>
        </g>
    </svg >
}

export default SvgComponent