import React from "react";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import CardButtonGroup from 'sfl-components/Card/CardButtonGroup';


export default function SFLDialog(props) {
    const { maxWidth = 'sm', open = true, style, ...rest } = props;
    return (<Dialog open={open} onClose={props.onClose} aria-labelledby="form-dialog-title" scroll='body'
        maxWidth={maxWidth}
        PaperProps={{
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                verticalAlign: 'top',
                ...style
            }
        }}
        onBackdropClick={(event) => {
            if (window.innerWidth - event.clientX > 40) {
                props.onClose()
            }
        }}
        {...rest}
    >
        <DialogContent>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} >
                    <Card>
                        <CardHeader color="success" stats icon style={{ display: 'flex' }}>
                            <CardButtonGroup>
                                {props.buttons}
                            </CardButtonGroup>
                        </CardHeader>
                        <CardBody>
                            <Box mt={5}>
                                {props.children}
                            </Box>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>)
}