import React from "react";
import ButtonBase from '@material-ui/core/ButtonBase';

export default function CircleButton(props) {
    let { size = '36px', style, className = '', ...rest } = props;
    let buttonStyle = {
        width: size,
        height: size,
        borderRadius: '50%',
        fontSize: '20px'
    }
    className = `circleButton ${className}`

    return <ButtonBase className={className} style={{ ...buttonStyle, ...style }} {...rest}>{props.children}</ButtonBase>
}
