import React from "react";

export default function SectionEntryCell(props) {
    const { style, onClick } = props;

    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
    }

    const cellStyle = {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '0',
        justifyContent: 'center',
        padding: '10px 20px',
        borderBottom: '1px solid #eeeeee',
        ...style
    }

    return <div style={cellStyle} onClick={handleClick}>
        {props.children}
    </div>
}
