import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import logo from "assets/images/logo-1.png";
import { useSFLApi } from 'api';
import Form from "./Form";

import Layout from '../Layout';


import bowlImg from 'assets/images/bowls/Bal_Leaves_circle_dishes_400px.png';

import { FormattedMessage } from 'react-intl';

export default function PasswordResetForm() {
    const [tokenStatus, setTokenStatus] = useState('pending');
    const { tokenUuid } = useParams();
    const { get } = useSFLApi();

    const fetchData = React.useCallback(async () => {
        get(`tokens/${tokenUuid}`)
            .then(data => {
                if (data.token.status === 'active' && data.token.type === 'questionnaire') {
                    setTokenStatus('active')
                } else {
                    setTokenStatus(data.token.status)
                }
            })
            .catch(error => {
                console.log(error);
                setTokenStatus('error')
            });
    }, [get, tokenUuid]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);



    let message = 'Validating'
    if (tokenStatus === 'error') {
        message = <FormattedMessage id="questionnaire.tokenError" defaultMessage="An error has occured while validating your request." />
    }
    if (tokenStatus === 'inactive') {
        message = <FormattedMessage id="questionnaire.tokenInactive" defaultMessage="This token is no longer valid." />
    }
    if (tokenStatus === 'complete') {
        message = <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '32px', marginTop: '100px', marginBottom: '40px' }}><i>
                <FormattedMessage id="questionnaire.thanksYouRock" defaultMessage="Thanks, you rock!" />
            </i></div>
            <img src={bowlImg} alt='' style={{ maxWidth: '300px' }} />
        </div>
    }
    if (tokenStatus === 'active') {
        message = <Form onSave={fetchData} />;
    }


    return (<Layout>
        <div style={{ textAlign: 'center' }}>
            <img src={logo} alt='Simply For Life' style={{ maxWidth: '450px', width: '100%' }} />
        </div>
        {message}
    </Layout>);
}
