import React from "react";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CardButton = props => {
    const history = useHistory();

    let buttonColors = {
        color: 'white',
        backgroundColor: '#6b3374',
    }

    if (props.color === 'grey') {
        buttonColors = {
            color: 'white',
            backgroundColor: '#333333',
        }
    }

    let icon = props.startIcon;
    if (props.icon) {
        icon = <FontAwesomeIcon icon={props.icon} fixedWidth />
    }
    if (props.iconComponent) {
        icon = props.iconComponent
    }

    return <Button
        variant="contained"
        style={{
            borderRadius: '25px',
            alignSelf: 'center',
            marginLeft: '20px',
            minWidth: 'max-content',
            ...buttonColors,
            ...props.style
        }}
        startIcon={icon}
        onClick={props.to
            ? () => history.push(props.to)
            : props.onClick
        }
    >
        {props.title ? props.title : props.children}
    </Button>
}

export default React.memo(CardButton)