import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
    >
        <path fill={color} d="M303.392 61.393C271.597 41.783 233.92 31 195 31 89.054 31 0 109.933 0 211c0 35.435 11.008 69.404 31.918 98.741l-29.21 91.706C-.378 411.134 6.878 421 17.003 421a14.97 14.97 0 006.795-1.629l88.832-45.167a203.154 203.154 0 0010.918 4.328C102.981 346.43 92 309.58 92 271c0-114.897 96.678-203.228 211.392-209.607z" />
        <path fill={color} d="M480.082 369.741C500.992 340.404 512 306.435 512 271c0-101.104-89.092-180-195-180-105.946 0-195 78.933-195 180 0 101.104 89.092 180 195 180 28.417 0 56.732-5.791 82.365-16.798l88.837 45.169a15.001 15.001 0 0021.091-17.923zM256 286c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15z" />
    </svg>
}

export default SvgComponent