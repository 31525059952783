import React, { useState } from "react";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Calendar from '@material-ui/icons/DateRange';
import { useSelector } from 'react-redux'
import { getDateFormat } from "misc";


import moment from 'moment'
import DatePickerPopover from 'sfl-components/DatePickerPopover'



export default function DatePicker(props) {
    const { onChange, label, value } = props;
    const { locale = 'en-ca' } = useSelector(state => state.appSettings);
    const [showDatePicker, setShowDatePicker] = useState(null);

    const dateFormat = getDateFormat(locale)

    const dateObj = moment(value)
    dateObj.locale(locale)

    const openDatePicker = (event) => {
        if (showDatePicker) {
            setShowDatePicker(null);
        } else {
            setShowDatePicker(event.currentTarget);
        }

    };

    const closeDatePicker = () => {
        setShowDatePicker(null);
    };

    const selectDate = (d) => {
        onChange(d.format('YYYY-MM-DD'));
        closeDatePicker();
    }

    const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
        let i
        let years = []
        for (i = moment().year(); i >= moment().year() - 100; i--) {
            years.push(<option value={i} key={`year-${i}`}>{i}</option>)
        }
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                    <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
                        {moment.months().map((label, value) => (
                            <option value={value} key={value}>{label}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
                        {years}
                    </select>
                </div>
            </div>
        )
    }

    return <FormControl fullWidth variant="filled">
        <InputLabel htmlFor="password">{label}</InputLabel>
        <FilledInput
            id="password"
            type='text'
            value={dateObj.format(dateFormat)}
            onClick={openDatePicker}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility">
                        <Calendar />
                    </IconButton>
                </InputAdornment>
            }
        />
        <DatePickerPopover
            open={Boolean(showDatePicker)}
            anchorEl={showDatePicker}
            onClose={closeDatePicker}
            onDateChange={selectDate}
            date={dateObj}
            calendarProps={{
                renderMonthElement: renderMonthElement
            }}
        />
    </FormControl>
}