import React from "react";

import Hidden from "@material-ui/core/Hidden";

import MealPlanMobile from './GroceryList.mobile';
import MealPlanDesktop from './GroceryList.desktop';

export default function GroceryList() {


	return <>
		<Hidden key='desktop' smDown>
			<MealPlanDesktop />
		</Hidden>
		<Hidden key='mobile' mdUp>
			<MealPlanMobile />
		</Hidden>
	</>
}