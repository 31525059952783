import React from "react";
import { Row, Cell } from 'sfl-components/Containers2';

import generateEntry from './Entry';

export default function Food(props) {
    const parentIndentCells = [...props.indentCells];
    const childIndentCells = [...props.indentCells];
    const { uuid, name, ingredient_description, amount, size, scale } = props.data


    parentIndentCells.push(<Cell key='indent' style={{ flexGrow: '0', width: '15px', borderLeft: '5px solid #b5bb00' }} />)
    childIndentCells.push(<Cell key='indentBorder' className='groupBlue' style={{ flexGrow: '0', width: '25px', borderLeft: '5px solid #b5bb00' }} />)


    if (parentIndentCells.length === 0) {
        parentIndentCells.push(<Cell key='indentExtra' style={{ flexGrow: '0', width: '20px' }} />)
    }


    const childContent = generateEntry(props.mealPlanEntries, props.members, uuid, props.actions, childIndentCells)

    return [
        <Row key={uuid}>
            {parentIndentCells}
            <Cell style={{ padding: '10px 10px 10px 0', color: '#b5bb00' }}>
                <b>{name}</b>
                {ingredient_description !== '' ? <div>{ingredient_description}</div> : null}
            </Cell>
            <Cell className='cellBorders' style={{ flexGrow: '0', width: '200px', minWidth: '200px', padding: '10px' }}>
                {amount} {size} {scale}
            </Cell>
            <Cell style={{ flexGrow: '0', width: '150px', minWidth: '150px', textAlign: 'right', padding: '10px' }}>

            </Cell>
        </Row>,
        childContent
    ]
}
