const PREFIX = 'SESSION_';
const SET_SESSION_TOKEN = `${PREFIX}CLEAR_SESSION_TOKEN`;
const CLEAR_SESSION_TOKEN = `${PREFIX}SET_RESULTS_PER_PAGE`;


export const setSessionToken = (token, userType, userUuid) => {
    return { type: SET_SESSION_TOKEN, token: token, userType: userType, userUuid: userUuid }
}

export const clearSessionToken = () => {
    return { type: CLEAR_SESSION_TOKEN }
}

const initialState = { token: '', userType: '', userUuid: '' };

export const session = (state = initialState, action) => {
    switch (action.type) {
        case SET_SESSION_TOKEN:
            return { token: action.token, userType: action.userType, userUuid: action.userUuid };

        case CLEAR_SESSION_TOKEN:
            return initialState;

        default:
            return state
    }
}