import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'


export default function ImageModal(props) {
    const { images, imageIndex } = props;

    if (imageIndex === undefined || images.length === 0) {
        return null;
    }

    return <Dialog open={true} onClose={props.onClose} aria-labelledby="form-dialog-title"
        maxWidth='xl'
        PaperProps={{
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                maxHeight: '100vh'
            }
        }}>
        <Carousel autoPlay={false} navButtonsAlwaysVisible={true} navButtonsAlwaysInvisible={images.length === 1} animation="slide" strictIndexing={false} index={imageIndex}>
            {images.map((image, i) => <Paper key={i}>
                <img src={image.path} style={{ maxHeight: '100vh', display: 'block' }} alt='' />
            </Paper>)}
        </Carousel>
    </Dialog>
}