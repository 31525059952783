import React from "react";

import Hidden from "@material-ui/core/Hidden";

import LayoutMobile from './Mobile';
import LayoutDesktop from './Desktop';

export default function FoodLogOtherForm(props) {
    return <>
        <Hidden smDown key='desktop'>
            <LayoutDesktop {...props} />
        </Hidden>
        <Hidden mdUp key='mobile'>
            <LayoutMobile {...props} />
        </Hidden>
    </>
}