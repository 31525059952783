import React from "react";
import Box from '@material-ui/core/Box';

import css from "./Dialogs/styles.module.css"

import CardButton from 'sfl-components/Card/CardButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faListAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Dialog from 'sfl-components/Dialog';

import { FormattedMessage } from 'react-intl';



export default function TypeSelect(props) {
    const { open, onSelect, onClose } = props;

    const types = [
        {
            key: 'mealPlan',
            name: <FormattedMessage id="app.mealPlan" defaultMessage="Meal Plan" />,
            icon: <FontAwesomeIcon style={{ color: '#6e3076' }} icon={faListAlt} fixedWidth />,
            description: <FormattedMessage id="foodLog.mealPlanDescription" defaultMessage="Following your meal plan? Pick  this option to select foods & recipes from your meal plan." />
        },
        {
            key: 'pastLog',
            name: <FormattedMessage id="app.pastFoodLog" defaultMessage="Past Food Logs" />,
            icon: <FontAwesomeIcon style={{ color: '#6e3076' }} icon={faCalendarAlt} fixedWidth />,
            description: <FormattedMessage id="foodLog.pastFoodLogDescription" defaultMessage="Did you eat the same thing as before? Pick this option to browse entries youv'e added in past food logs." />
        },
        {
            key: 'other',
            name: <FormattedMessage id="app.other" defaultMessage="Other" />,
            icon: <FontAwesomeIcon style={{ color: '#6e3076' }} icon={faPlusCircle} fixedWidth />,
            description: <FormattedMessage id="foodLog.otherDescription" defaultMessage="Did you eat something not on your meal plan? Pick other to enter your own foods." />
        }
    ];

    const handleSelectClick = (option) => {
        if (onSelect) {
            onSelect(option);
        }
    }

    if (!open) {
        return null;
    }

    return <Dialog onClose={onClose} buttons={<CardButton icon='backspace' onClick={onClose}><FormattedMessage id="app.cancel" defaultMessage="Cancel" /></CardButton>}>
        {types.map(type => <Box key={type.key} display="flex" p={1} className={css.row} onClick={handleSelectClick.bind(this, type.key)} style={{ marginBottom: '30px' }}>
            <div className={css.circle} >
                {type.icon}
            </div>
            <Box width="100%" style={{ margin: '10px 0px 0px 10px' }}>
                <div style={{ fontSize: '32px', fontWeight: '500', color: '#6e3076' }}>{type.name}</div>
                <p>{type.description}</p>
            </Box>
        </Box>)}
    </Dialog>
}