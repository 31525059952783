import React, { useState } from "react";

import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';




export default function OtherInput(props) {
    const value = props.value;
    const helperText = props.helperText;
    const [textMode, setTextMode] = useState(props.textMode);
    const [init, setInit] = useState(true);
    const isTextMode = init ? props.textMode : textMode


    const handleTextChange = (event) => {
        setInit(false)
        props.onChange(event.target.value);
    };

    const handleDropdownChange = (event) => {
        if (event.target.value === 'other') {
            toggleTextMode();
        } else {
            handleTextChange(event);
        }

    };

    const toggleTextMode = () => {
        setInit(false)
        props.onChange('');
        setTextMode(!isTextMode);
    };

    const preventDefault = (event) => {
        event.preventDefault();
    }

    if (isTextMode) {
        return (<FormControl fullWidth variant="filled">
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <OutlinedInput
                autoFocus={!init}
                id={props.id}
                variant="filled"
                type='text'
                value={value}
                onChange={handleTextChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleTextMode}
                            onMouseDown={preventDefault}
                        >
                            <Close />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText id="filled-weight-helper-text">{helperText}</FormHelperText>
        </FormControl>);
    }

    return (<TextField
        id={props.id}
        label={props.label}
        select
        value={value || ''}
        helperText={helperText}
        fullWidth
        variant="outlined"
        onChange={handleDropdownChange}
    >
        {props.children}
        <MenuItem key='other' value='other'><FormattedMessage
            id='app.other'
            defaultMessage="Other"
        />...</MenuItem>
    </TextField>);
}