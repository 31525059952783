import React from "react";
import Box from '@material-ui/core/Box';

export default function CardContainer(props) {
    
    return <Box display="flex" style={{ flexDirection: 'column', backgroundColor: '#f3f0d0', border: '5px solid #f3f0d0', borderRadius: '15px', marginBottom: '30px', padding: '15px', ...props.style }}>
        {props.children}
    </Box>

    /*
    return <Box display="flex" style={{ flexWrap: 'wrap', backgroundColor: '#f3f0d0', border: '5px solid #f3f0d0', borderRadius: '15px', marginBottom: '30px', padding: '15px', ...props.style }}>
        {props.children}
    </Box>
    */
}