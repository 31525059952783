import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

export default function MobileNavbar(props) {
    return <AppBar position="fixed" style={{ height: '60px', backgroundColor: 'black', ...props.style }}>
        <Toolbar style={{ marginRight: '0px', paddingLeft: '0', paddingRight: '0' }}>
            {props.children}
        </Toolbar>
    </AppBar>
}