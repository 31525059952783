import React from "react";

import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';

import Question from './Question';
import { FormattedMessage, useIntl } from 'react-intl';


const QuestionnaireHabits = props => {
    const {
        handleChange,
        exercise_level = '',
        restaurant_frequency = '',
        cook_frequency = ''
    } = props
    const intl = useIntl();


    return <Card key='habits' title={intl.formatMessage({ id: "questionnaire.whatHabitsDoYouHave", defaultMessage: "What habits do you have?" })} titleMode="inner" style={{ marginBottom: '75px' }}>
        <Grid container spacing={3} style={{ padding: '0 20px' }}>
            <Grid item xs={12} >
                <Grid container spacing={3}>
                    <Question
                        id="exercise_level"
                        label={intl.formatMessage({ id: "questionnaire.howMuchDoYouExercise", defaultMessage: "How many hours per week do you exercise?" })}
                        helperText=" "
                        select
                        value={exercise_level}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='none' value='none'><FormattedMessage id="questionnaire.exerciseLevel.none" defaultMessage="I don't exercise" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='low' value='low'><FormattedMessage id="questionnaire.exerciseLevel.low" defaultMessage="Under 2 hours" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='medium' value='medium'><FormattedMessage id="questionnaire.exerciseLevel.medium" defaultMessage="Between 2 to 5 hours" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='high' value='high'><FormattedMessage id="questionnaire.exerciseLevel.high" defaultMessage="More than 5 hours" /></MenuItem>
                    </Question>
                    <Question
                        id="restaurant_frequency"
                        label={intl.formatMessage({ id: "questionnaire.restaurantFrequency", defaultMessage: "On average, how often do you eat meals from restaurant?" })}
                        helperText={intl.formatMessage({ id: "questionnaire.restaurantFrequency.helper", defaultMessage: "This includes dine in, take out, etc." })}
                        select
                        value={restaurant_frequency}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'><FormattedMessage id="questionnaire.oneToFivePerWeek" defaultMessage="1-5 times per week" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'><FormattedMessage id="questionnaire.sixToTenPerWeek" defaultMessage="6-10 times per week" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='11' value='11'><FormattedMessage id="questionnaire.elevenOrMorePerWeek" defaultMessage="11+ times per week" /></MenuItem>
                    </Question>
                    <Question
                        id="cook_frequency"
                        label={intl.formatMessage({ id: "questionnaire.cookFrequency", defaultMessage: "On average, how often do you prepare meals at home?" })}
                        select
                        value={cook_frequency}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'><FormattedMessage id="questionnaire.oneToFivePerWeek" defaultMessage="1-5 times per week" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'><FormattedMessage id="questionnaire.sixToTenPerWeek" defaultMessage="6-10 times per week" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='11' value='11'><FormattedMessage id="questionnaire.elevenOrMorePerWeek" defaultMessage="11+ times per week" /></MenuItem>
                    </Question>
                </Grid>
            </Grid>
        </Grid>
    </Card>
}


export default React.memo(QuestionnaireHabits);