import React from "react";

export default function SectionTitle(props) {
    const { title, description, onClick } = props;

    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
    }

    return <div onClick={handleClick} style={{
        display: 'flex',
        backgroundColor: '#6e3076',
        padding: '15px 10px', color: 'white', marginTop: '20px', borderRadius: '15px 15px 0 0', ...props.style
    }}>
        <div style={{ display: 'flex', flexGrow: '1', flexDirection: 'column', justifyContent: 'center' }} >
            <span style={{ fontSize: '24px' }}>{title}</span>
            {description !== '' && <div>{description}</div>}
        </div>
        {props.children}
    </div>
}
