import React, { useState, useEffect } from 'react';
import CheckboxDialogList from 'sfl-components/CheckboxDialog/List';
import { useSFLApi } from 'api';

export default function CheckboxesTags(props) {
    const [options, setOptions] = useState([]);
    const { get } = useSFLApi();

    useEffect(() => {
        get(`medicalConditions?fields=uuid,name,description&status=active&sort=name`)
            .then(results => {
                setOptions(results.medicalConditions)
            })
    }, [get]);

    return <CheckboxDialogList
        options={options}
        {...props}
    />
}