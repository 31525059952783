
import React from "react";
import TextField from '@material-ui/core/TextField';

export default function SFLTextField(props) {
    const { children, id, onChange, ...rest } = props;

    const handleChange = event =>{
        onChange(id, event.target.value);
    }

    return <TextField fullWidth variant="filled"  name={id} onChange={handleChange} {...rest}>{children}</TextField>
}