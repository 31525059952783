import React from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import imagePlaceholder from "assets/images/recipe/placeholder.jpg";
import Button from '@material-ui/core/Button';

import notFoundImage from 'assets/images/plate-empty-h.jpg';
import { FormattedMessage } from 'react-intl';


export default function RecipeList(props) {
    const { data, hasMore, loadMore, searchValue, updateFavorite } = props;


    if (data.length === 0) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${notFoundImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'center',
            height: '700px',
            textAlign: 'center',
            fontSize: '36px',
            fontWeight: 'bold',
            color: '#ea7530'
        }}>
            <div
                style={{
                    lineHeight: '3rem',
                    fontSize: '48px',
                    marginRight: '70px',
                    marginBottom: '40px'
                }}
            >
                <FormattedMessage id="recipes.noResults" defaultMessage="Sorry, Your search came up empty." />
            </div>
        </div >
    }

    if (data.length === 0) {
        return <div style={{ textAlign: 'center', fontSize: '36px', fontWeight: 'bold' }}>
            <div style={{ margin: '40px' }}><FormattedMessage id="recipes.noResultsFoundForTerm" defaultMessage="No results found for" /> "{searchValue}".</div>
            <img src={notFoundImage} style={{ width: '50%' }} alt='' />
        </div>
    }

    return <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {data.map(recipe => {
            const imagePath = recipe.images.length > 0 ? recipe.images[0].path : imagePlaceholder
            return <Grid key={recipe.uuid} item xs={4} lg={4} xl={3} style={{ paddingRight: '20px', paddingBottom: '20px' }}>
                <Paper elevation={3} style={{ height: '100%' }}>
                    <NavLink to={`/recipe/${recipe.uuid}`}>
                        <div style={{ backgroundImage: `url(${imagePath}?width=400)`, backgroundPosition: 'center', backgroundSize: 'cover', height: '250px' }} />
                    </NavLink>
                    <div style={{ display: 'flex', }}>
                        <NavLink to={`/recipe/${recipe.uuid}`} style={{ flexGrow: 1 }}>

                            <div style={{ color: '#444', fontSize: '20px', padding: '20px 20px 20px 20px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{recipe.name}</div>
                                <div style={{ paddingTop: '10px', fontSize: '16px', fontStyle: 'italic' }}>
                                    <div><FormattedMessage
                                        id="app.MakesXServings"
                                        defaultMessage="Makes {recipe_servings, plural, one {# serving} other {# servings}}"
                                        values={{ recipe_servings: recipe.servings }}
                                    /></div>
                                </div>
                            </div>
                        </NavLink>
                        {updateFavorite && <div style={{ padding: '25px 20px 40px 20px', cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                updateFavorite(recipe.uuid, !recipe.favorite)
                            }}
                        >
                            <FontAwesomeIcon style={{ fontSize: '30px', color: recipe.favorite === true ? '#57026f' : '#dddddd' }} icon={['fas', 'heart']} />
                        </div>}
                    </div>
                </Paper>
            </Grid >
        })}
        {
            hasMore && <Grid key='loadMore' item xs={12} style={{ textAlign: 'center' }}>
                <Button variant="outlined" color="primary" onClick={loadMore}>
                    <FormattedMessage id="recipes.loadMoreResults" defaultMessage="Load More Results" />
                </Button>
            </Grid>
        }
    </div >

}