import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";
import Cycle from 'sfl-components/Cycle';
import Paper from "@material-ui/core/Paper";

import WeightPanel from './mobile/weight/Panel'
import BloodPressurePanel from './mobile/bloodPressure/Panel'
import SleepPanel from './mobile/sleep/Panel'
import RestingHeartRatePanel from './mobile/restingHeartRate/Panel'
import BreathsPerMinutePanel from './mobile/breathsPerMinute/Panel'


export default function MobileProgressTracker() {
    const [tabId, setTabId] = useState(0)
    const options = [
        <FormattedMessage key="app.weight" id="app.weight" />,
        <FormattedMessage key="app.bloodPressure" id="app.bloodPressure" />,
        <FormattedMessage key="app.sleep" id="app.sleep" />,
        <FormattedMessage key="app.restingHeartRate" id="app.restingHeartRate" />,
        <FormattedMessage key="app.breathsPerMinute" id="app.breathsPerMinute" />
    ]


    return [
        <MobileNavbar key='MobileNavbar'>
            <SidebarToggle />
            <NavBarTitle><FormattedMessage id="app.progressTracker" defaultMessage="Progress Tracker" /></NavBarTitle>
        </MobileNavbar>,
        <Paper key='Cycle' elevation={3} style={{ padding: '5px' }}>
            <Cycle options={options} value={tabId} title={<FormattedMessage id="app.measurementOptions" defaultMessage="Measurement Options" />} onChange={setTabId} />
        </Paper>,
        tabId === 0 && <WeightPanel key='WeightPanel' />,
        tabId === 1 && <BloodPressurePanel key='BloodPressurePanel' />,
        tabId === 2 && <SleepPanel key='SleepPanel' />,
        tabId === 3 && <RestingHeartRatePanel key='RestingHeartRatePanel' />,
        tabId === 4 && <BreathsPerMinutePanel key='BreathsPerMinutePanel' />
    ]
}