import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from 'react-redux'

//export const host = "http://localhost:3000";

//export const host = "http://192.168.177.1:3000";
//export const host = "http://sfl-api.ithmic.com";
export const host = "https://app-api.simplyforlife.com";


export const useApi = (urlParam, options = {}) => {
	const [url, setUrl] = useState(urlParam)
	const { token } = useSelector(state => state.session);
	const [hasError, setHasError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({});
	const { language = 'en' } = useSelector(state => state.appSettings);
	const { language: selectedLanguage } = options

	const fetchData = useCallback(() => {
		setHasError(false);
		setIsLoading(true);

		if (!url) {
			setData({})
			setIsLoading(false);
			return
		}

		get(token, selectedLanguage || language, url)
			.then(response => {
				setData(response);
				setIsLoading(false);
			})
			.catch(() => {
				setHasError(true);
			});
	}, [token, language, url]);

	useEffect(() => {
		fetchData();
	}, [fetchData, token, url]);



	return {
		hasError,
		isLoading,
		data,
		refresh: fetchData,
		setUrl: setUrl
	}
}


export const useSFLApi = () => {
	const { token } = useSelector(state => state.session);
	const { language = 'en' } = useSelector(state => state.appSettings);

	return useMemo(() => {
		return {
			get: get.bind(this, token, language),
			del: del.bind(this, token, language),
			post: post.bind(this, token, language),
			download: download.bind(this, token, language),
			upload: upload.bind(this, token, language)
		}
	}, [token, language]);
}

const api = async (method, token, language, path, data) => {
	try {
		let response;
		if (method === 'post') {
			response = await fetch(`${host}/${path}`,
				{
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept-Language': language,
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data)
				});

		} else if (method === 'download') {
			response = await fetch(`${host}/${path}`,
				{
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept-Language': language,
						'Accept': 'application/json',
					},
					body: data
				});

			const blobData = await response.blob()
			return blobData

		} else if (method === 'upload') {
			response = await fetch(`${host}/${path}`,
				{
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept-Language': language,
						'Accept': 'application/json',
					},
					body: data
				});

		} else if (method === 'delete') {
			response = await fetch(`${host}/${path}`,
				{
					method: 'DELETE',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept-Language': language,
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				});

		} else {
			response = await fetch(`${host}/${path}`,
				{
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept-Language': language,
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				});
		}

		const json = await response.json();

		if (json.status === 'success') {
			return json.data;
		} else {
			throw Error('error');
		}
	}
	catch (error) {
		throw Error(error)
	}
}

const get = (token, language, path) => {
	return api('get', token, language, path, {});
}

const post = (token, language, path, data) => {
	return api('post', token, language, path, data);
}

const download = (token, language, path, data) => {
	return api('download', token, language, path, data);
}

const upload = (token, language, path, data) => {
	return api('upload', token, language, path, data);
}

const del = (token, language, path, data) => {
	return api('delete', token, language, path, data);
}
