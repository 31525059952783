import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSFLApi } from 'api';
import MobileForm from "./MobileForm";

import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";
import logo from "assets/images/logo-with-tagline.png";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import bowlImg from 'assets/images/bowls/Bal_Leaves_circle_dishes_400px.png';

import { FormattedMessage } from 'react-intl';

export default function PasswordResetForm() {
    const history = useHistory();
    const [tokenStatus, setTokenStatus] = useState('pending');
    const { tokenUuid } = useParams();
    const { get } = useSFLApi();

    const fetchData = React.useCallback(async () => {
        get(`tokens/${tokenUuid}`)
            .then(data => {
                if (data.token.status === 'active' && data.token.type === 'questionnaire') {
                    setTokenStatus('active')
                } else {
                    setTokenStatus(data.token.status)
                }
            })
            .catch(error => {
                console.log(error);
                setTokenStatus('error')
            });
    }, [get, tokenUuid]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);



    let message = 'Validating'
    if (tokenStatus === 'error') {
        message = <FormattedMessage id="questionnaire.tokenError" defaultMessage='An error has occured while validating your request.' />
    }
    if (tokenStatus === 'inactive') {
        message = <FormattedMessage id="questionnaire.tokenInactive" defaultMessage="This token is no longer valid." />
    }
    if (tokenStatus === 'complete') {
        message = <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '24px', marginTop: '50px', marginBottom: '40px' }}><i>
                <FormattedMessage id="questionnaire.thanksYouRock" defaultMessage="Thanks, you rock!" />
            </i></div>
            <div style={{ padding: '0 80px' }}>
                <img src={bowlImg} alt='' style={{ maxWidth: '300px', width: '100%' }} />
            </div>
        </div>
    }
    if (tokenStatus === 'active') {
        message = <MobileForm onSave={fetchData} />;
    }


    return [
        <MobileNavbar key='MobileNavbar' style={{ backgroundColor: 'white', color: 'black', boxShadow: 'none' }}>
            <NavBarButton onClick={() => history.push('/')}>
                <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
            </NavBarButton>
            <NavBarTitle>
                <FormattedMessage id="app.questionnaire" defaultMessage="Questionnaire" />
            </NavBarTitle>
        </MobileNavbar>,
        <div key='content' style={{ padding: '80px 30px 10px 30px' }}>
            <img src={logo} alt='Simply For Life' style={{ width: '100%' }} />
        </div>,
        message
    ]
}
