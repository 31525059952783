import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useIntl } from 'react-intl';



export default function EntryDialog(props) {
    const { entry, onClose } = props;
    const { cartToggle, favoriteToggle, childrenToggle } = props.actions;
    const history = useHistory();
    const intl = useIntl();


    return <Dialog onClose={() => { onClose() }} aria-labelledby="simple-dialog-title" open={true}>
        <DialogTitle id="simple-dialog-title">{entry.name}</DialogTitle>
        <List>
            {entry.type === 'recipe' && <ListItem button onClick={() => { childrenToggle(entry.uuid); onClose(); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}><FontAwesomeIcon icon={['fas', 'indent']} /></ListItemIcon>
                <ListItemText primary={entry.displayChildren ? intl.formatMessage({ id: "app.hideIngredients", defaultMessage: "Hide Ingredients" }) : intl.formatMessage({ id: "app.showIngredients", defaultMessage: "Show Ingredients" })} style={{ padding: '0px 20px' }} />
            </ListItem>}
            {(entry.type === 'recipe' && entry.recipe_uuid) && <ListItem button onClick={() => {
                history.push(`/recipe/${entry.recipe_uuid}`);
                onClose();
            }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}><FontAwesomeIcon icon={['fas', 'newspaper']} /></ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "app.viewRecipe", defaultMessage: "View Recipe" })} style={{ padding: '0px 20px' }} />
            </ListItem>}
            {entry.shopping_list
                ? <ListItem button onClick={() => { cartToggle(entry.uuid) }}>
                    <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                        <FontAwesomeIcon icon={['fas', 'shopping-cart']} style={{ color: 'green' }} />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "app.groceryList", defaultMessage: "Grocery List" })} style={{ padding: '0px 20px' }} />
                </ListItem>
                : <ListItem button onClick={() => { cartToggle(entry.uuid) }}>
                    <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                        <FontAwesomeIcon icon={['fas', 'shopping-cart']} />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "app.groceryList", defaultMessage: "Grocery List" })} style={{ padding: '0px 20px' }} />
                </ListItem>
            }
            {entry.favorite
                ? <ListItem button onClick={() => { favoriteToggle(entry.uuid) }}>
                    <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                        <FontAwesomeIcon icon={['fas', 'heart']} style={{ color: 'red' }} />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "app.favorite", defaultMessage: "Favorite" })} style={{ padding: '0px 20px' }} />
                </ListItem>
                : <ListItem button onClick={() => { favoriteToggle(entry.uuid) }}>
                    <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                        <FontAwesomeIcon icon={['fas', 'heart']} />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "app.favorite", defaultMessage: "Favorite" })} style={{ padding: '0px 20px' }} />
                </ListItem>
            }
            {/*<ListItem button onClick={() => { onClose(); helpToggle(); }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}><FontAwesomeIcon icon={['fas', 'question-circle']} style={{ color: 'blue' }} /></ListItemIcon>
                <ListItemText primary="Learn More" style={{ padding: '0px 20px' }} />
            </ListItem>*/}
        </List>
    </Dialog>
}