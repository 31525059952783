import React from 'react';
import { ConfirmationDialog } from 'sfl-components/Dialog/ConfirmationDialog'

export const ConfirmationDialogStateContext = React.createContext(null);

export default function ConfirmationDialogStateProvider({ children }) {
	const [dialogState, setDialogState] = React.useState({ open: false });
	const { onSubmit, onClose } = dialogState;

	const handleSubmit = () => {
		if (onSubmit) {
			onSubmit();
		}
		setDialogState({ open: false });
	}

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
		setDialogState({ open: false });
	}

	return <ConfirmationDialogStateContext.Provider value={{ openDialog: setDialogState }}>
		{children}
		<ConfirmationDialog
			open={dialogState.open}
			title={dialogState.title}
			description={dialogState.description}
			type={dialogState.type}
			cancelText={dialogState.cancelText}
			submitText={dialogState.submitText}
			onSubmit={handleSubmit}
			onClose={handleClose}
		></ConfirmationDialog>
	</ConfirmationDialogStateContext.Provider>
}