import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Redirect } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

// core components
import Member from "layouts/Member.js";
import { useSFLApi } from 'api';
import { clearSessionToken } from 'store/session'

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ConfirmationDialogStateProvider from "sfl-components/Dialog/ConfirmationDialogStateProvider"



import SignIn from 'views-public/SignIn';
import ForgotLogin from 'views-public/ForgotLogin';
import ResetPassword from 'views-public/ResetPassword';
import TermsOfUse from 'views-public/TermsOfUse';
import PrivacyPolicy from 'views-public/PrivacyPolicy';
import SessionExpired from 'views-public/SessionExpired';
import QuestionnaireSignup from 'views-public/QuestionnaireSignup';


const Admin = React.lazy(() => import("layouts/Admin.js"));
const Consultant = React.lazy(() => import("layouts/Consultant.js"));

const theme = createTheme({
    palette: {
        primary: { main: "#6b3374", contrastText: "#fff" },
        secondary: { main: "#03a9f4", contrastText: "#fff" }
    },
    overrides: {
        // Style sheet name ⚛️
        MuiFilledInput: {
            // Name of the rule
            root: {
                // Some CSS
                backgroundColor: 'white',
            },
        },
    },
})

const sharedRoutes = [
    <Route
        exact
        path='/sign-in'
        component={SignIn}
        key='signIn'
    />,
    <Route
        exact
        path='/forgot-login'
        component={ForgotLogin}
        key='forgotLogin'
    />,
    <Route
        path='/reset-password/:passwordResetToken'
        component={ResetPassword}
        key='resetPassword'
    />,
    <Route
        exact
        path='/terms-of-use'
        component={TermsOfUse}
        key='termsOfUse'
    />,
    <Route
        exact
        path='/privacy-policy'
        component={PrivacyPolicy}
        key='privacyPolicy'
    />,
    <Route
        exact
        path='/session-expired'
        component={SessionExpired}
        key='sessionExpired'
    />,
    <Route
        exact
        path='/questionnaire-signup/:tokenUuid'
        component={QuestionnaireSignup}
        key='QuestionnaireSignup'
    />
]

const RootLayout = () => {
    const { token, userType } = useSelector(state => state.session);
    const [tokenValidated, setTokenValidated] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { get } = useSFLApi();

    useEffect(() => {
        if (token === '') {
            return;
        }
        get(`sessions/${token}`)
            .then(data => {
                if (data.session.status === 'active') {
                    setTokenValidated(true);
                } else {
                    dispatch(clearSessionToken());
                    history.push('/session-expired');
                }
            })
            .catch(error => {
                //TO-DO - detect cancelled
                dispatch(clearSessionToken());
                history.push('/session-expired');
                console.log(error)
            })
    }, [token, get, dispatch, history])


    if (token === '') {
        return <ThemeProvider theme={theme}>
            {sharedRoutes}
            <Redirect from="/" to="/sign-in" />
        </ThemeProvider>

    } else if (tokenValidated) {
        return <Switch>
            {sharedRoutes}
            <Route>
                <ThemeProvider theme={theme}>
                    <ConfirmationDialogStateProvider>
                        <Suspense fallback={null}>
                            {userType === 'admin' && <Admin />}
                            {userType === 'consultant' && <Consultant />}
                            {userType === 'member' && <Member />}
                        </Suspense>
                    </ConfirmationDialogStateProvider>
                </ThemeProvider>
            </Route>
        </Switch>
    } else {
        return <div />
    }
}

export default RootLayout;