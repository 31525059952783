import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        viewBox="0 0 410 410"
        width={size}
    >
        <path fill={color} d="M410.916,375.424v22.413H0V13.079h22.413v362.35h388.502V375.424z M70.647,188.514c3.166,0,6.168-0.651,8.981-1.683
		l41.516,65.002c-5.762,5.452-9.397,13.116-9.397,21.669c0,16.499,13.382,29.878,29.883,29.878
		c16.502,0,29.882-13.379,29.882-29.878c0-4.58-1.109-8.862-2.946-12.743l54.505-39.837c4.249,3.623,9.675,5.883,15.684,5.883
		c6.645,0,12.658-2.683,17.039-7.004l55.749,34.866c-2.854,4.969-4.507,10.715-4.507,16.859c0,18.775,15.212,33.987,33.979,33.987
		c18.764,0,33.977-15.212,33.977-33.987c0-18.774-15.213-33.986-33.977-33.986c-8.545,0-16.339,3.18-22.31,8.395l-57.043-35.689
		c0.821-2.439,1.368-5.006,1.368-7.723c0-13.408-10.869-24.278-24.275-24.278c-13.413,0-24.285,10.87-24.285,24.278
		c0,3.341,0.679,6.521,1.906,9.42l-54.418,39.76c-5.337-4.979-12.448-8.088-20.323-8.088c-3.91,0-7.625,0.804-11.052,2.172
		l-41.457-64.911c4.747-4.733,7.683-11.278,7.683-18.506c0-14.446-11.705-26.151-26.151-26.151c-14.442,0-26.15,11.705-26.15,26.151
		C44.506,176.815,56.206,188.514,70.647,188.514z"/>
    </svg>
}

export default SvgComponent