import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useApi } from 'api';
import { setPage, setResultsPerPage, setDataLength } from 'store/memberMealPlans';
import Error from 'sfl-components/Error';
import CardButton from 'sfl-components/SFLCards/CardButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";

import Card from 'sfl-components/SFLCards/Card';

import Table from "./Table.js";

export default function MemberMealPlansList() {
    const { resultsPerPage, offset, currentPage, totalPages } = useSelector(state => state.mealPlans);


    const { hasError, data, refresh } = useApi(`mealPlans?status=active&sort=start_date:desc,name:asc`);
    const { mealPlans = [] } = data;
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setDataLength(mealPlans ? mealPlans.length : 0));
    }, [mealPlans, dispatch]);


    if (hasError) {
        return [
            <MobileNavbar key='MobileNavbar'>
                <SidebarToggle />
                <NavBarTitle>Meal Plan</NavBarTitle>
            </MobileNavbar>,
            <Error key='Error' title="Error!" description="A error has occured while retreiving your meal plans. Please contact your consultant for assistance.">
                <CardButton key="refresh" onClick={refresh}>
                    <FontAwesomeIcon icon={['fas', 'sync']} />
                    <div>Try Again</div>
                </CardButton>
            </Error>
        ]
    }

    const tableData = mealPlans ? mealPlans.slice(offset, offset + resultsPerPage) : [];

    return <Card title="Past Meal Plans" titleMode="outer">
        <Table tableData={tableData}
            refresh={refresh}
            pagination={{
                currentPage: currentPage,
                totalPages: totalPages,
                firstPage: () => { dispatch(setPage('first')) },
                previousPage: () => { dispatch(setPage('previous')) },
                nextPage: () => { dispatch(setPage('next')) },
                lastPage: () => { dispatch(setPage('last')) },
                setResultsPerPage: (resultsPerPage) => { dispatch(setResultsPerPage(resultsPerPage)) }
            }}
        />
    </Card>
}