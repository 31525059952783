import React from "react";

import Hidden from "@material-ui/core/Hidden";

import MealPlanMobile from './MealPlan.mobile';
import MealPlanDesktop from './MealPlan.desktop';

export default function RecipeDetails() {


	return <>
		<Hidden smDown>
			<MealPlanDesktop />
		</Hidden>
		<Hidden mdUp>
			<MealPlanMobile />
		</Hidden>
	</>
}