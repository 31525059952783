import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import { ConfirmationDialogStateContext } from "sfl-components/Dialog/ConfirmationDialogStateProvider";
import DateDisplay from 'sfl-components/DateDisplay';
import moment from 'moment';
import { useSFLApi } from 'api';

import Dialog from './Dialog';
import Table from '../Table';
import { Summary, SummaryPanel, ChartPanel } from '../Summary';


export default function BloodPressurePanel() {
    const { userUuid } = useSelector(state => state.session);
    const { memberUuid = userUuid } = useParams();
    const [records, setRecords] = useState([])
    const { get, del } = useSFLApi();
    const [editSettings, setEditSettings] = useState()
    const [timeframeId, setTimeframeId] = useState('3')
    const intl = useIntl();
    const { openDialog } = React.useContext(ConfirmationDialogStateContext);
    const startDate = moment().subtract(timeframeId, 'months').format('YYYY-MM-01')

    const loadData = async () => {
        try {
            const results = await get(`measurements/bloodPressure?date_start=${startDate}&member_uuid=${memberUuid}`)
            setRecords(results.bloodPressure)
        }
        catch (error) {
            console.log(error);
            setRecords([])
        }
    }

    const deleteEntry = async (entryUuid) => {
        try {
            await del(`measurements/bloodPressure/${entryUuid}`);
            toast.success(intl.formatMessage({ id: "app.entryDeleted", defaultMessage: 'Entry Deleted' }));
            loadData();
        }
        catch (error) {
            console.log(error);
            toast.error(intl.formatMessage({ id: "app.deleteFail", defaultMessage: 'Deletion Failed.' }));
        }
    }

    const handleDeleteClick = (uuid) => {
        openDialog({
            open: true,
            title: intl.formatMessage({ id: "app.deleteConfirmation", defaultMessage: 'Delete Confirmation' }),
            description: intl.formatMessage({ id: "app.deleteConfirmationMessage", defaultMessage: 'Are you sure you want to delete this entry?' }),
            onSubmit: () => { deleteEntry(uuid) }
        });
    }

    useEffect(() => {
        loadData()
    }, [timeframeId])


    const series = [{
        name: intl.formatMessage({ id: "app.systolic", defaultMessage: 'Systolic' }),
        data: records.map(e => ({
            x: new Date(e.date),
            y: e.upper_value
        }))
    },
    {
        name: intl.formatMessage({ id: "app.diastolic", defaultMessage: 'Diastolic' }),
        data: records.map(e => ({
            x: new Date(e.date),
            y: e.lower_value
        }))
    }]

    return [
        <Dialog key='editDialog' open={editSettings ? true : false} {...editSettings} onClose={() => { loadData(); setEditSettings() }} />,
        <Summary key='summary'>
            <SummaryPanel
                isEmpty={records.length === 0 ? true : false}
                date={records[0]?.date}
                onEdit={() => { setEditSettings({}) }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <span style={{ fontSize: '72px', fontWeight: 'bold' }}>{records[0]?.upper_value}</span><br />
                        {intl.formatMessage({ id: "app.systolic", defaultMessage: 'Systolic' })}
                    </div>
                    <div style={{ fontSize: '72px', fontWeight: 'bold', padding: '0px 20px' }}>
                        /
                    </div>
                    <div>
                        <span style={{ fontSize: '72px', fontWeight: 'bold' }}>{records[0]?.lower_value}</span><br />
                        {intl.formatMessage({ id: "app.diastolic", defaultMessage: 'Diastolic' })}
                    </div>
                </div>
            </SummaryPanel>
            <ChartPanel
                timeframeId={timeframeId}
                onTimeframeSelect={setTimeframeId}
                series={series}
            />
        </Summary>,
        <Table
            key='results'
            columns={[
                { value: 'Date' },
                { value: 'Measurement' },
                { value: '', style: { width: '100px', textAlign: 'center' } },
            ]}
            rows={records.map(e => {
                return [{ value: <DateDisplay date={e.date} /> },
                { value: `${e.upper_value} / ${e.lower_value}` },
                {
                    value: <>
                        <IconButton style={{ marginRight: '10px', width: '30px', height: '30px', fontSize: '15px', borderRadius: '25px', color: 'white', backgroundColor: '#6b3374' }} onClick={() => { setEditSettings({ uuid: e.uuid }) }}>
                            <FontAwesomeIcon icon={['fas', 'edit']} fixedWidth />
                        </IconButton>
                        <IconButton style={{ width: '30px', height: '30px', fontSize: '15px', borderRadius: '25px', color: 'white', backgroundColor: '#6b3374' }} onClick={() => { handleDeleteClick(e.uuid) }}>
                            <FontAwesomeIcon icon={['fas', 'times']} fixedWidth />
                        </IconButton>
                    </>,
                    style: { textAlign: 'center' }
                },]
            })}
        />
    ]
}