import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSFLApi } from 'api';
import { Row, Cell } from 'sfl-components/Containers2'
import { toast } from 'react-toastify';
import SearchInput from 'sfl-components/Inputs/SearchInput'
import Title from 'sfl-components/Title';
//import { useHistory } from "react-router-dom";

import CategoryList from './CategoryList'
import RecipeList from './RecipeList'
import { FormattedMessage, useIntl } from 'react-intl';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function RecipesList() {
	const { categoryUuid } = useParams();
	const [isLoading, setIsLoading] = useState(true)
	const [categories, setCategories] = useState([])
	const [recipes, setRecipes] = useState([])
	const [recipeLimit, setRecipeLimit] = useState(100)
	const { get, post } = useSFLApi();
	const history = useHistory();
	const location = useLocation();
	const intl = useIntl();

	const searchParam = new URLSearchParams(location.search).get("search");
	const [searchValue, setSearchValue] = useState(searchParam || '')

	useEffect(() => {
		get(`recipeCategories?status=active&sort=name`)
			.then(results => setCategories(results.recipeCategories))
			.catch(error => {
				console.log(error)
				toast.error(intl.formatMessage({ id: "recipes.error", defaultMessage: 'An error has occured.' }))
			})
	}, [get, intl])


	useEffect(() => {
		let url = `recipes?sort=name&limit=${recipeLimit + 1}`;
		if (categoryUuid) {
			url += `&recipe_categories=${categoryUuid}`;
		}
		if (searchValue) {
			url += `&search=${searchValue}`;
		}


		get(url)
			.then(results => {
				setRecipes(results.recipes)
			})
			.catch(error => {
				console.log(error)
				toast.error(intl.formatMessage({ id: "recipes.error", defaultMessage: 'An error has occured.' }))
			})


		setIsLoading(false)
	}, [get, intl, recipeLimit, categoryUuid, searchValue])


	/*
	useEffect(() => {
		//Set scroll one data loads
		if (history.location.state?.scroll) {
			console.log('set scroll')
			setScroll(history.location.state.scroll)
		}
	}, [setScroll, recipes])

	if (history.location.state?.scroll) {
		console.log('set scroll')
		setScroll(history.location.state.scroll)
	}
*/

	const handleToggleFavorite = async (recipeUuid, favoriteStatus) => {
		try {
			const result = await post(`recipes/${recipeUuid}/favorite`, {
				favorite: favoriteStatus
			})

			setRecipes(currentRecipes => {
				const index = currentRecipes.findIndex(e => e.uuid === recipeUuid)
				currentRecipes[index].favorite = result.recipe.favorite
				return [...currentRecipes]
			})
		}
		catch (error) {
			console.log('error')
		}
	}


	if (isLoading) {
		return <></>
	}

	const selectedCategory = categories.find(e => e.uuid === categoryUuid);

	if (searchValue || categoryUuid) {
		return [
			<Row key='title'>
				<Title><FormattedMessage id="app.recipes" defaultMessage="Recipes" /></Title>
				<Cell style={{ flexGrow: 0 }}>
					<SearchInput
						title={intl.formatMessage({ id: "app.search", defaultMessage: "Search" })}
						value={searchValue}
						onChange={updatedSearchValue => {
							history.replace(`${location.pathname}?search=${updatedSearchValue}`)
							setSearchValue(updatedSearchValue)
						}}
					/>
				</Cell>
			</Row>,
			<Row key='content' style={{ marginTop: '5px', marginBottom: '40px' }}>
				<BreadCrumbList key='breadCrumbList' style={{ display: 'flex' }}>
					<BreadCrumb key='categories'>
						<FontAwesomeIcon icon={['fas', 'home']} fixedWidth style={{ marginRight: '5px' }} />
						<FormattedMessage id="app.recipeCategories" defaultMessage="Recipe Categories" />
					</BreadCrumb>
					{selectedCategory ? <BreadCrumb key='categoryName'>
						{selectedCategory.name}
					</BreadCrumb> : null}
					{searchValue ? <BreadCrumb key='search'>
						<FontAwesomeIcon icon={['fas', 'search']} fixedWidth style={{ marginRight: '5px' }} />
						<FormattedMessage id="app.search" defaultMessage="Search" />
					</BreadCrumb> : null}
					{searchValue ? <BreadCrumb key='searchTerm'>{searchValue}</BreadCrumb> : null}
				</BreadCrumbList>
			</Row>,
			<RecipeList
				key='recipeList'
				searchValue={searchValue}
				data={recipes.slice(0, recipeLimit)}
				hasMore={recipes.length > recipeLimit}
				updateFavorite={handleToggleFavorite}
				loadMore={() => {
					setRecipeLimit(recipeLimit + 40)
				}}
			/>
		]
	}


	return [
		<Row key='title' style={{ marginBottom: '40px' }}>
			<Title>
				<FormattedMessage id="app.RecipesForAnyPalate" defaultMessage="Recipes for any palate" />
			</Title>
			<Cell style={{ flexGrow: 0 }}>
				<SearchInput
					title={intl.formatMessage({ id: "app.search", defaultMessage: "Search" })}
					value={searchValue}
					onChange={updatedSearchValue => {
						history.replace(`${location.pathname}?search=${updatedSearchValue}`)
						setSearchValue(updatedSearchValue)
					}}
				/>
			</Cell>
		</Row>,
		<CategoryList key='CategoryList' data={categories} />
	]
}



const BreadCrumbList = (props) => {
	return <div style={{ display: 'flex', fontSize: '14px' }}>{props.children.map((bc, index) => {
		if (bc === null) {
			return bc;
		}
		if (index > 0) {
			return [<span key={`padding${index}`} style={{ padding: '0 5px' }}>/</span>, bc]
		}
		return bc
	})}</div>
}

const BreadCrumb = (props) => {
	return <div>{props.children}</div>
}