export const loadState = () => {
	try {
		const serializedSessionState = sessionStorage.getItem('state');
		const serializedLocalState = localStorage.getItem('session');

		let state = {}
		if (serializedSessionState !== null) {
			state = JSON.parse(serializedSessionState)
		}
		if (serializedLocalState !== null) {
			state.session = JSON.parse(serializedLocalState)
		}

		return state;
	} catch (error) {
		return {};
	}
};

export const saveState = (state) => {
	try {
		const { session, ...rest } = state;
		sessionStorage.setItem('state', JSON.stringify(rest));
		localStorage.setItem('session', JSON.stringify(session));
	} catch (error) {
		// Ignore write errors.
	}
};