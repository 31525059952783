import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Error(props) {
    const { title, description, children } = props;
    return <div style={{ width: '100%', minHeight: 'calc(100vh - 120px)', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} style={{ color: '#444444', fontSize: '120px', margin: '20px 0' }} />
        <div style={{ fontSize: '24px' }}>{title}</div>
        <div style={{ padding: '20px 40px' }}>
            {description}
        </div>
        {children}
    </div>
}