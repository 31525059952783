import React from "react";
import { useHistory } from "react-router-dom";
import { useApi } from 'api';
import Error from 'sfl-components/Error';
import CardButton from 'sfl-components/SFLCards/CardButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";

import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";

import BottomNavbar from "../Shared/BottomNavBar";



export default function MemberMealPlansList() {
    const history = useHistory();


    const { hasError, data, refresh } = useApi(`mealPlans?status=active&sort=start_date:desc,name:asc`);
    const { mealPlans = [] } = data;



    if (hasError) {
        return [
            <MobileNavbar key='MobileNavbar'>
                <SidebarToggle />
                <NavBarTitle>Meal Plan</NavBarTitle>
            </MobileNavbar>,
            <BottomNavbar key='BottomNavbar' activeTab={2} />,
            <Error key='error' title="Error!" description="A error has occured while retreiving your meal plans. Please contact your consultant for assistance.">
                <CardButton key="refresh" onClick={refresh}>
                    <FontAwesomeIcon icon={['fas', 'sync']} />
                    <div>Try Again</div>
                </CardButton>
            </Error>
        ]
    }




    let year;
    return [
        <MobileNavbar key='MobileNavbar'>
            <SidebarToggle />
            <NavBarTitle>Meal Plan</NavBarTitle>
        </MobileNavbar>,
        <BottomNavbar key='BottomNavbar' activeTab={2} />,
        mealPlans.map(mealPlan => {
            const entryYear = mealPlan.start_date ? parseInt(mealPlan.start_date.substr(0, 4)) : 0;
            const displayHeader = year !== entryYear;
            year = entryYear;

            return [
                displayHeader && <div key='year' style={{ display: 'flex', backgroundColor: '#6e3076', padding: '10px', borderLeft: '5px solid #6e3076', color: 'white', marginTop: '20px', borderRadius: '15px 15px 0 0' }}>
                    <div style={{ display: 'flex', flexGrow: '1', flexDirection: 'column', justifyContent: 'center' }} >
                        <div style={{ fontSize: '24px' }}>{entryYear > 0 ? entryYear : null}</div>
                    </div>
                </div>,
                <div key='content' onClick={() => history.push(`/meal-plan/${mealPlan.uuid}`)} style={{ display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'white', borderBottom: '1px solid #eeeeee' }}>
                    <div style={{ fontWeight: '400', fontSize: '18px', color: 'black' }}>{mealPlan.name}</div>
                    <div style={{ color: '#888888' }}>{mealPlan.start_date} - {mealPlan.end_date}</div>
                </div>
            ]
        })
    ]

}