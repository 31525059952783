import React from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";



const styles = theme => ({
	/**
	 * Color styles for contained (aka raised) buttons
	 * @see https://github.com/mui-org/material-ui/blob/8995f085904eb55bcb5861fb6d8a32fbd38d72eb/packages/material-ui/src/Button/Button.js#L131-L141
	 */
    containedCyan: {
        color: theme.palette.getContrastText('#0b6666'),
        backgroundColor: '#0b6666',
        "&:hover": {
            backgroundColor: '#095252',
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: '#0b6666'
            }
        }
    },

    containedPurple: {
        color: theme.palette.getContrastText('#6e3076'),
        backgroundColor: '#6e3076',
        "&:hover": {
            backgroundColor: '#632B6A',
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: '#6e3076'
            }
        }
    },

    

    // This is required for the '&$disabled' selector to work
    disabled: {}
});

const ColoredButton = props => {
    const { classes, color, ...other } = props;
    let className = null;
    if (color === 'purple') {
        className = classes.containedPurple;
    }
    if (color === 'cyan') {
        className = classes.containedCyan;
    }

    

    return (<Button {...other} variant="contained" className={className} >
        {props.children}
    </Button>);

}

export default withStyles(styles)(ColoredButton);