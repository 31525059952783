import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

export default function DialogListSelect(props) {
    const { open = false, title = '', selectedUuid, options = [], onSelect, onClose, closeOnSubmit = true } = props;

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }


    return <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} >
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <List>
            {options.map(option => <ListItem key={option.uuid} button onClick={() => {
                if (closeOnSubmit) {
                    handleClose();
                }
                onSelect(option.uuid);
            }} style={{ borderTop: '1px solid #eeeeee', paddingLeft: '30px', paddingRight: '50px' }}>
                <ListItemIcon style={{ justifyContent: 'center', minWidth: '0px' }}>
                    {option.uuid === selectedUuid
                        ? <FontAwesomeIcon icon={['fas', 'dot-circle']} style={{ color: '6e3076' }} />
                        : <FontAwesomeIcon icon={['fas', 'circle']} />}
                </ListItemIcon>
                <ListItemText primary={option.name} secondary={option.start_date ? option.start_date : option.description} style={{ padding: '0px 20px' }} />
            </ListItem>)}
        </List>
        <DialogActions style={{ borderTop: '1px solid #eeeeee' }}>
            <Button onClick={onClose} >
                <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>
        </DialogActions>
    </Dialog>
}