import React from "react";
import ServingSize from 'sfl-components/ServingSize';

export default function Portions(props) {
    const { members = [], portions = [] } = props;

    //If either is empty, there's nothing to show so we return early
    if (portions.length === 0 || members.length === 0) {
        return <div className="portionsList" />;
    }

    //If all portions are equal, no need to display separately by member
    const allEqualPortions = portions.length > 0 && portions.every(val => val.amount === portions[0].amount && val.scale === portions[0].scale);
    if (allEqualPortions) {
        return <div className="portionsList">
            <ServingSize
                amount={portions[0].amount || ''}
                scale={portions[0].scale || ''}
            />
        </div>
    } else {
        return <div className="portionsList">
            <table cellPadding='0' cellSpacing='0' border='0'>
                <tbody className="portionsList">
                    {members.map(member => {
                        const portion = portions.find(e => e.member_uuid === member.uuid) || {}
                        return <tr key={member.uuid}>
                            <td style={{ paddingRight: '10px', fontWeight: 'bold' }}>{member.first_name}:</td>
                            <td>
                                <ServingSize
                                    amount={portion.amount || ''}
                                    scale={portion.scale || ''}
                                />
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    }
}
