import React from 'react';
import { useLocation, useHistory } from "react-router-dom";
import TitleBar from 'sfl-components/TitleBar';
import { ViewportContext } from 'sfl-components/ViewportProvider';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'


export const ScrollContext = React.createContext({});

export default function MainPanel(props) {
    const scrollRef = React.useRef()
    const history = useHistory()
    const { pathname, search, state } = useLocation()
    const { width } = React.useContext(ViewportContext);
    const { scroll = 0 } = state || {}

    if (width < 800) {
        return <div style={{ paddingTop: '60px', minHeight: '100vh', boxSizing: 'border-box', backgroundColor: '#eeeeee', color: '#111111' }}>
            {props.children}
        </div>
    }

    const restoreScroll = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scroll
        }
    }

    const nav = (url) => {
        history.replace(`${pathname}${search}`, { scroll: scrollRef.current.scrollTop })
        history.push(url)
    }


    return <ScrollContext.Provider value={{ restoreScroll: restoreScroll, nav: nav }}>
        <PerfectScrollbar
            containerRef={(ref) => { scrollRef.current = ref; }}
            style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100vh',
                minHeight: '100vh',
                backgroundImage: 'linear-gradient(90deg, #b4bb00, 50%, #e1e566)'
            }}
        >
            <div style={{ flexGrow: '0', marginRight: '50px' }}>
                <TitleBar />
            </div>
            <div style={{
                flexGrow: '1',
                marginRight: '50px',
                padding: '60px 40px 40px 40px',
                backgroundColor: 'white',
                borderRadius: '25px 25px 0 0'
            }}>
                {props.children}
            </div>
        </PerfectScrollbar>
    </ScrollContext.Provider>
}