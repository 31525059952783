import React from "react";
import classNames from "classnames";
import { NavLink, withRouter } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch } from "react-redux";
import { toggleSidebar } from 'store/appSettings';
import { Scrollbars } from 'react-custom-scrollbars';
import css from './styles.module.css'
import './scrollbar.css'

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

function Sidebar(props) {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleDrawerToggle = () => {
        //setMobileOpen(!mobileOpen);
        dispatch(toggleSidebar());
    };


    const classes = useStyles();
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        if (routeName === '/') {
            return false;
        }
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }
    const { logo, routes } = props;
    var links = (
        <List style={{ paddingLeft: '20px', paddingTop: '20px' }}>
            {routes.map((prop, key) => {

                const menuClass = activeRoute(prop.path)
                    ? `${css.menuItem} ${css.active}`
                    : css.menuItem


                return (
                    <NavLink
                        to={prop.path}
                        key={key}
                        style={{ fontWeight: 'bold' }}
                        onClick={props.open ? handleDrawerToggle : () => { }}
                    >
                        <ListItem className={menuClass}>
                            {prop.iconComponent}
                            <ListItemText
                                style={{ maxWidth: '144px', paddingLeft: '15px' }}
                                primary={prop.id ? intl.formatMessage({ id: prop.id, defaultMessage: prop.name }) : prop.name}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );



    var mobileLinks = (
        <List style={{ paddingLeft: '20px', paddingTop: '0px', paddingRight: '20px', paddingBottom: '400px' }}>
            {routes.map((prop, key) => {

                const menuClass = activeRoute(prop.path)
                    ? `${css.menuItemMobile} ${css.active}`
                    : css.menuItemMobile


                return (
                    <NavLink
                        to={prop.path}
                        key={key}
                        style={{ fontWeight: 'bold' }}
                        onClick={props.open ? handleDrawerToggle : () => { }}
                    >
                        <ListItem className={menuClass}>
                            {prop.iconComponent}
                            <ListItemText
                                style={{ maxWidth: '144px', paddingLeft: '15px' }}
                                primary={prop.name}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}

        </List>
    );
    var brand = (
        <div className={classes.logo}>
            <a
                href="/dashboard"
                className={classNames(classes.logoLink)}
                rel="noopener noreferrer"
            >
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div>
            </a>
        </div>
    );
    return <div>
        <Hidden mdUp implementation="css">
            <Drawer
                variant="temporary"
                open={props.open}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                BackdropProps={{
                    style: {
                        height: '100vh'
                    }
                }}
                PaperProps={{
                    style: {
                        width: 'calc(100% - 100px)',
                        maxWidth: '300px',
                        height: '100vh',
                        backgroundColor: '#b4bb00'
                    }
                }}

            >
                {brand}
                {mobileLinks}
            </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
            <Drawer
                anchor="left"
                variant="permanent"
                open
                PaperProps={{
                    style: { backgroundColor: '#b4bb00', borderRight: '0px solid black' }
                }}
            >
                {brand}
                <div style={{ width: '260px', height: 'calc(100vh - 75px)' }}>
                    <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        renderTrackVertical={props => <div {...props} className="track-vertical" />}
                        style={{ width: '100%', height: '100%' }}
                    >
                        {links}
                    </Scrollbars>
                </div>
            </Drawer>
        </Hidden >
    </div >
}
export default withRouter(Sidebar);