import React, { useState } from "react";
import { useSFLApi } from 'api';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardButton from 'sfl-components/SFLCards/CardButton';
import { FormattedMessage } from 'react-intl';

export default function DownloadRecipeButton({ uuid }) {
    const [isLoading, setIsLoading] = useState(false);
    const { get } = useSFLApi();

    const getPDF = async () => {
        setIsLoading(true)
        try {
            const printResults = await get(`recipes/${uuid}/print2`)
            if (!printResults) {
                throw 'API file download error!';
            }
            window.open(printResults.download.path)
        }
        catch (error) {
            console.log('error', error)
            toast.error('Download Failed')
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return <CardButton iconComponent={<CircularProgress size={20} style={{ color: 'white' }} />} style={{ backgroundColor: '#b999ba' }}><FormattedMessage id="app.print" defaultMessage="Print" /></CardButton>
    } else {
        return <CardButton icon={['far', 'file-pdf']} onClick={getPDF}><FormattedMessage id="app.print" defaultMessage="Print" /></CardButton>
    }
}