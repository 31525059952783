import React from "react";

import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';
import HeightTextField from "sfl-components/Inputs/HeightTextField";

import Question from './Question';
import DatePicker from 'sfl-components/Inputs/DatePicker';


const QuestionnaireIntro = props => {
    const {
        handleChange,
        height = '',
        height_scale = 'ft',
        what_other_programs = '',
        what_brings_you = '',
        household = '',
        pets = '',
        gender = '',
        tune = '',
        date_of_birth
    } = props

    return <Card key='intro' title="Tell us about yourself" titleMode="inner">
        <Grid container spacing={3} style={{ padding: '0 20px' }}>
            <Grid item xs={12} >
                <Grid container spacing={3}>
                    <Question
                        id="what_brings_you"
                        label="What brings you to Simply For Life?"
                        multiline
                        helperText=" "
                        rows={2}
                        rowsMax={10}
                        value={what_brings_you}
                        onChange={handleChange}
                    />
                    <Question
                        id="what_other_programs"
                        label="What have you tried previously and why didn't it work?"
                        helperText="Ex: Number of attempts, program names, results, etc."
                        multiline
                        rows={2}
                        rowsMax={10}
                        value={what_other_programs}
                        onChange={handleChange}
                    />
                    <Question
                        id="household"
                        label="How many people are there in your household and who usualy does the cooking?"
                        helperText="Ex: Me, my wife, and our two kids (6 and 8). We usualy take turns preparing the meals."
                        multiline
                        rows={2}
                        rowsMax={10}
                        value={household}
                        onChange={handleChange}
                    />
                    <Question
                        id="pets"
                        label="Do you have any pets?"
                        helperText="Make sure to show a picture to your consultant!"
                        multiline
                        rows={2}
                        rowsMax={10}
                        value={pets}
                        onChange={handleChange}
                    />
                    <Question
                        id="tune"
                        label="What is your favorite tune?"
                        value={tune}
                        onChange={handleChange}
                    />
                    <Grid item xs={12} >
                        <DatePicker
                            id="date_of_birth"
                            label="When were you born?"
                            value={date_of_birth}
                            onChange={(date) => {
                                handleChange('date_of_birth', date)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <HeightTextField
                            id="height"
                            label="How tall are you?"
                            helperText=" "
                            height={height}
                            scale={height_scale}
                            onHeightChange={(updatedHeight) => handleChange('height', updatedHeight)}
                            onHeightScaleChange={(updatedScale) => handleChange('height_scale', updatedScale)}
                        />
                    </Grid>
                    <Question
                        id="gender"
                        label="What is your gender?"
                        helperText="Gender is a factor for determining appropriate portion sizes. If you're unsure which option to pick, please select 'Other' and your consultant will work with you to determine the appropriate portion sizes."
                        select
                        value={gender}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='male' value='male'>Male</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='female' value='female'>Female</MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='other' value='other'>Other</MenuItem>
                    </Question>
                </Grid>
            </Grid>
        </Grid>
    </Card>
}


export default React.memo(QuestionnaireIntro);