import React from "react";

import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';
import HeightTextField from "sfl-components/Inputs/HeightTextField";

import Question from './Question';

import DatePicker from 'sfl-components/Inputs/DatePicker';
import { FormattedMessage, useIntl } from 'react-intl';


const QuestionnaireIntro = props => {
    const {
        handleChange,
        height = '',
        height_scale = 'ft',
        what_other_programs = '',
        what_brings_you = '',
        household = '',
        pets = '',
        gender = '',
        date_of_birth,
        tune = ''
    } = props
    const intl = useIntl();




    return <Card
        key='intro'
        title={intl.formatMessage({ id: "questionnaire.tellUsAboutYourself", defaultMessage: "Tell us about yourself" })}
        titleMode="inner"
        style={{ marginBottom: '75px' }}
    >
        <div style={{ padding: '0 20px' }}>
            <Grid container spacing={3} >
                <Question
                    id="what_brings_you"
                    label={intl.formatMessage({ id: "questionnaire.whatBringsYou", defaultMessage: "What brings you to Simply For Life?" })}
                    multiline
                    helperText=" "
                    rows={2}
                    rowsMax={10}
                    value={what_brings_you}
                    onChange={handleChange}
                />
                <Question
                    id="what_other_programs"
                    label={intl.formatMessage({ id: "questionnaire.whatOtherPrograms", defaultMessage: "What have you tried previously and why didn't it work?" })}
                    helperText={intl.formatMessage({ id: "questionnaire.whatOtherPrograms.helper", defaultMessage: "Ex: Number of attempts, program names, results, etc." })}
                    multiline
                    rows={2}
                    rowsMax={10}
                    value={what_other_programs}
                    onChange={handleChange}
                />
                <Question
                    id="household"
                    label={intl.formatMessage({ id: "questionnaire.household", defaultMessage: "How many people are there in your household and who usualy does the cooking?" })}
                    helperText={intl.formatMessage({ id: "questionnaire.household.helper", defaultMessage: "Ex: Me, my wife, and our two kids (6 and 8). We usualy take turns preparing the meals." })}
                    multiline
                    rows={2}
                    rowsMax={10}
                    value={household}
                    onChange={handleChange}
                />
                <Question
                    id="pets"
                    label={intl.formatMessage({ id: "questionnaire.pets", defaultMessage: "Do you have any pets?" })}
                    helperText={intl.formatMessage({ id: "questionnaire.pets.helper", defaultMessage: "Make sure to share a picture to your consultant!" })}
                    multiline
                    rows={2}
                    rowsMax={10}
                    value={pets}
                    onChange={handleChange}
                />
                <Question
                    id="tune"
                    label={intl.formatMessage({ id: "questionnaire.tune", defaultMessage: "What is your favourite tune?" })}
                    value={tune}
                    onChange={handleChange}
                />
                <Grid item xs={6}>
                    <DatePicker
                        id="date_of_birth"
                        label={intl.formatMessage({ id: "questionnaire.dateOfBirth", defaultMessage: "When were you born?" })}
                        value={date_of_birth}
                        onChange={(date) => { handleChange('date_of_birth', date) }}
                    />
                </Grid>
                <Grid item xs={6} >
                    <HeightTextField
                        id="height"
                        label={intl.formatMessage({ id: "questionnaire.height", defaultMessage: "How tall are you?" })}
                        helperText=" "
                        height={height}
                        scale={height_scale}
                        onHeightChange={(updatedHeight) => handleChange('height', updatedHeight)}
                        onHeightScaleChange={(updatedScale) => handleChange('height_scale', updatedScale)}
                    />
                </Grid>
                <Question
                    id="gender"
                    label={intl.formatMessage({ id: "questionnaire.gender", defaultMessage: "What is your gender?" })}
                    helperText={intl.formatMessage({ id: "questionnaire.gender.helper", defaultMessage: "Gender is a factor for determining appropriate portion sizes. If you're unsure which option to pick, please select 'Other' and your consultant will work with you to determine the appropriate portion sizes." })}
                    select
                    value={gender}
                    onChange={handleChange}
                >
                    <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='male' value='male'><FormattedMessage id="app.male" defaultMessage="Male" /></MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='female' value='female'><FormattedMessage id="app.female" defaultMessage="Female" /></MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='other' value='other'><FormattedMessage id="app.other" defaultMessage="Other" /></MenuItem>
                </Question>
            </Grid>
        </div>
    </Card>
}


export default React.memo(QuestionnaireIntro);