import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxSelect(props) {
    const { id = "checkboxes-tags-demo", options, selectedOptions, onChange, label, helperText, variant = 'filled' } = props;

    return <Autocomplete
        multiple
        id={id}
        options={options.map(category => {
            category['selected'] = selectedOptions.includes(category.id)
            return category
        })}
        onChange={(data, values) => {
            onChange(values.map(v => v.id))
        }}
        value={options.filter(o => selectedOptions.includes(o.id))}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        renderOption={(option) => {
            return <React.Fragment>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={option.selected}
                />
                {option.name}
            </React.Fragment>
        }}
        renderInput={(params) => {
            return <TextField {...params} label={label} helperText={helperText} variant={variant} />
        }}
    />
}