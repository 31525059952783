import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from 'sfl-components/TextField';


const QuestionnaireQuestion = props => {
    const { onChange, label, gridProps, ...rest } = props
    return <Grid item xs={12} lg={6} {...gridProps}>
        <TextField
            {...rest}
            label={label}
            onChange={onChange}
        >{props.children}</TextField>
    </Grid>
}

export default React.memo(QuestionnaireQuestion, (prevProps, nextProps) => prevProps.value === nextProps.value);